import React from 'react';

//Img
import GB_foto_04 from '../../assets/img/QuienesSomos/GB_foto_04.png';

const QuienesSomosImg4 = () => {
    
    return (
          
        <div className="containerImageQuienesSomos2">
                    
            <img className="imgQuienesSomos" src={GB_foto_04} alt="Img4"></img>

            <div className="container p-0 row m-0 containerTxtQuienesSomos">

                <div className="col-5 p-0">

                    <p className="titleTxtQuienesSomosImg4 text-right">Realiza preguntas personalizadas para tu candidato.</p>
                    <p className="descrTxtQuienesSomosImg4 mb-0 text-right">Quieres saber un poco mas sobre tu candidato? Envia una plantilla de preguntas personalizadas y obten una respuesta de video que podras consultar cuantas veces necesitas.</p>
                    
                </div>

            </div>

        </div>
    )
    
}

export default QuienesSomosImg4;