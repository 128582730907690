import React, { Fragment, memo } from 'react';

import { v4 as uuid_v4 } from 'uuid';

//Components
import CandidatoRecienteInfo from './CandidatoRecienteInfo';
import CandidatoRecienteInfoSkeleton from './CandidatoRecienteInfoSkeleton';

//Info Example
import { exampleUsersRecentsData } from '../../data/info';

const ContainerScrollViewCandidatosRecientes = memo((props) => {
    
    const {recentUsers} = props;

    return (
        <Fragment>
            
            {
        
                recentUsers && recentUsers.length > 0 ?
                    recentUsers.map(user=> <CandidatoRecienteInfo key={uuid_v4()} user={user} viewProfile={props.viewProfile}/> )
                :
                exampleUsersRecentsData && 
                exampleUsersRecentsData.map(() => <CandidatoRecienteInfoSkeleton key={uuid_v4()} /> )
            }    

        </Fragment>
    )
    
}, (prevProps, nextProps) => {

    if(prevProps.recentUsers !== nextProps.recentUsers){//|| prevProps.user.userFiles !== nextProps.user.userFiles

        //console.log("Memo true");
        return false;
    }

    //console.log("Memo false");
    return true;
});

export default ContainerScrollViewCandidatosRecientes;
