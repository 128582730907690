import React from 'react';
import ic_profile from '../../assets/img/ic_profile.png';
import Close from '../../assets/img/Close.png';
import Star_Blue from '../../assets/img/Star_Blue.png';

const CandidatoInfoSkeleton = () =>{
    
    const file = false;

    return (

        <div className="mainContainerCandidatoInfoE">
            <div className="row m-0">
                <div className="col-12 p-0">




                    <div className="leftMainContainerCandidatoInfo">

                        <div className="leftSubContainerCandidatoInfo">

                            <div className="containerVideoCandidatoInfo">

                                {/*{props.user.file && <img src={Close} className="pageView3 previewVideo"></img>} */
                                    !file &&   <img src= {Close} className="imgVideoCandidatoInfo" alt="Video Candidato Info"></img>   
                                }

                                {
                                    /*
                                    <video className={`${file ? 'enable' : ''} `} id="v" controls disablePictureInPicture controlsList="nodownload" preload="metadata">
                                    <source src="" type="video/MP4"/>
                                    </video>*/
                                }
                            
                            </div>

                        </div>

                    </div>


                    <div className="row m-0 rightMainContainerCandidatoInfo">
                        <div className="rightSubContainerCandidatoInfo">
                            <div className="rightFillContainerCandidatoInfo">
                                <div className="row m-0">

                                        

                                        <div className="col-12 p-0">


                                            
                                            <div className="leftContainerImageProfileCandidatoInfo">

                                                    <div className="leftSubContainerImageProfileCandidatoInfo">
                                                        <img src= {ic_profile} className="imgProfileCandidatoInfo" alt="Profile Img"></img>
                                                    </div>
                                                
                                            </div>

                                            
                                            <div className="row m-0 rightContainerUserInfoCandidatoInfo">

                                                <div className="rightSubContainerUserInfoCandidatoInfo"> {/**/}

                                                        <div className="containerStarCandidatoInfo align-self-center">

                                                            <div className="text-right">

                                                                <img className="cardStar" alt="star" src={ Star_Blue }/>
                                                                <img className="cardStar" alt="star" src={ Star_Blue }/>
                                                                <img className="cardStar" alt="star" src={ Star_Blue }/>
                                                                <img className="cardStar" alt="star" src={ Star_Blue }/>
                                                                <img className="cardStar" alt="star" src={ Star_Blue }/>
                                                                
                                                            </div>

                                                        </div>

                                                        <div className="leftContainerUserDetailCandidatoInfo my-auto align-self-center">
                                                            <p className="candidatoInfoNameE"></p>
                                                            <p className="mb-0 candidatoInfoJobE"></p>
                                                        </div>
                                                </div>

                                            </div>

                                        </div>


                                        <div className="containerAboutMeCandidatoInfo">

                                            <p className="aboutMeTitleE"></p>
                                            <p className="aboutMeDescrE"></p>
                                            <p className="aboutMeDescrE"></p>
                                            <p className="aboutMeDescrE"></p>
                                        </div>



                                </div>
                            </div>    
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
    
}


export default CandidatoInfoSkeleton;