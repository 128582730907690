import { types } from '../types/types';
import axios from 'axios';

import { setLoadingCandidatosRecientes, setLoadingCandidatosInfo } from '../actions/uiAction';

export const getUserByID = (uid) =>{

    return(dispatch) =>{

        //let users = [];

        console.log("getUserByID");

        axios({
            method: 'post',     //put
            url: `${process.env.REACT_APP_FIREBASE_FUNCTIONS_URL}/getUserByID`, 
            data: {
                uid
            }
        })
        .then(res => {
            //users = res.data;
            console.log("getUserByID", res.data);

            /*dispatch({
                type: types.userSelected,
                payload: {
                    userSelected: res.data
                }
            });*/

            dispatch(setUserSelected(res.data));

        }).catch(err =>{
            console.log("err", err);
        });

    }
}


export const setUserSelected = (userSelected) =>{

    return (dispatch) => {

        dispatch({
            type: types.userSelected,
            payload: {
                userSelected
            }
        });

    }
}


export const getRecentUsers = () =>{

    return(dispatch) =>{

        //let users = [];

        console.log("getRecentUsers");

        dispatch(setLoadingCandidatosRecientes(true));


        axios.post(`${process.env.REACT_APP_FIREBASE_FUNCTIONS_URL}/getRecentUsers`)
        .then(res => {
            //users = res.data;
            console.log("getRecentUsers", res.data);

            dispatch({
                type: types.recentUsers,
                payload: {
                    recentUsers: res.data
                }
            });

            dispatch(setLoadingCandidatosRecientes(false));

        }).catch(err =>{
            console.log("err", err);
            dispatch(setLoadingCandidatosRecientes(false));
        });

    }
}


export const getUsersFilter = (stateSearch) =>{

    return(dispatch) =>{

        //let users = [];
        //const { searchString } = getState().searchReducer;
        dispatch(setFilterSearch(stateSearch));

        console.log("getUsersFilter");

        if(stateSearch.searchFilter === "Job"){
            dispatch(searchByJob(stateSearch));
        }
        else if(stateSearch.searchFilter === "Email"){
            dispatch(searchByEmail(stateSearch));
        }
        else if(stateSearch.searchFilter === "Name"){
            dispatch(searchByName(stateSearch));
        }
        
    }
}


export const searchByJob = (stateSearch) =>{

    return(dispatch, getState) =>{

        console.log("searchForJob");
        dispatch(setLoadingCandidatosInfo(true));

        const { countryCode, cityID, job } = getState().searchOnBoardingReducer.searchOnBoarding;
        
        axios({
                method: 'post',     //put
                url: `${process.env.REACT_APP_FIREBASE_FUNCTIONS_URL}/getUsersFilter`, 
                data: {
                    cityID: cityID,
                    countryCode: countryCode,
                    job: job,
                    puestoSearch: stateSearch.searchText
                }
            })
            .then(res => {
                //users = res.data;
                console.log("users", res.data);

                dispatch({
                    type: types.searchUsersFilter,
                    payload: {
                        data: res.data
                    }
                });

                dispatch(setLoadingCandidatosInfo(false));

            }).catch(err =>{
                console.log("err", err);

                dispatch(setLoadingCandidatosInfo(false));
            });

    }
}

export const searchByEmail = (stateSearch) =>{

    return(dispatch) =>{

        console.log("searchByEmail");
        dispatch(setLoadingCandidatosInfo(true));

        axios({
                method: 'post',     //put
                url: `${process.env.REACT_APP_FIREBASE_FUNCTIONS_URL}/searchByEmail`, 
                data: {
                    email: stateSearch.searchText
                }
            })
            .then(res => {
                //users = res.data;
                console.log("users", res.data);

                dispatch({
                    type: types.searchUsersFilter,
                    payload: {
                        data: res.data
                    }
                });

                dispatch(setLoadingCandidatosInfo(false));

            }).catch(err =>{
                console.log("err", err);
                dispatch(setLoadingCandidatosInfo(false));
            });

    }
}


export const searchByName = (stateSearch) =>{

    return(dispatch, getState) =>{

        console.log("searchByName");

        dispatch(setLoadingCandidatosInfo(true));

        const { countryCode, cityID } = getState().searchOnBoardingReducer.searchOnBoarding;

        axios({
                method: 'post',     //put
                url: `${process.env.REACT_APP_FIREBASE_FUNCTIONS_URL}/searchByName`, 
                data: {
                    name: stateSearch.searchText,
                    countryCode: countryCode,
                    cityID: cityID
                }
            })
            .then(res => {
                //users = res.data;
                console.log("users", res.data);

                dispatch({
                    type: types.searchUsersFilter,
                    payload: {
                        data: res.data
                    }
                });

                dispatch(setLoadingCandidatosInfo(false));

            }).catch(err =>{
                console.log("err", err);
                dispatch(setLoadingCandidatosInfo(false));
            });

    }
}


export const getUsersNextFilter = () =>{

    return(dispatch, getState) =>{

        
        const { searchUsers, searchString } = getState().searchReducer;
        const { countryCode, cityID, job } = getState().searchOnBoardingReducer.searchOnBoarding;


        console.log("endUser ", searchUsers.endUser);

        axios({
            method: 'post',     //put
            url: `${process.env.REACT_APP_FIREBASE_FUNCTIONS_URL}/getUserNextFilter`, 
            data: {
                lastUser: searchUsers.endUser, // This is the body part
                cityID: cityID,
                countryCode: countryCode,
                job: job,
                puestoSearch: searchString
            }
          })//axios.post(`https://us-central1-awesomepeople-5f978.cloudfunctions.net/api/getUserNext`)//'4ecSL4vuq9MsMtWDrGAu8boEqZ62'
        .then(res => {
            //users = res.data;
            console.log("getUsersNext", res.data);

            dispatch({
                type: types.searchUsersNextFilter,
                payload: {
                    data: res.data
                }
            });

        }).catch(err =>{
            console.log("err", err);
        });

    }
}


export const setFilterSearch = (stateSearch) =>{

    return(dispatch) =>{

        dispatch({
            type: types.setFiltersSearch,
            payload: {
                stateSearch
            }
        });
    }
}


export const CerrarSesionSearch = () => 
{
    return (dispatch) => {

        dispatch({
            type: types.logout,
            payload: {
                
            }
        });
           
    }
}