import React, { Fragment } from 'react';

const PlanPlatinum = (props) => {
    
    return (

        <Fragment>
            <div className="containerPlan">

                <button type="button" className="btn btn-primary btn-block btnServicios mb-2">gBonita Platinum</button>   

                <div className="containerPlanDescr">
                    <h4>Para empresas pequeñas en crecimiento</h4>
                    <p className="m-0">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries</p>
                </div>

               
                <div className="containerPlanDetail">
                    <p>Lorem Ipsum</p>
                    <p>Lorem Ipsum</p>
                </div>

            </div>

            <div className="containerPlanButton">
                <button type="button" className="btn btn-primary btn-block btnServiciosComprar" onClick={props.handleLogIn}>Ingresar para comprar</button> 
            </div>

        </Fragment>
        
    )
    
}

export default PlanPlatinum;
