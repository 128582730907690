import React from 'react';

const Contacto = (props) => {
    
    const { user } = props;
    const { strings } = props;

    return (

        <div className="containerContactoProfileCandidato">
            <h2>{strings.title}</h2>
            <p className="m-0"><strong>{strings.labelEmail}</strong>{user.email}</p>         
            <p className="m-0"><strong>{strings.labelPhone}</strong>{user.phone}</p>    
        </div>

    )
    
}

export default Contacto;
