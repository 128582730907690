import { types } from '../types/types';

const initialState = 
{
    sidebar: false,//true
    showSideBarSts: true,//displayed,
    searchBar: false,//

    loadingLogIn: false,
    loadingRegister: false,
    loadingChangePassword: false,
    loadingCandidatosInfo: false,
    loadingCandidatosRecientes: false,
    loadingChangePassword: false,
    loadingVideoOnCadidatoProfile: true
}

 export const uiReducer = (state = initialState, action) => {

    switch(action.type){

        case types.setLoadingLogIn:
            return{
                ...state,
                loadingLogIn: action.payload.loadingLogIn
            }

        case types.setLoadingRegister:
            return{
                ...state,
                loadingRegister: action.payload.loadingRegister
            }

        case types.setLoadingChangePassword:
            return{
                ...state,
                loadingChangePassword: action.payload.loadingChangePassword
            }
        case types.setLoadingCandidatosRecientes:
            return{
                ...state,
                loadingCandidatosRecientes: action.payload.loadingCandidatosRecientes
            }

        case types.setLoadingCandidatosInfo:
            return{
                ...state,
                loadingCandidatosInfo: action.payload.loadingCandidatosInfo
            }
            
        case types.setLoadingVideoOnCadidatoProfile:
            return{
                ...state,
                loadingVideoOnCadidatoProfile: action.payload.loadingVideoOnCadidatoProfile
            }

        case types.setLoadingChangePassword:
            return{
                ...state,
                loadingChangePassword: action.payload.loadingChangePassword
            }
        
        case types.sidebar:
            return{
                ...state,
                ...action.payload
            }

        case types.showSideBar:
            return{
                ...state,
                sidebar: action.payload.sidebar
            }    
        case types.clickShowSidebar:
            //console.log("uiReducer ClickShowHideSideBar");
            return{
                ...state,
                showSideBarSts: action.payload.displayed
            }
        case types.showSearchBar:
            return{
                ...state,
                searchBar: action.payload.searchBar
            }
            
        case types.logout:
            return initialState;

        default:
            return state;

    }
 }

