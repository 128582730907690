import React from 'react';

//Img
import GB_foto_02 from '../../assets/img/QuienesSomos/GB_foto_02.png';

const QuienesSomosImg2 = () => {
    
    return (
        <div className="containerImageQuienesSomos2">
                    
            <img className="imgQuienesSomos" src={GB_foto_02} alt="Img2"></img>


            <div className="container p-0 row m-0 containerTxtQuienesSomos">

                <div className="col-5 p-0 text-center">

                    <p className="titleQuienesSomosImg2 text-right">Conoce a tus candidatos sin tener una primera entrevista.</p>
                    <p className="descrTxtQuienesSomosImg2 mb-0 text-right">Revisa cada perfil, sin tener la necesidad de contactarse directamente en las primeras etapas de reclutamiento, y sobre todo sin necesidad de reuniones en persona.</p>
                    

                </div>

            </div>

        </div>
    )
    
}

export default QuienesSomosImg2;