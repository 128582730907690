import React from 'react';

//Img
import ICO_fortalezas from '../../assets/img/CandidatoProfile/ICO_fortalezas.png';

const PrepareSkills = (props) => {
    
    const { group_HabilidadesFortalezas, habilidadesFortalezasCat } = props;
    const { strings } = props;
    //const val = AddHabilidades();

    return (
        <div className="col-12 p-0 mainContainerPrepareItemProfileCandidato">
                            
            
            <div className="containerImgPrepareProfileCandidato">
                <img src= {ICO_fortalezas} className="imgPrepareCandidatoInfo" alt="Fortalezas"></img>
            </div>


            <div className="row m-0 containerInfoPrepareProfileCandidato">
                <div className="subcontainerInfoPrepareProfileCandidato">
                    
                    <h1>{strings.titleSkills}</h1>   
                    {
                          group_HabilidadesFortalezas && 
                          group_HabilidadesFortalezas.map(habilidad => <p key={habilidad}>{habilidadesFortalezasCat[habilidad].descrCorta}</p>)
                    }
                    
                </div>
            </div>

        </div>
    )
    
}

export default PrepareSkills;