import React, { useState, useEffect, Fragment } from 'react';

//Redux
import { useDispatch, useSelector } from 'react-redux';

//Components
import RegisterMobile from '../components/Register/RegisterMobile';
import RegisterTablet from '../components/Register/RegisterTablet';
import RegisterDesktop from '../components/Register/RegisterDesktop';

//Model
import { RegisterModel } from '../models/RegisterModel';

//Actions
import { setUp } from '../redux/actions/stateRegisterAction';
import { startRegisterWithEmailPasswordName } from '../redux/actions/auth';
import { showSideBar } from '../redux/actions/uiAction';

//ROUTES
import * as ROUTES from '../data/routes';

//Data
import { countryData } from '../data/info'; //jobData

//Strings
import { RegisterStrings, RegisterAuthStrings } from '../data/strings';

//Image
import BUT_seleccion_02_up from '../assets/img/BUT_seleccion_02_up.png';
import BUT_seleccion_02_press from '../assets/img/BUT_seleccion_02_press.png';

 const Register = () => {

    const dispatch = useDispatch();

    //Languages
    const strings = RegisterStrings['MX'];
    const stringsAuth = RegisterAuthStrings['MX'];
    
    //Model
    let registerModel = new RegisterModel(stringsAuth); 

    const { stateRegister, errorState } = useSelector(state => state.stateRegisterReducer);

    //console.log("stateRegister", stateRegister);
    const [stateForm, setState] = useState(stateRegister);

    const { email, password } = stateForm;

    const { loadingRegister } = useSelector(state => state.uiReducer);

    const [stateWindow, setWindow] = useState('Desktop');

    useEffect(() => {

        dispatch(showSideBar(false));

        handleResize();

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);

    }, [dispatch]);

    const handleResize = () =>{

        const { innerWidth } = window;//console.log("window", window);

        if(innerWidth >= 1600){
            setWindow('Desktop');
        }
        else if(innerWidth >= 1000 && innerWidth <= 1599){
            setWindow('Tablet');
        }
        else if(innerWidth <= 999){
            setWindow('Mobile');
        }

    }

    const handleInputChange = ({target}) => {

        setState({
            ...stateForm,
            [target.name]: target.value
        });
    };

    const handleShowHide = (id, toggleID) =>{
          
          var x = document.getElementById(id);
          var element = null;

          if (x.type === "password") {

            x.type = "text";

            element = document.getElementById(toggleID);
            //console.log("element", element.className);
            element.className = "fa fa-fw fa-eye field-icon toggle-password fa-eye-slash";

          } else {

            x.type = "password";

            element = document.getElementById(toggleID);
            //console.log("element", element[0].className);
            element.className = "fa fa-fw fa-eye field-icon toggle-password";

          }
    } 

    const handleCheckPrivacyPolicy = () =>{
        
        const privacyPolicy = !stateForm.privacyPolicy;

        setState(
            {
                ...stateForm,
                privacyPolicy
            });
    }

    const handleShowPrivacy = () => {

        console.log("handleShowPrivacy");
        var win = window.open(ROUTES.TERMS, '_blank');
        win.focus();
    }

    const handleRegistrarse = (e) =>{
        
        e.preventDefault();
        
        registerModel.validate(stateForm);
      
        //Local
        if(registerModel.errorStatus){

            dispatch(setUp(stateForm, registerModel.toObject()));
         
            return;
        }

        //Server
        dispatch(startRegisterWithEmailPasswordName(email, password, stateForm));

    };

    return (

        <Fragment>

            <div className="mainContainerRegisterColumns">
                
            
                {
                    stateWindow === 'Desktop' ? 

                        <RegisterDesktop 
                            stateForm={stateForm} 
                            errorState={errorState} 
                            handleInputChange={handleInputChange}
                            handleShowHide={handleShowHide}
                            handleCheckPrivacyPolicy={handleCheckPrivacyPolicy}
                            handleShowPrivacy={handleShowPrivacy}
                            handleRegistrarse={handleRegistrarse}
                            BTN_check_up={BUT_seleccion_02_up}
                            BTN_check_press={BUT_seleccion_02_press}
                            countryData={countryData}
                            strings={strings}
                        />
                        : stateWindow === 'Tablet' ? 
                        <RegisterTablet 
                            stateForm={stateForm} 
                            errorState={errorState} 
                            handleInputChange={handleInputChange}
                            handleShowHide={handleShowHide}
                            handleCheckPrivacyPolicy={handleCheckPrivacyPolicy}
                            handleShowPrivacy={handleShowPrivacy}
                            handleRegistrarse={handleRegistrarse}
                            BTN_check_up={BUT_seleccion_02_up}
                            BTN_check_press={BUT_seleccion_02_press}
                            countryData={countryData}
                            strings={strings}
                        />
                        :
                        <RegisterMobile
                            stateForm={stateForm} 
                            errorState={errorState} 
                            handleInputChange={handleInputChange}
                            handleShowHide={handleShowHide}
                            handleCheckPrivacyPolicy={handleCheckPrivacyPolicy}
                            handleShowPrivacy={handleShowPrivacy}
                            handleRegistrarse={handleRegistrarse}
                            BTN_check_up={BUT_seleccion_02_up}
                            BTN_check_press={BUT_seleccion_02_press}
                            countryData={countryData}
                            strings={strings}
                        />
                        
                }

            
            </div>

            {
                loadingRegister && <div className="containerRegisterLoading">
                                    </div>
            }

        </Fragment>

    )
    
}

export default Register;