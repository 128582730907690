import React from 'react';

//Img
import ICO_idiomas from '../../assets/img/CandidatoProfile/ICO_idiomas.png';

const PrepareLanguages = (props) => {
    
    const { group_LanguageLevelID, idiomaCat, idiomaLevelCat } = props;
    const { strings } = props;

    return (


         <div className="col-12 p-0 mainContainerPrepareItemProfileCandidato">
                                 
            <div className="containerImgPrepareProfileCandidato">
                <img src= {ICO_idiomas} className="imgPrepareCandidatoInfo" alt="Idiomas"></img>
            </div>


            <div className="row m-0 containerInfoPrepareProfileCandidato">
                <div className="subcontainerInfoPrepareProfileCandidato">
                    
                <h1>{strings.titleLanguages}</h1>               
                
                {
                    group_LanguageLevelID && 
                    group_LanguageLevelID.map(idioma => 
                                                                                        
                                                    {
                                                        var array =  idioma.split("|");
                                                        console.log("array", array[0]);
                                                        
                                                        //{idiomaLevelCat[array[1]].descrString} 

                                                        return(<p key={idioma}> {idiomaCat[array[0]].descrString} : {idiomaLevelCat[array[1]].descrID}</p>)
                                                    }
         
                                                )
                }
                    
                </div>
            </div>

        </div>
)
    
}

export default PrepareLanguages;
