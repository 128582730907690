import { types } from '../types/types';

export const setUp = (stateSettings, errorState) => {
    
    return ( dispatch ) => {
        dispatch(setStateSettings(stateSettings));
        dispatch(setErrorAuthSettings(errorState));
    }
}

export const setStateSettings = (stateSettings) => ({
    type: types.setStateSettings,
    payload:{
        stateSettings
    }     
});

export const setErrorAuthSettings = (errorState) => ({
    type: types.errorAuthSettings,
    payload:{
        ...errorState
    }     
});

export const resetStateSettings = () => ({
    type: types.resetStateSettings,
    payload:{
        
    }     
});

export const CerrarStateSettingsAction = () => 
{
    return (dispatch) => {

        dispatch({
            type: types.logout,
            payload: {
                
            }
        });
           
    }
}
