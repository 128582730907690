//á, é, í, ó, ú
export const PublicNavbarStrings = {

    MX: {
        'btnQuienesSomos': 'Quienes Somos',
        'btnServicios':'Servicios',
        'btnLogIn': 'Ingresar',
        'btnRegister': 'Registrate'
    }
};

export const SideBarStrings = {

    MX: {
        'btnBusqueda': 'Búsqueda',
        'btnFiltro':'Filtros',
        'btnCarpetas': 'Carpetas',
        'btnChat': 'Chat'
    }
};



export const LogInStrings = {

    MX: {
        'btnLogIn': 'Ingresa',
        'btnRegister':'Registrate',
        'socialMedia': 'Siguenos'
    }
};

export const LogInAuthStrings = {

    MX: {
        'EmailAlreadyInUse': 'La dirección de correo electrónico ya está siendo utilizada por otra cuenta.',
        'InvalidEmail':'La dirección de correo electrónico está mal formateada.',
        'NotAvailableCandidate': "No disponible para candidatos",
        'EmailNotVerified': "Email no verificado",
        'FieldEmailError': 'Email no puede estar vacio',
        'FieldPasswordError': 'La contraseña debe ser igual o mayor a 8 caracteres'
    }
};


export const AuthStrings = {

    MX: {
        'EmailAlreadyInUse': 'La dirección de correo electrónico ya está siendo utilizada por otra cuenta.',
        'InvalidEmail':'La dirección de correo electrónico está mal formateada.',
        'NotAvailableCandidate': "No disponible para candidatos",
        'EmailNotVerified': "Email no verificado",
        'FieldEmailError': 'Email no puede estar vacio',
        'FieldPasswordBadFormatError': 'Formato incorrecto.',
        'FieldPasswordLenghtError': 'La contraseña debe ser igual o mayor a 8 caracteres',
        'FieldPasswordNotMatchError': 'La contraseña no coincide',
    }
};

export const RegisterStrings = {

    MX: {
        'titleRegister': 'Tu primer paso para encontrar a tu candidato ideal.',
        'labelName':'Nombre(s)',
        'labelLastName': 'Apellido(s)',
        'labelCompany': 'Empresa',
        'placeHolderCompany': 'Empresa',
        'labelEmail': 'Correo electrónico corporativo',
        'placeHolderEmail': 'ejemplo@empresa.com',
        'labelJob': 'Puesto',
        'labelCountry': 'País',
        'labelPassword': 'Contraseña',
        'labelConfirmPassword': 'Confirmar Contraseña',
        'labelDescrPassword': 'Tu contraseña debe ser mayor a 8 caracteres, tener al menos una mayuscula, un símbolo y un número.',
        'terms': 'Consultar terminos y condiciones',
        'privacyPolicy': 'Acepto',
        'privacyPolicy2': 'términos y condiciones',
        'btnRegister': 'Registrate'
    }
};


export const RegisterAuthStrings = {

    MX: {
        'WrongPassword': 'Usuario y/o password incorrectos.',
        'InvalidEmail':'La dirección de correo electrónico está mal formateada.',
        'TooManyRequests':'Demasiados intentos',
        
        'FieldNameError': 'El campo es obligatorio',
        'FieldLastNameError': 'El campo es obligatorio',
        'FieldCompanyError': 'El campo es obligatorio',
        'FieldEmailError': 'El campo es obligatorio',
        'FieldJobError': 'El campo es obligatorio',
        'FieldPasswordError': 'Formato incorrecto.',//'La contraseña debe ser igual o mayor a 8 caracteres',
        'FieldPasswordNotMatchError': 'La contraseña no coincide',
        'FieldCountryError': 'Debes seleccionar una opc'

    }
};



export const Servicestrings = {

    MX: {
        'labelTitle': 'Tenemos un plan para tus necesidades',
        'btnLogIn':'Ingresar para comprar',
        'socialMedia': 'Siguenos'
    }
};

export const PlanLiteStrings = {

    MX: {
        'labelTitle': 'gBonita Lite',
        'labelDescr': 'Para independientes y Emprendedores'
    }
};


export const PlanPlatinumStrings = {

    MX: {
        'labelTitle': 'gBonita Platinum',
        'labelDescr': 'Para empresas pequeñas en crecimiento'
    }
};


export const PlanExtendedStrings = {

    MX: {
        'labelTitle': 'gBonita Extended',
        'labelDescr': 'Para un equipo o cuadrilla de reclutadores.'
    }
};

export const RegisterOnBoardingStrings = {

    MX: {
        'labelTitle': '!Hola!',
        'labelSubTitle': 'Que puesto estas buscando hoy?',
        'labelJob': 'Puesto',
        'labelSelectJob': 'Todos',
        'labelCountry': 'País',
        'labelSelectCountry': 'Selecciona una opc',
        'labelCity': 'Ciudad',
        'labelSelectCity': 'Selecciona una opc',
        'btnSave': 'Vamos!'
    }
};



export const MenuStrings = {

    MX: {
        'titleRecent': 'Recientes',
        'titleSearch': 'Búsqueda',
        'btnCargarMas': 'Cargar Más'
    }
};


export const CandidatosSearchStrings = {

    MX: {
        'labelNoInformation': 'No hay información disponible para sus criterios de búsqueda. Volver al menu de búsqueda y ajustar la seleción.',
        'labelAboutMe': 'Sobre mi:'
    }
};


export const SettingsStrings = {

    MX: {
        'labelName':'Nombre',
        'labelLastName': 'Apellido',
        'labelCompany': 'Nombre de tu empresa',
        'placeHolderCompany': 'Empresa',
        'labelEmail': 'Correo',
        'placeHolderEmail': 'ejemplo@empresa.com',
        'labelJob': 'Puesto',
        'labelPassword': 'Contraseña',
        'labelPhone': 'Telefono',
        'labelCountry': 'País',
        'labelCity': 'Ciudad',
        'labelPostCode': 'Código Postal',
        'labelPlan': 'Plan',
        'labelPrivacyPolicy1': 'Consulta nuestros ',
        'labelPrivacyPolicy2': 'Términos y Condiciones.',
        'socialMedia': 'Siguenos',
        'btnSave': 'Guardar',
        'btnLogOut': 'Cerrar Sesion'
    }
};



export const ChangePasswordStrings = {

    MX: {
        'btnChangePassword': 'Cambiar Contraseña',
        'labelCurrentPassword': 'Contraseña',
        'labelNewPassword': 'Nueva contraseña',
        'labelConfirmNewPassword': 'Confirmar contraseña'
    }
};



export const CandidatoProfileStrings = {

    MX: {
        'btnLogIn': 'Ingresa',
        'btnRegister':'Registrate',
        'socialMedia': 'Siguenos'
    }
};

export const CandidatoProfile_Prepare_Strings = {

    MX: {
        'titleEducation': 'Escolaridad:',
        'titleLanguages': 'Idiomas y nivel:',
        'titleExp': 'Años y Experiencia:',
        'titleSkills': 'Habilidades y Fortalezas:',
        'titleHobbies': 'Hobbies:'
    }
};


export const CandidatoProfile_Contacto_Strings = {

    MX: {
        'title': 'CONTACTO:',
        'labelEmail': 'Correo: ',
        'labelPhone': 'Telefono: '
    }
};

export const CandidatoProfile_AboutMe_Strings = {

    MX: {
        'title': 'Sobre mi:' 
    }
};

export const CandidatoProfile_CalificaPerfil_Strings = {

    MX: {
        'title': 'Califica este perfil'
    }
};


export const Footer_PrivacyTerms_Strings = {

    MX: {
        'privacyPolicyLabel': 'Política de Privacidad',
        'termsLabel': ' Términos y Condiciones'
    }
};





export const hobbiesCat = {

    EU: {
        1: { descrID: 'Physical conditioning' },
        2: { descrID: 'Contact sports' },
        3: { descrID: 'Outdoor sports' },
        4: { descrID: 'Watersports' },
        5: { descrID: 'Team sports' },
        6: { descrID: 'Running' },
        7: { descrID: 'Musical formation' },
        8: { descrID: 'Reading and writing' },
        9: { descrID: 'Plastic arts' },
        10: { descrID: 'Performing arts' },
        11: { descrID: 'Dance' },
        12: { descrID: 'Meditation / Yoga' },
        13: { descrID: 'Cinema' },
        14: { descrID: 'Photography' },
        15: { descrID: 'Travels' },
        16: { descrID: 'Videogames' },
        17: { descrID: 'Board games and strategy' },
        18: { descrID: 'Culinary training' }
    },
        
        
    MX: {
        1: { descrID: 'Acondicionamiento físico' },
        2: { descrID: 'Deportes de contacto' },
        3: { descrID: 'Deportes al aire libre' },
        4: { descrID: 'Deportes acuaticos' },
        5: { descrID: 'Deportes en equipo' },
        6: { descrID: 'Running' },
        7: { descrID: 'Formación musical' },
        8: { descrID: 'Lectura y escritura' },
        9: { descrID: 'Artes plásticas' },
        10: { descrID: 'Artes escénicas' },
        11: { descrID: 'Danza y Baile' },
        12: { descrID: 'Meditación / Yoga' },
        13: { descrID: 'Cine' },
        14: { descrID: 'Fotografía' },
        15: { descrID: 'Viajes' },
        16: { descrID: 'Videojuegos' },
        17: { descrID: 'Juegos de mesa y estrategia' },
        18: { descrID: 'Formación culinaria' }
    }
        
};







export const formacionCat = {

    EU: {
        1: { descrID: 'Doctor\'s degree' },
        2: { descrID: 'Master\'s degree' },
        3: { descrID: 'Bachelor\'s degree' },
        4: { descrID: 'Engineering' },
        5: { descrID: 'Certification' },
        6: { descrID: 'Certified' },
        7: { descrID: 'College student' },
        8: { descrID: 'Technical Baccalaureate' },
        9: { descrID: 'Student' }
    }
    ,
        
    MX: {
        1: { descrID: 'Doctorado' },
        2: { descrID: 'Maestria' },
        3: { descrID: 'Licenciatura' },
        4: { descrID: 'Ingenieria' },
        5: { descrID: 'Certificacion' },
        6: { descrID: 'Diplomado' },
        7: { descrID: 'Estudiante Universitario' },
        8: { descrID: 'Bachillerato Tecnico' },
        9: { descrID: 'Estudiante' }
    }

};


export const habilidadesFortalezasCat = {

    
    EU: {
        1: { descrCorta: 'Organization and planning', descrLarga: 'I am a methodical and organized person, for the fulfillment of my professional and personal objectives. I commit to the assigned time for tasks and deliveries to other areas or customers on the agreed dates.' },
        2: { descrCorta: 'Analysis and problem solving', descrLarga: 'I evaluated the information and looked at the sources critically, identifying gaps that lead to the full picture of the situation, I use this information to give more objective opinions and propose solutions.' },
        3: { descrCorta: 'Follow instructions and rules', descrLarga: 'I understand that the rules must be respected, for me they are created with a meaning. I have values and according to them I proceed. I follow processes and procedures.' },
        4: { descrCorta: 'Change management and strategic thinking', descrLarga: 'I adapt to change and uncertain situations, analyze the situation and find a solution to the problems, generate ideas and think strategically for decision-making in situations under pressure.' },
        5: { descrCorta: 'Teamwork', descrLarga: 'My technical and interpersonal skills help me achieve the goals by working as a team. I communicate and collaborate in the activities of a project for a common good.' },
        6: { descrCorta: 'Emotional intelligence', descrLarga: 'I generate understanding and control my emotions according to the need of the environment, I recognize the emotions of others. I manage my emotions to achieve positive results in my relationships with others.' },
        7: { descrCorta: 'Relationships', descrLarga: 'I am a sociable person, I interact easily, I establish contacts and in general I adapt easily in work and personal environments; When needed I use interpersonal skills to relate.' },
        8: { descrCorta: 'Service attitude', descrLarga: 'I have an attitude of service, I show interest in others and their needs in the personal and work environment. I anticipate the needs of others and listen to my clients and coworkers.' },
        9: { descrCorta: 'Negotiation', descrLarga: 'I establish good working relationships, I have negotiated with clients and colleagues in various situations, getting cooperation and finding the right arguments to conduct successful negotiations.' },
        10: { descrCorta: 'Personal aspiration', descrLarga: 'I am looking for professional and personal success, to achieve it I plan medium and long term goals, developing activities such as study and work. I project myself and create plans to achieve my goals.' },
        11: { descrCorta: 'Leadership', descrLarga: 'I have led teams, facilitating cohesion and allowing the development of collaborators. I have had projects and manage to achieve the expected results. Empower, motive and guide the tasks.' },
        12: { descrCorta: 'Digital skills', descrLarga: 'I establish rapid learning of the new technologies, I understand that they are part of the strategic vision of companies. I manage information technologies, work, collaborate and cooperate in digital environments.' },
        13: { descrCorta: 'Self-management', descrLarga: 'I am an active worker, I make decisions and make the necessary developments to fulfill my work. I believe that I have the capacity and autonomy to achieve the objectives.' },
        14: { descrCorta: 'Achievement orientation', descrLarga: 'I am a persevering person, who before opportunities and challenges assume responsibilities and commitments to meet the objectives in a timely manner.' }
        }
        ,
    MX: {
        1: { descrCorta: 'Organización y planificación', descrLarga: 'Soy una persona metódica y organizada, para el cumplimiento de mis objetivos profesionales y personales. Me comprometo con los tiempos asignados para las tareas y entregas a otras áreas o clientes en las fechas pactadas.' },
        2: { descrCorta: 'Análisis y resolución de problemas', descrLarga: 'Evaluó la información y miro las fuentes de manera crítica, identificando vacíos que llevan al panorama completo de la situación, utilizo esta información para dar opiniones mas objetivas y proponer soluciones.' },
        3: { descrCorta: 'Seguimiento de instrucciones y normas', descrLarga: 'Comprendo que las normas hay que respetarlas, para mi están creadas con un sentido. Tengo valores y conforme a ellos procedo. Sigo procesos y procedimientos.' },
        4: { descrCorta: 'Gestión de cambio y pensamiento estratégico', descrLarga: 'Yo me adapto al cambio y a situaciones inciertas, analizó las situación y encuentro solución a los problemas, genero ideas y pienso estratégicamente para toma de decisiones en situaciones bajo presión.' },
        5: { descrCorta: 'Trabajo en equipo', descrLarga: 'Mis habilidades técnicas e interpersonales me ayudan a lograr los objetivos trabajando en equipo. Comunico y colaboro en las actividades de un proyecto para un bien comun.' },
        6: { descrCorta: 'Inteligencia emocional', descrLarga: 'Genero entendimiento y controlo mis emociones según sea la necesidad del entorno, reconozco las emociones  de los demás. Gestiono mis emociones para lograr resultados positivos en mis relaciones con los demás.' },
        7: { descrCorta: 'Relaciones interpersonales', descrLarga: 'Soy una persona sociable, me relaciono fácilmente, establezco contactos  y en general me adapto fácilmente  en ambientes laborales como personales; cuando se necesita hago uso de habilidades interpersonales para relacionarme.' },
        8: { descrCorta: 'Actitud de servicio', descrLarga: 'Tengo actitud de servicio, demuestro interés por los demás y sus necesidades en el ámbito personal y laboral. Me adelanto a las necesidades de los demás y escucho a mis clientes y compañeros de trabajo.' },
        9: { descrCorta: 'Negociación', descrLarga: 'Establezco buenas relaciones de trabajo, he negociado con clientes y compañeros en diversas situaciones, consiguiendo cooperación y encontrando los argumentos correctos para llevar negociaciones acertadas.' },
        10: { descrCorta: 'Aspiración personal', descrLarga: 'Busco el éxito profesional y personal,  para lograrlo planifico metas a mediano y largo plazo, desarrollando actividades como estudio y trabajo. Me proyecto y creo planes para lograr mis metas.' },
        11: { descrCorta: 'Liderazgo', descrLarga: 'He liderado equipos, facilitando la cohesión y permitiendo el desarrollo de los colaboradores. He tenido proyectos y gestiono para conseguir los resultados esperados. Empodero, motivo y guío las tareas.' },
        12: { descrCorta: 'Habilidades digitales', descrLarga: 'Establezco un aprendizaje rápido ante nuevas tecnologias, entiendo que son parte de la visión estratégica de las compañías. Gestiono tecnologias de la  información, trabajo, colaboro y coopero en entornos digitales.' },
        13: { descrCorta: 'Autogestión', descrLarga: 'Soy un trabajador activo, tomo decisiones y realizo los desarrollos necesarios para cumplir con mi labor. Considero que tengo  la capacidad de cooperar para conseguir los objetivos.' },
        14: { descrCorta: 'Orientación al logro', descrLarga: 'Soy  una persona perseverante, que ante oportunidades y retos asumo responsabilidades y compromisos para cumplir los objetivos en tiempo y forma.' }
    }
                
};


export const idiomaLevelCat = {

 
    EU: {
        1: { descrID: '-' },
        2: { descrID: 'Basic' },
        3: { descrID: 'Intermediate' },
        4: { descrID: 'Advance' }
    },
    MX: {
        1: { descrID: '-' },
        2: { descrID: 'Basico' },
        3: { descrID: 'Intermedio' },
        4: { descrID: 'Avanzado' }
    }
};



export const idiomaCat = {

    EU: {
        1: { descrString: '-' },
        2: { descrString: 'Afrikaans' },
        3: { descrString: 'Amharic' },
        4: { descrString: 'Arabic' },
        5: { descrString: 'Bahasa' },
        6: { descrString: 'Bengali' },
        7: { descrString: 'Burmese' },
        8: { descrString: 'Chinese' },
        9: { descrString: 'Czech' },
        10: { descrString: 'Danish' },
        11: { descrString: 'Dutch' },
        12: { descrString: 'English' },
        13: { descrString: 'Farsi (Persian)' },
        14: { descrString: 'Finnish' },
        15: { descrString: 'Flemish' },
        16: { descrString: 'French' },
        17: { descrString: 'German' },
        18: { descrString: 'Greek' },
        19: { descrString: 'Hebrew' },
        20: { descrString: 'Hindi' },
        21: { descrString: 'Hungarian' },
        22: { descrString: 'Italian' },
        23: { descrString: 'Japanese' },
        24: { descrString: 'Kiswahili' },
        25: { descrString: 'Korean' },
        26: { descrString: 'Laotian' },
        27: { descrString: 'Latin' },
        28: { descrString: 'Latvian' },
        29: { descrString: 'Lithuanian' },
        30: { descrString: 'Malay' },
        31: { descrString: 'Norwegian' },
        32: { descrString: 'Polish' },
        33: { descrString: 'Portuguese' },
        34: { descrString: 'Punjabi' },
        35: { descrString: 'Rumanian' },
        36: { descrString: 'Russian' },
        37: { descrString: 'Serbo-Croatian' },
        38: { descrString: 'Spanish' },
        39: { descrString: 'Swahili' },
        40: { descrString: 'Swedish' },
        41: { descrString: 'Tagalog (Philippines)' },
        42: { descrString: 'Tamil' },
        43: { descrString: 'Telugu' },
        44: { descrString: 'Thai' },
        45: { descrString: 'Turkish' },
        46: { descrString: 'Twi (Ghana)' },
        47: { descrString: 'Ukrainian' },
        48: { descrString: 'Urdu (Pakistan)' },
        49: { descrString: 'Vietnamese' },
        50: { descrString: 'Welsh' }
    },
    
    
    MX: {
        1: { descrString: '-' },
        2: { descrString: 'Africano' },
        3: { descrString: 'Amárico' },
        4: { descrString: 'Árabe' },
        5: { descrString: 'Indonesio' },
        6: { descrString: 'Bengalí' },
        7: { descrString: 'Birmano' },
        8: { descrString: 'Chino' },
        9: { descrString: 'Checo' },
        10: { descrString: 'Danés' },
        11: { descrString: 'Holandés' },
        12: { descrString: 'Inglés' },
        13: { descrString: 'Persa' },
        14: { descrString: 'Finlandés' },
        15: { descrString: 'Flamenco' },
        16: { descrString: 'Francés' },
        17: { descrString: 'Alemán' },
        18: { descrString: 'Griego' },
        19: { descrString: 'Hebreo' },
        20: { descrString: 'Hindi' },
        21: { descrString: 'Húngaro' },
        22: { descrString: 'Italiano' },
        23: { descrString: 'Japonés' },
        24: { descrString: 'Suajili' },
        25: { descrString: 'Coreano' },
        26: { descrString: 'Laosiano' },
        27: { descrString: 'Latino' },
        28: { descrString: 'Letón' },
        29: { descrString: 'Lituano' },
        30: { descrString: 'Malayo' },
        31: { descrString: 'Noruego' },
        32: { descrString: 'Polaco' },
        33: { descrString: 'Portugués' },
        34: { descrString: 'Panyabí' },
        35: { descrString: 'Rumano' },
        36: { descrString: 'Ruso' },
        37: { descrString: 'Serbocroata' },
        38: { descrString: 'Español' },
        39: { descrString: 'Swahili' },
        40: { descrString: 'Sueco' },
        41: { descrString: 'Tagalo (Filipinas)' },
        42: { descrString: 'Tamil' },
        43: { descrString: 'Telugu' },
        44: { descrString: 'Tailandés' },
        45: { descrString: 'Turco' },
        46: { descrString: 'Twi (Ghana)' },
        47: { descrString: 'Ucraniano' },
        48: { descrString: 'Urdu (Pakistán)' },
        49: { descrString: 'Vietnamita' },
        50: { descrString: 'Galés' }
    }
        

};


    