
export const types = {

    //Auth
    logout: '[Auth] Logout',
    login: '[Auth] Login',
    register: '[Register] Register',
    setIsLoggedIn: '[isLoggedIn]',
    authUser: '[Auth] authUser',
    checkingAuth: '[Auth] checkingAuth',
    updateUserSettings: '[Auth] updateUserSettings',
    updateUserSettingsProfilePhoto: '[Auth] updateUserSettingsProfilePhoto',
    //LogIn
    errorAuthLogIn: '[errorAuthLogIn]',
    setStateLogIn: '[setStateLogIn]',
    resetStateLogIn: '[resetStateLogIn]',
    //Register
    setStateRegister: '[setStateRegister]',
    resetStateRegister: '[resetStateRegister]',
    errorAuthRegister: '[errorAuthRegister]',
    //Settings
    setStateSettings: '[setStateSettings]',
    errorAuthSettings: '[errorAuthSettings]',
    resetStateSettings: '[resetStateSettings]',
    //ChangePassword
    setStateChangePassword:  '[setStateChangePassword]', 
    resetStateChangePassword: '[resetStateChangePassword]',
    errorAuthChangePassword: '[errorAuthChangePassword]',
    //UI Reducer
    sidebar: '[SideBar] Enable/Disble',
    showSideBar: '[SideBar] Show SideBar',
    clickShowSidebar: '[SideBar] Show/Hide',
    showSearchBar: 'searchBar Show/Hide',
    //Loading
    setLoadingLogIn: 'setLoadingLogIn',
    setLoadingRegister: 'setLoadingRegister',
    setLoadingChangePassword: 'setLoadingChangePassword',
    setLoadingCandidatosInfo: 'setLoadingCandidatosInfo',
    setLoadingCandidatosRecientes: 'setLoadingCandidatosRecientes',
    setLoadingVideoOnCadidatoProfile: 'loadingVideoOnCadidatoProfile',
    //Search
    userSelected: '[userSelected]',
    recentUsers: '[recentUsers]',
    searchUsersFilter: '[searchUsersFilter]',
    searchUsersNextFilter: '[searchUsersNextFilter]',
    setSearchOnBoarding: '[setSearchOnBoarding]',
    setFiltersSearch: '[setFiltersSearch]'
}