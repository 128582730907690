import { types } from '../types/types';


const initialState = 
{
    stateChangePassword:{
        currentPassword: '', 
        newPassword: '', 
        confirmNewPassword: ''
    },
    errorState:{

        currentPassword:{
            status: false,
            msg: ''
        },
        newPassword:{
            status: false,
            msg: ''
        },
        confirmNewPassword:{
            status: false,
            msg: ''
        },
        serv:{
            status: false,
            msg: ''
        },
        clickChangePassword: false
    }
}

export const stateChangePasswordReducer = ( state = initialState, action ) => {

    switch ( action.type ) {
    
        case types.setStateChangePassword:
            return{
                ...state,
                stateChangePassword:{
                    ...action.payload.stateChangePassword
                }
            }
      
        case types.errorAuthChangePassword:
            return{
                ...state,
                errorState:{
                    ...state.errorState,
                    ...action.payload
                }
            }
            
        case types.resetStateChangePassword:
            return initialState;
            
        default:
            return state;
    }

}