import React, { memo } from 'react';

import { v4 as uuid_v4 } from 'uuid';

//Components
import CandidatoInfo from './CandidatoInfo';
import CandidatoInfoSkeleton from './CandidatoInfoSkeleton';

//Info Example
import { usersSearchSkeletonData } from '../../data/info';

import { CandidatosSearchStrings } from '../../data/strings';


//const searchUsers = {users: [{user:''}, {user:''}, {user:''}]}
//const loadingCandidatosInfo = true

const ContainerCandidatoInfo = memo((props) => {

    //const { searchUsers } = useSelector(state => state.searchReducer);
    const { loadingCandidatosInfo, searchUsers } = props; //const { loadingCandidatosInfo } = useSelector(state => state.uiReducer);
    const strings = CandidatosSearchStrings['MX'];


    return (
        
       <div className="col-12 p-0">

            <div className="row m-0">

                {
                   <div className="col-12 p-0">

                        <div className="row m-0">
            
                            {
                                searchUsers && searchUsers.users.length > 0 
                                ?
                                    searchUsers.users.map(user => <CandidatoInfo key={uuid_v4()} 
                                                                        user={user} 
                                                                        viewProfile={props.viewProfile} 
                                                                        strings={strings}/> )
                                :
                                    loadingCandidatosInfo 
                                        ? 
                                            usersSearchSkeletonData &&
                                            usersSearchSkeletonData.map(user => <CandidatoInfoSkeleton key={uuid_v4()} />)
                                        :

                                            <div className="row m-0 searchNoInformation">
                                                <div className="col-12">
                                                    <h4>{strings.labelNoInformation}</h4>
                                                </div>
                                            </div>
                                           
                            }
            
                            {
                                loadingCandidatosInfo && <div className="containerItemsCandidatoInfoLoading">
            
                                </div>
                            }
            
                            </div>
                    </div>
                   
                }

               

            </div>
       </div>
       
    )

}, (prevProps, nextProps) => {

    if(prevProps.loadingCandidatosInfo !== nextProps.loadingCandidatosInfo ||
        prevProps.searchUsers.users !== nextProps.searchUsers.users){

        //console.log("Memo true");
        return false;
    }

    //console.log("Memo false");
    return true;
});

export default ContainerCandidatoInfo;
