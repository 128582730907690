import { validFormatPassword } from './PasswordModel';
import { FieldError } from './FieldError';

export class ChangePasswordModel {

    constructor(stringsAuth) {
        this.errorStatus = false;
        this.currentPasswordError = new FieldError ();
        this.newPasswordError = new FieldError ();
        this.confirmNewPasswordError = new FieldError ();
        this.stringsAuth = stringsAuth;
    }

    validate(stateForm){
        this.errorStatus = false;

        let validatePassword = validFormatPassword(stateForm.currentPassword);
        if(!validatePassword.status) {
            this.currentPasswordError.setValues(true, validatePassword.error);
            this.errorStatus = true;
        }

        validatePassword = validFormatPassword(stateForm.newPassword);
        if(!validatePassword.status) {
            this.newPasswordError.setValues(true, validatePassword.error);
            this.errorStatus = true;
        }

        validatePassword = validFormatPassword(stateForm.confirmNewPassword);
        if(!validatePassword.status){
            this.confirmNewPasswordError.setValues(true, validatePassword.error); 
            this.errorStatus = true;
        }

        if(stateForm.newPassword !== stateForm.confirmNewPassword){
            this.confirmNewPasswordError.setValues(true, this.stringsAuth.FieldPasswordNotMatchError); 
            this.errorStatus = true;
        }
    }

    toObject(){
        return {
            currentPassword: this.currentPasswordError.toObject(),
            newPassword: this.newPasswordError.toObject(),
            confirmNewPassword: this.confirmNewPasswordError.toObject(),
            serv:{
                status: false,
                msg: ''
            }
        }
    }
}
