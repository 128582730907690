import React,  { useEffect, useState } from 'react';

//Redux
import { useDispatch, useSelector } from 'react-redux';

//Data
import { habilidadesFortalezasCat, formacionCat, hobbiesCat, idiomaLevelCat, idiomaCat } from '../data/strings';

//Components
import VideoCandidatoProfile from '../components/CandidatoProfile/VideoCandidatoProfile';
import HeaderCandidatoProfile from '../components/CandidatoProfile/HeaderCandidatoProfile';
import PrepareExp from '../components/CandidatoProfile/PrepareExp';
import PrepareHobbies from '../components/CandidatoProfile/PrepareHobbies';
import PrepareSkills from '../components/CandidatoProfile/PrepareSkills';
import PrepareEducation from '../components/CandidatoProfile/PrepareEducation';
import PrepareLanguages from '../components/CandidatoProfile/PrepareLanguages';
import CalificaPerfil from '../components/CandidatoProfile/CalificaPerfil';
import Contacto from '../components/CandidatoProfile/Contacto';
import AboutMe from '../components/CandidatoProfile/AboutMe';

//Actions
import { getUserByID } from '../redux/actions/searchAction';
import { setLoadingVideoOnCadidatoProfile } from '../redux/actions/uiAction';

//Helpers
//import { getJob } from '../helpers/helpers';
//Hooks
import { useAccessModule } from '../hooks/useAccessModule';

//Helpers
import { accessModuleProfileUser } from '../helpers/accessModule';

//Images
import BTN_formal_press from '../assets/img/CandidatoProfile/BTN_formal_press.png';
import BTN_formal_up from '../assets/img/CandidatoProfile/BTN_formal_up.png';
import BTN_informal_press from '../assets/img/CandidatoProfile/BTN_informal_press.png';
import BTN_informal_up from '../assets/img/CandidatoProfile/BTN_informal_up.png';

//Strings
import { CandidatoProfile_Prepare_Strings, CandidatoProfile_Contacto_Strings, CandidatoProfile_CalificaPerfil_Strings, CandidatoProfile_AboutMe_Strings } from '../data/strings';

 const CandidatoProfile = (props) => {
    
    const [ validateAccess ] = useAccessModule(accessModuleProfileUser);

    const dispatch = useDispatch();

    const { showSideBarSts } = useSelector(state => state.uiReducer);
    const { userSelected } = useSelector(state => state.searchReducer);

    const [stateTypeVideo, setTypeVideo] = useState(
        {
            videoFormal: true
        }
    );

    const { videoFormal } = stateTypeVideo;

    
    useEffect(() => {

        validateAccess();
         
    }, [validateAccess]);


    //Si pageSelected no es null tomar valores para mostrar
    //Si pageSelected es null request valores
    useEffect(() => {

        console.log("Candidato Profile -> setUserSelected");

        const ejecutarFuncion = () => {

            if(typeof(userSelected) === 'undefined' || userSelected === null || Object.keys(userSelected.user).length === 0){
    
                const pathname = props.history.location.pathname;
                const splitPath = pathname.split('/');
                const _uid = splitPath[splitPath.length -1];
        
                console.log("log desde _uid", _uid);
    
                dispatch(getUserByID(_uid));
            }
           
        }

        ejecutarFuncion();
      
    }, [dispatch, userSelected, props]);

    const handleClickVideoFormal = () =>{
        
        if(!stateTypeVideo.videoFormal){
            dispatch(setLoadingVideoOnCadidatoProfile(true));
        }

        //console.log("handleClickVideoFormal");
        setTypeVideo({videoFormal: true});
    }

    const handleClickVideoInformal = () =>{
        
        if(stateTypeVideo.videoFormal){
            dispatch(setLoadingVideoOnCadidatoProfile(true));
        }

        //console.log("handleClickVideoInformal");
        setTypeVideo({videoFormal: false});
    }

    const page = (

        <div className="row m-0 mainContainerProfileCandidato">


            <HeaderCandidatoProfile data={userSelected}/>

    
            <div className="subContainerProfileCandidato">
                <div className="row m-0">


                    <div className="subContainerProfileCandidato_C1">


                            <div className="col-12">
                                <VideoCandidatoProfile dataVideo={{videoFormal:videoFormal, userFiles:{...userSelected.userFiles}}} />
                            </div>    

                            <div className="containerTypeVideoCandidato col-12 text-center">
                                <img className="imgVideoCandidatoProfile" src={ videoFormal ? BTN_formal_press : BTN_formal_up } alt="videoFormal" onClick={handleClickVideoFormal}></img>
                                <img className="imgVideoCandidatoProfile" src={ !videoFormal ? BTN_informal_press : BTN_informal_up } alt="videoInformal" onClick={handleClickVideoInformal}></img>
                            </div>

                            <div className="col-12 p-0">
                                <AboutMe 
                                    group_HabilidadesFortalezas={userSelected.userPrepare.group_HabilidadesFortalezas}
                                    strings={CandidatoProfile_AboutMe_Strings['MX']}
                                />
                            </div>    


                            <div className="col-12 p-0">
                                <div className="borderPrepareProfileCandidato"/>
                            </div>

                    </div>





                    <div className="subContainerProfileCandidato_C2">


                        <div className="containerPrepareProfileCandidato row m-0">

                            <PrepareEducation 
                                group_NivelFormacion={userSelected.userPrepare.group_NivelFormacion} 
                                formacionCat={formacionCat['MX']} 
                                strings={CandidatoProfile_Prepare_Strings['MX']}
                            />


                            <PrepareLanguages 
                                group_LanguageLevelID={userSelected.userPrepare.group_LanguageLevelID} 
                                idiomaCat={idiomaCat['MX']} idiomaLevelCat={idiomaLevelCat['MX']} 
                                strings={CandidatoProfile_Prepare_Strings['MX']}
                            />


                            <PrepareExp 
                                expAge={userSelected.userPrepare.expAge} 
                                strings={CandidatoProfile_Prepare_Strings['MX']}
                            />


                            <PrepareSkills 
                                group_HabilidadesFortalezas={userSelected.userPrepare.group_HabilidadesFortalezas} 
                                habilidadesFortalezasCat={habilidadesFortalezasCat['MX']} 
                                strings={CandidatoProfile_Prepare_Strings['MX']}
                            />
                        

                            <PrepareHobbies 
                                group_Hobbies={userSelected.userPrepare.group_Hobbies} 
                                hobbiesCat={hobbiesCat['MX']} 
                                strings={CandidatoProfile_Prepare_Strings['MX']}
                            />

                        </div>   



                        <div className="col-12 p-0">
                            <div className="borderPrepareProfileCandidato"/>
                        </div>


                        <div className="col-12 p-0">
                            <Contacto 
                                user={userSelected.user} 
                                strings={CandidatoProfile_Contacto_Strings['MX']}
                            />     
                        </div>   



                        <div className="col-12 p-0">
                            <div className="borderPrepareProfileCandidato"/>
                        </div>


                        <div className="col-12 p-0">
                            <CalificaPerfil 
                                strings={CandidatoProfile_CalificaPerfil_Strings['MX']}
                            />
                        </div>


                        <div className="col-12 p-0">
                            <div className="borderPrepareProfileCandidato"/>
                        </div>

                    </div>    



                </div>
            </div>

          

        </div>   
    )

    return (

        <div className={`${showSideBarSts ? 'menuDisplayed': ''} wrapper`}>
            <div className="page-content-wrapper">
                <div className="container-fluid p-0">

                    { page }

                </div>
            </div>
        </div>
    )
    
}

export default CandidatoProfile;