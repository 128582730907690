import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

//Actions
import { showSideBar } from '../redux/actions/uiAction';

const Terms = () => {
    
    const dispatch = useDispatch();

    useEffect(() => {

        dispatch(showSideBar(false));

    }, [dispatch]);

    return (
        
        <div className="wrapper">
            <div className="container">
                <br/>
                <br/>
                <h1>Términos y condiciones de uso del servicio</h1>
                <br/>

                <h3 className="privacyTitle"><strong>1. Introducción</strong></h3>

                    <p className="pt-2 privacyDescr">Esta página establece los "términos y condiciones" bajo los cuales usted acepta utilizar GBonita disponible online a través de cualquier dispositivo. Por favor lea esta página cuidadosamente. 
                        Si usted no está de acuerdo con los términos y condiciones establecidos aquí, no utilice este sitio de Internet, su aplicación móvil, widget, ni los servicios proporcionados por GBonita.</p>

                <h3 className="privacyTitle"><strong>2. Aceptación de Términos</strong></h3>

                    <p className="pt-2 privacyDescr">Al utilizar el sitio de Internet, descargar e instalar la aplicación móvil, o utilizar el Widget de GBonita, usted acepta obligarse a estos términos y condiciones. Los términos y condiciones pueden 
                    ser actualizados por GBonita en cualquier momento y sin previo aviso por lo que debe visitar ésta página periódicamente para consultar la versión más reciente de los términos y condiciones. Los términos "usted" y "usuario" según 
                    se utilizan aquí hacen referencia a todas las personas y/o entidades que acceden a este sitio de Internet, su aplicación móvil, widget o cualquier plataforma desarrollada por GBonita.</p>

                <h3 className="privacyTitle"><strong>3. Acuerdo Obligatorio</strong></h3>

                    <p className="pt-2 privacyDescr">Estos términos forman un acuerdo obligatorio entre usted y GBonita. GBonita se reserva el derecho de cambiar estas reglas en cualquier momento y sin necesidad de notificación previa. 
                    El acceso y uso a GBonita indica su aceptación de estos términos. Asimismo, usted acuerda utilizar GBonita a su propio riesgo. Tratándose de contratación electrónica de servicios, ésta se perfeccionará desde que se 
                    reciba la aceptación de la propuesta o las condiciones con que ésta fuere modificada.</p>

                <h3 className="privacyTitle"><strong>4. Descripción del Servicio</strong></h3>

                    <p className="pt-2 privacyDescr">El servicio que se presta a través del sitio web y aplicación móvil GBonita operado por Gbonita Business Consulting S.A. de C.V. (en adelante GBonita) quién es su propietario, 
                    es exclusivamente proporcionar una licencia de uso limitada para que empleadores ajenos a GBonita redacten y publiquen ofertas de trabajo y anuncios de empleo en la plataforma, y a su vez los candidatos publiquen sus 
                    currículums en el sistema. En virtud de lo anterior, GBonita no es responsable por el contenido o veracidad de las publicaciones realizadas por los empleadores/reclutadores en el Sitio Web y Aplicación Móvil.
                    De igual forma, GBonita es un intermediario para la oferta de servicios educativos y de capacitación prestados por terceros, con los cuales GBonita mantiene convenios de colaboración.</p>

                    <p className="pt-2 privacyDescr">La liberación de nuevos productos y propiedades queda sujeta a estos términos. Usted entiende y acuerda que los servicios mencionados anteriormente son proporcionados “como- es" y 
                    que GBonita no asumen ninguna responsabilidad por la eliminación, falla en la entrega o en el almacenamiento de cualquier comunicación o características de personalización.</p>

                <h3 className="privacyTitle"><strong>5. Comunicaciones</strong></h3>

                    <p className="pt-2 privacyDescr">Queda establecido que usted entiende y acepta que el servicio puede incluir ciertas comunicaciones de GBonita, como mensajes administrativos, anuncios de servicio y correos transaccionales, 
                    estas comunicaciones son consideradas como parte del servicio de GBonita y usted no puede excluir la recepción de dichas comunicaciones.</p>
                    <p className="pt-2 privacyDescr">En el caso de que un usuario reciba comunicaciones de este sitio web o su aplicación móvil sin haberse registrado, o sin haber dado su consentimiento expreso a dicho registro, 
                    puede cancelar la suscripción de conformidad al procedimiento establecido en el Aviso de Privacidad de GBonita.</p>

                <h3 className="privacyTitle"><strong>6. Definiciones</strong></h3>

                    <p className="pt-2 privacyDescr">Las siguientes son definiciones de términos usadas o aplicadas a la información aquí contenida:</p>
                    <ul>
                        <li><p className="privacyDescr"><strong>“Recolección de datos del Candidato":</strong> Significa el proceso de obtener información (personal y/o de empleo) sobre un candidato.</p></li>
                        <li><p className="privacyDescr"><strong>“Contenido":</strong> Incluye todo el texto, gráficas, diseño y programación usada en el Sitio Web. Asimismo, incluye la herramienta llamada “ATS", cuya propiedad intelectual detenta gBonita. </p></li>
                        <li><p className="privacyDescr"><strong>“Diseño":</strong> Incluye las combinaciones de color y el diseño del Sitio Web y Aplicación Móvil</p></li>
                        <li><p className="privacyDescr"><strong>“Empleador/Reclutador":</strong> Significa la persona física o entidad legal que ingresa al sitio web y/o aplicación móvil para colocar un anuncio de empleo o por cualquier razón está relacionada al propósito de buscar candidatos.</p></li>
                        <li><p className="privacyDescr"><strong>“Gráficas":</strong> Incluye todos los logotipos, botones y otros elementos gráficos en el Sitio Web y Aplicación Móvil, con la excepción de banners de publicidad</p></li>
                        <li><p className="privacyDescr"><strong>“Sitio GBonita":</strong> Incluye www.gBonita.com en su totalidad, su aplicación móvil, así como los sitios relacionados que opera y son propiedad de Gbonita Business Consulting S.A. de C.V. incluyendo su contenido, texto, gráficas, diseño, programación y servicios aplicables en el contexto. La herramienta de servicio “ATS" está incluida bajo los presentes términos.</p></li>
                        <li><p className="privacyDescr"><strong>“Candidato":</strong> Significa un usuario que accede al Sitio Web y Aplicación Móvil para buscar un empleo y que no es un empleador.</p></li>
                        <li><p className="privacyDescr"><strong>“Materiales":</strong> Se refiere a cualquier publicación hecha en el Sitio Web. La herramienta de servicio “ATS" está incluida bajo los presentes términos.</p></li>
                        <li><p className="privacyDescr"><strong>“Programación":</strong> Incluye códigos de ambos lados del cliente (HTML, JavaScript, etc.) y el código del servidor (Páginas activas del servidor, VBScript, Bases de Datos, etc.) usadas en el Sitio Web.</p></li>
                        <li><p className="privacyDescr"><strong>“Servicios":</strong> Significa cualquier servicio proporcionado por Gbonita Business Consulting S.A de C.V., sus agentes o miembros, incluyendo la herramienta de servicio denominada “ATS".</p></li>
                        <li><p className="privacyDescr"><strong>“Texto":</strong> Incluye todo el texto en cada página del Sitio Web y Aplicación Móvil, ya sea editorial, de navegación en la página o instruccional.</p></li>
                        <li><p className="privacyDescr"><strong>“Terceras partes":</strong> Incluye cualquier persona o entidad no especificada directamente en este acuerdo.</p></li>
                        <li><p className="privacyDescr"><strong>“Usuario":</strong> Se refiere a cualquier individuo o entidad que utilice cualquier aspecto del Sitio Web y Aplicación Móvil.</p></li>
                        <li><p className="privacyDescr"><strong>“Usted":</strong> Significa la persona (o la entidad representada por la que se está actuando) que está aceptando estos términos y condiciones.</p></li>
                    </ul>
                

                <h3 className="privacyTitle"><strong>7. Reglas de Uso General del Sitio GBonita y Aplicación Móvil</strong></h3>

                    <p className="pt-2 privacyDescr">El portal y aplicación móvil están previstos para individuos que buscan trabajo y para empleadores o reclutadores que buscan candidatos para ofrecerles empleo. 
                    Usted debe usar este Sitio Web y Aplicación Móvil con intenciones legítimas y de acuerdo con las indicaciones de uso dictadas por Gbonita Business Consulting S.A de C.V., GBonita es la única entidad 
                    que interpretará el uso aceptable del Sitio Web y Aplicación Móvil.</p>

                <h3 className="privacyTitle"><strong>8. Licencia de uso para empleadores/reclutadores</strong></h3>

                    <p className="pt-2 privacyDescr">GBonita otorga, durante la vigencia de los servicios contratados, una licencia de uso revocable, de responsabilidad limitada, terminable y con derechos de no exclusividad para accesar y usar el Sitio Web, 
                    Widget (previa contratación) y Aplicación Móvil de GBonita para el uso interno del empleador/reclutador en la búsqueda de candidatos para ofrecerles empleo. Esto le autoriza al empleador/reclutador a ver el material del Sitio Web y 
                    Aplicación Móvil con el único propósito de buscar y reclutar candidatos. GBonita se reserva el derecho de suspender temporal o definitivamente sus claves de acceso o dar por terminado su contrato si se determina que existe un 
                    incumplimiento de cualquiera de estos términos y condiciones. Usted no podrá comercializar ni revender en forma alguna los servicios o porciones de éstos. Estos términos se hacen extensibles al uso de todas las modalidades y 
                    herramientas como “ATS" cuya propiedad intelectual detenta HS GROUP S.R.L.</p>

                    <p className="pt-2 privacyDescr">GBonita otorga al empleador/reclutador contratante del servicio una clave de usuario administrador, cuyo titular será responsable de la existencia y veracidad de la información de las subcuentas generadas, 
                    así como por el uso que dichas claves den al servicio contratado</p>

                <h3 className="privacyTitle"><strong>9. Transferencia de datos personales</strong></h3>

                    <p className="pt-2 privacyDescr">De conformidad con la Ley Federal de Protección de Datos Personales en Posesión de los Particulares vigente en México, GBonita es responsable transferente y el empleador/reclutador se convierte en responsable 
                    receptor, por lo que, en cumplimiento con las disposiciones de la materia, el empleador/reclutador al contratar el servicio, se obliga a:</p>

                    <ul>
                        <li><p className="privacyDescr">Mantener la confidencialidad de los datos personales, utilizándolos únicamente para efectos de reclutamiento y selección de personal y con ningún otro propósito.</p></li>
                        <li><p className="privacyDescr">No transferir a ningún tercero, datos personales que recibe en su calidad de responsable receptor, salvo por lo dispuesto en el artículo 70 de reglamento de LFPDPPP.</p></li>
                        <li><p className="privacyDescr">Garantizar que solo sus empleados tendrán acceso a los datos transferidos y que ninguno de ellos dará un tratamiento distinto al de reclutamiento y selección de personal.
                            GBonita se deslinda de cualquier responsabilidad presente o futura, que surja del uso no convenido de los datos personales que le han sido transferidos a los empleadores/reclutadores, 
                            por lo que en caso de contravenir estas disposiciones se obligan a sacar en paz y a salvo a GBonita, pagando los daños y perjuicios causados por esta situación.</p></li>
                        
                    </ul>

                <h3 className="privacyTitle"><strong>10. Licencia de uso para Candidatos</strong></h3>

                    <p className="pt-2 privacyDescr">GBonita otorga un acuerdo de responsabilidad limitada, terminable y con derechos de no exclusividad para accesar y usar el Sitio Web y Aplicación Móvil para el uso personal en la búsqueda de oportunidades de 
                    empleo para usted mismo. Esto le autoriza a usted a ver el material del Sitio Web y Aplicación Móvil solamente para su uso personal y no para uso comercial con fines de lucro. El uso del Sitio Web y Aplicación Móvil es un privilegio. 
                    GBonita se reserva el derecho de suspender o terminar este privilegio por cualquier razón y en cualquier momento. </p>
                    <p className="pt-2 privacyDescr">Estos términos se hacen extensibles al uso de todas las modalidades y herramientas como “ATS" cuya propiedad intelectual detenta gBonita. </p>


                <h3 className="privacyTitle"><strong>11. Otras Reglas Particulares de Uso del Sitio Web y Aplicación Móvil</strong></h3>

                    <p className="pt-2 privacyDescr">Usted representa, garantiza y acepta que usted no usará (o planeará, motivará o ayudará a otros a usar) el Sitio Web y Aplicación Móvil para cualquier otro propósito o que en cualquier manera esté prohibido por 
                    los términos aquí mencionados o los que sean aplicables por ley. Es su responsabilidad asegurar que usted use el Sitio Web y Aplicación Móvil de acuerdo con los términos y condiciones aquí especificados.</p>

                <h3 className="privacyTitle"><strong>12. Reglas de Publicación, Conducta y Seguridad</strong></h3>

                    <p className="pt-2 privacyDescr">Usted acepta cumplir con las reglas de GBonita para la Publicación de Anuncios, de Conducta y de Seguridad en este Sitio Web y Aplicación Móvil. Los usuarios que violen dichas reglas tendrán el uso y el acceso del 
                    sitio y aplicación suspendido o cancelado a discreción exclusiva de GBonita. Las reglas de Conducta, Publicación y Seguridad son como se describe a continuación:</p>

                <h3 className="privacyTitle"><strong>12.1 General</strong></h3>

                    <p className="pt-2 privacyDescr">La información de contacto de GBonita está listada en el Sitio Web y Aplicación Móvil. GBonita y sus agentes asociados no asumen responsabilidad de que el contenido del sitio web y aplicación móvil sea apropiado 
                    fuera de la República Mexicana. El acceso al contenido del sitio web y aplicación móvil puede no ser legal para ciertas personas o en ciertos países. Si usted tiene acceso al contenido del sitio web y aplicación móvil fuera de la República 
                    Mexicana, lo hace bajo su propio riesgo y es responsable por el cumplimiento de las leyes dentro de su jurisdicción. El Usuario se compromete a utilizar el Sitio Web y Aplicación Móvil de conformidad con la ley, estos Términos y Condiciones, 
                    así como con la moral y buenas costumbres generalmente aceptadas y el orden público. GBonita excluye cualquier responsabilidad por los daños y perjuicios de toda naturaleza que puedan deberse a los servicios prestados por terceros a 
                    través del Sitio Web y Aplicación Móvil, y en particular, aunque no de modo exclusivo por los daños y perjuicios que puedan deberse al incumplimiento de la ley, la moral y las buenas costumbres generalmente aceptadas o de orden público 
                    como consecuencia de la prestación de servicios por terceros a través del Sitio Web y Aplicación Móvil; la infracción de los derechos de propiedad intelectual e industrial, de los secretos empresariales, de compromisos contractuales 
                    de cualquier clase, de los derechos al honor, a la intimidad personal y familiar y a la imagen de las personas, de los derechos de propiedad y de toda otra naturaleza pertenecientes a un tercero como consecuencia de la prestación de 
                    servicios por terceros a través del sitio web y aplicación móvil; la realización de actos de competencia desleal y publicidad ilícita como consecuencia de la prestación de servicios por terceros a través del sitio web y aplicación móvil, 
                    la falta de veracidad, exactitud, exhaustividad, pertinencia y/o actualidad de los contenidos transmitidos, difundidos, almacenados, recibidos, obtenidos, puestos a disposición o accesibles mediante los servicios prestados por terceros a 
                    través del sitio web y aplicación móvil; el incumplimiento, retraso en el cumplimiento, cumplimiento defectuosos o terminación por cualquier causa de las obligaciones contraídas por terceros y contratos realizados con terceros en 
                    relación o con motivo de la prestación de servicios a través del sitio web y aplicación móvil; los vicios y defectos de toda clase de los servicios prestados a través del sitio web y aplicación móvil. Usted no debe asignar o 
                    transferir sus obligaciones bajo estos términos, estas condiciones constituyen todo el contrato entre usted y GBonita con respecto al uso del sitio web y aplicación móvil. GBonita se reserva el derecho a denegar o retirar el acceso 
                    al Sitio Web y Aplicación Móvil, en cualquier momento y sin necesidad de preaviso, a aquellos usuarios que incumplan estos Términos y Condiciones.</p>
                    

                <h3 className="privacyTitle"><strong>12.2 Reglas de Publicación para Empleadores/Reclutadores</strong></h3>

                    <p className="pt-2 privacyDescr"><strong>A. El material que redacte y publique en el Sitio Web y Aplicación Móvil no debe contener:</strong></p>

                    <ul>
                        <li><p className="privacyDescr">Hipervínculos, hiperenlace, ligas o direcciones URL’s que no estén previamente contratados e incluidos en nuestro “Paquete de Direccionamiento" en tal caso, los enlaces deben ir dirigidos exclusivamente al sitio con el dominio 
                            de la página principal (Home Page) de usted, la empresa contratante, o cualquier variante de ese dominio, tal como son directorios y subdirectorios.</p></li>
                        <li><p className="privacyDescr">No debe publicar simultáneamente el mismo anuncio de empleo.</p></li>
                        <li><p className="privacyDescr">Publicación de diversos perfiles en el mismo anuncio de empleo.</p></li>
                        <li><p className="privacyDescr">No está permitida la publicación de varios anuncios de empleo con un solo crédito</p></li>
                        <li><p className="privacyDescr">Publicidad para ferias o eventos de reclutamiento.</p></li>
                        <li><p className="privacyDescr">Datos de contacto fuera del espacio establecido para tal finalidad, en particular correos electrónicos y números telefónicos (WhatsApp) o cualquier otro medio de contacto.</p></li>
                        <li><p className="privacyDescr">Datos personales sensibles</p></li>
                        <li><p className="privacyDescr">Descripciones discriminatorias o que tengan por intención menoscabar los derechos y libertades de las personas.</p></li>
                        <li><p className="privacyDescr">Invitaciones para acudir a eventos de reclutamiento masivo y/o ferias de empleo</p></li>
                        <li><p className="privacyDescr">Material que requiera derechos de autor (a menos que se tengan los derechos y/o el permiso del autor para su publicación)</p></li>
                        <li><p className="privacyDescr">Información confidencial o secretos corporativos (a menos que se tengan los derechos y/o el permiso del autor para su publicación)</p></li>
                        <li><p className="privacyDescr">Material que infrinja cualquier otro derecho de autor o viole la privacidad o los derechos de publicación de otros</p></li>
                        <li><p className="privacyDescr">Cualquier material que sea sexualmente explícito, obsceno, difamatorio, amenazante, acosador, abusivo, dañino</p></li>
                        <li><p className="privacyDescr">Cualquier cosa que sea embarazosa u ofensiva para otra persona o entidad</p></li>
                        <li><p className="privacyDescr">Solicitud de servicios personales</p></li>
                        <li><p className="privacyDescr">Trabajos que sean pagados solamente por comisión</p></li>
                        <li><p className="privacyDescr">Trabajos que no paguen por lo menos el salario mínimo diario</p></li>
                        <li><p className="privacyDescr">Trabajos que requieran que el candidato, o empleado, haga una inversión de capital o pago</p></li>
                        <li><p className="privacyDescr">Trabajos que requieran que el candidato, o empleado, vaya a un entrenamiento no pagado para ser contratado</p></li>
                        <li><p className="privacyDescr">Ningún otro lenguaje que no sea el Español o el Inglés.</p></li>
                        
                    </ul>

                    <p className="pt-2 privacyDescr"><strong> B. Usted NO deberá usar su (s) Material (es) para:</strong></p>

                    <ul>
                        
                        <li><p className="privacyDescr">Representar a otra persona, viva o muerta</p></li>
                        <li><p className="privacyDescr">Publicar información falsa, imprecisa o engañosa que atraigan a los candidatos con el fin de obtener información personal o de empleo que él o ella no 
                            divulgarían si la intención de dicho anuncio fuera previamente conocida por el candidato.</p></li>
                        <li><p className="privacyDescr">Anuncios Publicitarios o pedidos de negocios (incluyendo, posiciones para empresas de tipo multinivel, franquicias, FOREX, clubes de membresías, 
                            distribución de productos, o cualquier otra que requiera inversión monetaria por parte del usuario)</p></li>
                        <li><p className="privacyDescr">Publicar cadenas de cartas o esquemas piramidales</p></li>
                        <li><p className="privacyDescr">Publicar opiniones o noticias, anuncios comerciales, promoción de cursos y/o de productos</p></li>
                        <li><p className="privacyDescr">Recolectar información masiva de candidatos</p></li>
                        
                    </ul>

                    <p className="pt-2 privacyDescr"> C. Su(s) material(es) deben contener suficientes detalles para transmitir claramente al usuario la naturaleza y requerimientos de la oportunidad de 
                    trabajo, o las calificaciones como un candidato para el empleo (datos biográficos completos, datos propios verídicos).</p>

                    <p className="pt-2 privacyDescr"><strong>D.</strong> Materiales de terceros que requieran una cuota o restrinjan de alguna forma el acceso a la información del currículo están prohibidos.</p>

                    <p className="pt-2 privacyDescr"><strong>E.</strong> GBonita mantendrá un cuidado razonable para asegurar la calidad de su Sitio Web y Aplicación Móvil, sin embargo, no es su obligación monitorear 
                    los materiales publicados. GBonita podrá monitorear los materiales publicados en forma aleatoria.</p>

                    <p className="pt-2 privacyDescr"><strong>F.</strong> Es responsabilidad directa de los empleadores/reclutadores que la redacción de anuncios de empleo publicados a través del Sitio Web y Aplicación 
                    Móvil de GBonita esté apegada a la ley de acuerdo con la jurisdicción del país al que corresponde, dependiendo de la localidad en la cual se encuentra el anuncio de empleo. En particular es 
                    de suma importancia cumplir con las obligaciones antidiscriminatorias que establece la legislación y no restringir el acceso al empleo en virtud de origen étnico o nacional, el color de la piel, 
                    la cultura, el sexo, el género, la edad, las discapacidades, la condición social, la condición económica, las condiciones de salud, la condición jurídica, la religión, la apariencia física, las características genéticas, 
                    la situación migratoria, el embarazo, la lengua, las opiniones, las preferencias sexuales, la identidad o filiación política, el estado civil, la situación familiar, las responsabilidades familiares, el idioma, 
                    los antecedentes penales o cualquier otra que atente contra la dignidad humana y tenga por objeto anular o menoscabar los derechos y libertades de las personas, lo que podría constituir una conducta que tiene el efecto de 
                    anular el ejercicio de sus derechos y la igualdad real de oportunidades con respecto al resto de los y las demás aspirantes a ocupar un empleo.
                    GBonita en todo momento pone a disposición de los candidatos las herramientas necesarias para poder postularse a todos los anuncios de empleo a través del Sitio Web y Aplicación Móvil, sin hacer distinción alguna entre los candidatos</p>

                    <p className="pt-2 privacyDescr"><strong>G.</strong> Los empleadores/reclutadores son quienes de manera directa agregan a los anuncios de empleo a través del Sitio Web y Aplicación Móvil de GBonita, el título, localidad, tipo de empleo y correo 
                    electrónico de cada vacante, motivo por el cual GBonita no realizará cambio alguno en dichos campos derivado de errores involuntarios de los empleadores/reclutadores.</p>

                    <p className="pt-2 privacyDescr"><strong>H.</strong> Al darse de alta en el Sitio Web y Aplicación Móvil de GBonita, los empleadores/reclutadores serán los responsables de registrar correctamente los datos de la cuenta 
                    (incluyendo denominación o razón social, nombre comercial, RFC, etc.), mismos que serán utilizados para la emisión de la factura correspondiente, así como para publicar los datos de la empresa dentro de cada vacante. 
                    Para la realización de cambios en dichos datos, GBonita requerirá la documentación legal correspondiente a efecto de evaluar si dicho cambio procede o no. La publicación de anuncios básicos contendrá la razón social, 
                    mientras que los anuncios destacados podrán, a elección de los empleadores/reclutadores, mostrar el nombre comercial.</p>

                    <p className="pt-2 privacyDescr"><strong>I.</strong> Los anuncios de empleo, identidades y test conductuales adquiridos en el Sitio Web y Aplicación Móvil de GBonita tendrán la vigencia que se señale al momento de su compra, 
                    por lo que deberán ser consumidos durante dicho periodo. Una vez concluida su vigencia no serán reembolsables y/o acumulables.</p>

                    <p className="pt-2 privacyDescr">  Los Materiales que sean encontrados y violen las Reglas de Publicación mencionadas anteriormente serán removidos a criterio exclusivo de GBonita, sin necesidad de notificación previa y 
                    sin posibilidad de reposición y/o devolución de los montos pagados.</p>

                <h3 className="privacyTitle"><strong>12.3 Reglas de Conducta:</strong></h3>

                    <ul>
                        
                        <li><p className="privacyDescr">Usted no puede responder a publicaciones a nombre de otros usuarios de ninguna manera y para ningún otro propósito que el esperado (ejemplo, para aplicar al trabajo o para iniciar una 
                            discusión con referencia al trabajo). Comunicaciones solicitando el negocio del empleador están prohibidas.</p></li>
                        <li><p className="privacyDescr">Usted no puede enviar correos electrónicos comerciales a los usuarios.</p></li>
                        <li><p className="privacyDescr">Reporte publicaciones o conducta inapropiada a: gestion@gbonita.com</p></li>
                        <li><p className="privacyDescr">Usted no puede borrar o revisar ningún material publicado por ninguna otra persona o entidad.</p></li>
                        <li><p className="privacyDescr">Si en cualquier momento durante el término del presente contrato GBonita se entera que usted lo ha engañado en cuanto a su práctica de negocios y/o servicios, y/o ha comprado servicios 
                            que no representan precisamente su negocio, GBonita se reserva el derecho de terminar el presente contrato y su uso de privilegios inmediatamente sin previa notificación y sin derecho a reembolso.</p></li>
                        <li><p className="privacyDescr">GBonita no tiene obligación de monitorear la conducta de sus usuarios, pero se compromete a investigar y responder cuando se reporten violaciones a los términos aquí mencionados.</p></li>
                        
                    </ul>

                <h3 className="privacyTitle"><strong>12.4 Reglas de Seguridad:</strong></h3>

                    <p className="pt-2 privacyDescr"><strong>A.</strong> Los usuarios tienen prohibido violar o atentar contra la seguridad del Sitio Web y Aplicación Móvil, incluyendo, sin limitación:</p>

                    <ul>
                        
                        
                        <li><p className="privacyDescr">Accesar datos que no sean previstos para ese usuario o ingresar a un servidor o cuenta en la cual el usuario no esté autorizado para accesar. Si usted tiene una contraseña que le permita 
                            acceso a un área no pública de este sitio web o su aplicación móvil, no podrá divulgar o compartir su contraseña con terceros o utilizar su contraseña para cualquier fin no autorizado.</p></li>
                        <li><p className="privacyDescr">Atentar, explorar o probar la vulnerabilidad del sistema o de la red o tratar de traspasar la seguridad o las medidas de autenticación sin autorización previa;</p></li>
                        <li><p className="privacyDescr">Atentar e interferir con el servicio de cualquier usuario, servidor o red, (host, network) a través del envío de un virus al Sitio Web o Aplicación Móvil, u otros actos como sobrecarga (overloading, flooding);</p></li>
                        <li><p className="privacyDescr">Enviar e-mails no solicitados, incluyendo promociones y/o publicidad de productos o servicios;</p></li>
                        
                    </ul>

                    <p className="pt-2 privacyDescr"><strong>B.</strong> GBonita no se hace responsable por la información, en particular por los datos personales que los candidatos envíen de manera directa a los empleadores/reclutadores a 
                    través de medios ajenos a la plataforma de GBonita, como correo electrónico, whats app o vía telefónica.</p>

                    <p className="pt-2 privacyDescr"><strong>C.</strong> La violación a estas Reglas de Seguridad puede resultar en responsabilidad administrativa, civil o criminal. GBonita investigará los casos que puedan presentar dichas 
                    violaciones e implicará, y cooperará con las respectivas autoridades en el proceso jurídico de los usuarios que estén implicados en esas violaciones.</p>


                <h3 className="privacyTitle"><strong>13. Opiniones y/o Comentarios:</strong></h3>

                    <p className="pt-2 privacyDescr">Los comentarios y opiniones expresadas en los apartados disponibles son formulados por personas ajenas a GBonita, bajo su única y exclusiva responsabilidad. Todas las personas que accedan a 
                    este sitio web y su aplicación móvil asumen la calidad de usuarios, y por ende se comprometen a la observancia y cumplimiento de estas disposiciones. Los participantes de dichos espacios se comprometen a utilizar 
                    los mismos en conformidad con la ley, estas condiciones generales, así como con la moral y buenas costumbres generalmente aceptadas. GBonita se exime de cualquier tipo de responsabilidad derivada de la información, opiniones, 
                    comentarios, ideas u otros contenidos realizados por los visitantes en su sitio web y aplicación móvil. Las opiniones realizadas por los usuarios en este sitio web y aplicación móvil no podrán contener elementos obscenos y/o insultos, 
                    ni información que supongan una vulneración de derechos de terceros y, en particular, de su honor, intimidad o propia imagen. Tampoco se permitirán comentarios que fueren difamatorios, injuriosos, calumniosos, obscenos, amenazadores, 
                    discriminatorios, o bien que inciten a la violencia. GBonita no es responsable de la veracidad y exactitud de las opiniones expresadas en el sitio web y aplicación móvil por los usuarios, quedando exentos de cualquier responsabilidad 
                    contractual o extracontractual con la persona o empresa que haga uso de ellos. Se prohíbe el envío de cualquier contenido u opinión que vulnere la legislación vigente y/o derechos legítimos de otras personas. Asimismo, 
                    GBonita se reserva el derecho de admitir o dar de baja a cualquier usuario, así como de omitir, suprimir o editar parcial o totalmente, sin previo aviso y sin ningún tipo de responsabilidad con los usuarios, todos aquellos contenidos o 
                    comentarios que considere inadecuados o vayan contra la ley y/o las buenas costumbres. GBonita recomienda a los usuarios no colocar en las publicaciones datos personales como nombre, números de teléfono, direcciones físicas o emails. 
                    Los comentarios realizados por los usuarios en general pasan a formar parte del sitio web y aplicación móvil de GBonita, por lo que los usuarios otorgan a GBonita y a sus afiliadas un derecho no exclusivo sobre los mismos, libre 
                    de regalías, perpetuo e irrevocable para usar, reproducir, modificar, y publicar su contenido por cualquier medio y para cualquier fin, no obstante, dichos comentarios no son ni serán responsabilidad de GBonita, si no de los usuarios 
                    que los realizan. GBonita no estará obligado en ningún caso a evaluar, editar o monitorear el contenido publicado en el sitio web y aplicación móvil o por los usuarios, por lo que no se responsabiliza ni asume responsabilidad alguna 
                    sobre el contenido publicado o cargado por los usuarios o por algún tercero, ni por errores, difamación, calumnias, exposiciones, falsedades o profanidades que pudiesen ser publicadas en el sitio web o su aplicación móvil. 
                    Para la participación de los usuarios en el sitio web y aplicación móvil será requerido el correo electrónico, mismo que será tratado bajo los términos establecidos en el Aviso de Privacidad de GBonita.</p>

                <h3 className="privacyTitle"><strong>14. Derechos de Propiedad Intelectual:</strong></h3>

                    <p className="pt-2 privacyDescr"> El Sitio Web, Aplicación Móvil y todos sus derechos, título e intereses son propiedad única de Gbonita Business Consulting S.A de C.V., y se encuentran protegidos por las leyes mexicanas de derechos de autor y 
                    de los tratados internacionales. GBonita se reserva para el mismo y sus licenciatarios todos los derechos, títulos e intereses, sin límite de propiedad en lo aquí mencionado usted no puede reproducir, modificar, mostrar, vender o 
                    distribuir el contenido, o usarlo en cualquier otra forma para uso público o comercial. Esto incluye copiar o adaptar código HTML usado para generar páginas Web en el Sitio Web y Aplicación Móvil. El logotipo de GBonita y otros nombres y 
                    logotipos son marcas de servicio y marcas registradas de Gbonita Business Consulting S.A de C.V., y todos los productos y nombres de servicio, diseño de marcas y slogans publicitarios son marcas registradas de GBonita, “apariencia" 
                    (look and feel) del Sitio Web y Aplicación Móvil (incluyendo la combinación de colores, forma de los botones, diseño y otros elementos gráficos) están protegidos por el registro de marca de GBonita y sus derechos de autor. Todas las 
                    demás marcas contenidas en el Sitio Web y Aplicación Móvil son marcas registradas de sus respectivos propietarios.</p>

                <h3 className="privacyTitle"><strong>15. Limitación de responsabilidad de GBonita:</strong></h3>

                    <p className="pt-2 privacyDescr">GBonita no asume ninguna responsabilidad por materiales publicados en el Sitio Web y Aplicación Móvil por los usuarios y no tiene responsabilidad por sus actividades, omisiones o conducta de los usuarios. 
                    Asimismo, gBonita no asume ninguna responsabilidad del uso de la herramienta “ATS", ni por materiales publicados en el Sitio Web por los usuarios y no tiene responsabilidad por sus actividades, omisiones o conducta de los usuarios. 
                    Las ofertas de trabajo son publicadas por cada Empleador/Reclutador, por lo que GBonita no asume responsabilidad alguna de los anuncios en el Sitio Web y Aplicación Móvil.</p>

                    <p className="">Por ningún motivo los servicios prestados por GBonita deben entenderse como servicios conocidos como outsourcing, agencia de colocación, manejo de nóminas, ni ningún servicio similar, por lo que GBonita no 
                    puede ser considerado por ningún medio o forma como patrón sustituto en términos de la Ley Federal del Trabajo vigente en los Estados Unidos Mexicanos. Los anuncios de empleo publicados en el sitio web y aplicación móvil, 
                    y las relaciones individuales de trabajo que puedan derivar de ellas, son exclusiva responsabilidad de la persona que lleva a cabo su publicación, motivo por el cual, cualquier persona que publique un anuncio de empleo, 
                    libera desde este momento y en el futuro a GBonita de cualquier responsabilidad derivada de la Ley Federal del Trabajo vigente en los Estados Unidos Mexicanos.
                    Asimismo, GBonita no es responsable por los servicios educativos y de capacitación prestados por terceros con los que GBonita tiene convenios de colaboración, por lo que cualquier reclamación deberá ser realizada de manera directa con 
                    el proveedor del servicio educativo.
                    GBonita no será responsable por las fallas presentadas en la realización de pagos mediante plataformas de terceros, por lo que toda aclaración referente a este tema deberá ser canalizada de manera directa a la empresa encargada de llevar 
                    a cabo dicha transacción.
                    Adicionalmente deberá considerarse que según el medio de pago utilizado para la contratación de servicios, éstos podrán no estar disponibles sino hasta 36 horas hábiles posteriores a la realización del pago correspondiente.</p>

                <h3 className="privacyTitle"><strong>16. Exclusión de Anexos por GBonita:</strong></h3>

                    <p className="pt-2 privacyDescr"> Nada en el Sitio Web y Aplicación Móvil debe ser considerado un anexo, representación o garantías con respecto a cualquier usuario o un tercero, ya sea en relación con el Sitio Web, a sus productos, servicios, 
                    contrataciones, experiencia, empleo, prácticas de reclutamiento u otras.</p>

                <h3 className="privacyTitle"><strong>17. Exclusión de Garantías y de responsabilidad:</strong></h3>

                    <p className="pt-2 privacyDescr"> GBonita no es una agencia de empleos ni una firma de reclutamiento, y no tiene ninguna representación ni garantiza la efectividad o el tiempo en la obtención de empleo para los usuarios. GBonita no garantizan los materiales 
                    publicados en este sitio web y su aplicación móvil por usuarios que resulten candidatos contratados o por puestos por cubrir y no es responsable de ninguna decisión sobre un empleo, por cualquier razón hecho por cualquier usuario.</p>

                <h3 className="privacyTitle"><strong>18. Exclusión de errores y precisión de los materiales publicados en el Sitio Web y Aplicación Móvil:</strong></h3>

                    <p className="pt-2 privacyDescr">  GBonita no garantiza la veracidad, exactitud, vigencia o confiabilidad de ninguno de los materiales publicados por los usuarios, o por cualquier otra forma de comunicación que sea comprometida por los usuarios. 
                    Los materiales pueden contener inexactitudes o errores tipográficos. Usted acepta que cualquier consecuencia en materiales publicados por los usuarios, o en cualquier otra forma de comunicación con los usuarios, será a su propio riesgo. 
                    Adicionalmente GBonita no garantiza el contenido del Sitio Web y Aplicación Móvil, incluyendo responsabilidad limitada hacia ligas que no funcionen, inexactitudes o errores tipográficos. </p>

                <h3 className="privacyTitle"><strong>19. EXCLUSIÓN DE GARANTÍAS:</strong></h3>

                    <p className="pt-2 privacyDescr">EL SITIO WEB, LA APLICACIÓN MÓVIL Y TODO SU CONTENIDO ES PROVEÍDO “COMO- ES" SIN GARANTÍAS DE NINGUNA CLASE, EXPRESAS O IMPLÍCITAS. LA EMPRESA Y SUS AGENTES ASOCIADOS (HS GROUP), HASTA DONDE LO PERMITA LA LEY, 
                    LIMITA LA RESPONSABILIDAD DE TODAS LAS GARANTÍAS, INCLUYENDO PERO NO LIMITANDO LA GARANTÍA DE COMERCIALIZACIÓN, IDONEIDAD PARA UN PROPÓSITO ESPECÍFICO Y NO INFRACCIÓN. LA EMPRESA Y SUS AGENTES ASOCIADOS NO OFRECEN GARANTÍAS ACERCA DE 
                    LA PRECISIÓN, CONFIABILIDAD, INTEGRIDAD U OPORTUNIDAD DEL CONTENIDO DEL SITIO DE INTERNET, SERVICIOS, SOFTWARE, TEXTO, GRÁFICAS Y VÍNCULOS. GBONITA Y SUS AGENTES ASOCIADOS NO GARANTIZA QUE EL SITIO VA A OPERAR LIBRE DE ERRORES O QUE 
                    EL SITIO, LA APP Y SUS SERVIDORES ESTÁN LIBRE DE VIRUS INFORMÁTICOS U OTROS MECANISMOS DAÑINOS. SI SU USO DEL SITIO WEB Y SU APLICACIÓN MÓVIL RESULTA DIRECTA O INDIRECTAMENTE EN LA NECESIDAD DE REMPLAZAR EQUIPOS O DATOS O REALIZAR 
                    SERVICIOS, GBONITA Y SUS AGENTES ASOCIADOS NO SON RESPONSABLES POR LOS COSTOS ASOCIADOS CON DICHOS REEMPLAZOS Y/O SERVICIOS. </p>

                <h3 className="privacyTitle"><strong>20. Ligas a otros sitios:</strong></h3>

                    <p className="pt-2 privacyDescr"> GBonita y sus agentes asociados contienen ligas o hipervínculos a Sitios de terceros. Estás ligas son proporcionadas para conveniencia de Usted y los contenidos no son avalados por GBonita. GBonita no es responsable 
                    por el contenido de esos sitios ni por la exactitud de sus materiales. Si usted decide accesar a esos sitios es bajo su responsabilidad.</p>

                <h3 className="privacyTitle"><strong>21. Widget:</strong></h3>

                    <p className="pt-2 privacyDescr">La interfaz de usuario de GBonita podrá estar disponible de forma no exclusiva para los Empleadores/Reclutadores a través del Widget de GBonita (previa contratación), el cual se le pedirá sea añadido a su sitio web. 
                    La inserción del Widget de GBonita en su sitio web implica la aceptación de estos Términos y Condiciones de uso. El código de instalación del Widget de GBonita le será proporcionado con la única finalidad de acceder e instalar el 
                    Widget de GBonita en su sitio web, por lo que deberá abstenerse de utilizarlo para cualquier otro propósito.
                    El Widget y su código de instalación es propiedad exclusiva de GBonita, por lo que Usted no podrá copiar, alterar, modificar, mejorar, desmontar, traducir, descompilar o aplicar ingeniería inversa al Widget o código de instalación 
                    provistos por GBonita. Asimismo, no podrá duplicar, vender, rentar o prestar el Widget de GBonita o su código de instalación a ningún otro tercero.
                    Los anuncios de empleo publicados por los Empleadores/Reclutadores en el sitio de GBonita se podrán ver reflejados en sitios de terceros con los que GBonita tiene suscrito contrato para la prestación del servicio de Widget.</p>

                <h3 className="privacyTitle"><strong>22. Notificaciones:</strong></h3>

                    <p className="pt-2 privacyDescr"> El uso de la plataforma GBonita implica la aceptación a la recepción de notificaciones para los usuarios mediante diversos medios, tales como notificaciones de escritorio, vía WhatsApp o SMS, a través de correo electrónico, 
                    o cualquier otro medio con el cual sea posible contactar al usuario con la información proporcionada en el Sitio Web.</p>

                <h3 className="privacyTitle"><strong>23. Enmiendas a este acuerdo y Cambios al Sitio Web:</strong></h3>

                    <p className="pt-2 privacyDescr"> GBonita podrá revisar estos términos en cualquier momento y actualizar su contenido. Cualquier uso del Sitio Web y Aplicación Móvil se considerará como aceptación de usted de los términos aquí mostrados. 
                    Si en cualquier momento usted encuentra los términos inaceptables, usted no deberá usar este Sitio Web y Aplicación Móvil. Cualquier término nuevo o diferente proporcionado por usted será específicamente rechazado por GBonita. 
                    GBonita podrá realizar cambios a estos términos sin previo aviso.
                    GBonita se reserva el derecho de modificar o descontinuar, temporal o permanentemente, los Servicios o cualquier otra característica que forme parte de estos, así como su modelo de negocio sin previo aviso. Usted acepta que GBonita 
                    no será responsable por ninguna modificación, suspensión, cambio de modelo o interrupción de los Servicios o cualquier parte de los mismos. En caso de cambio de modelo de los Servicios GBonita otorgará al usuario el equivalente de 
                    servicios en el nuevo modelo.</p>

                <h3 className="privacyTitle"><strong>24. Indemnización de GBonita:</strong></h3>

                    <p className="pt-2 privacyDescr"> Usted acepta defender, indemnizar y declarar exenta de responsabilidad a la Empresa y sus agentes asociados, sus funcionarios, directores, empleados y agentes, frente y contra cualquier reclamación, acción judicial 
                    o demanda, incluido sin limitaciones los daños, costos legales y contables derivados o resultantes de cualquier alegato resultado o en conexión con su uso del Sitio Web y Aplicación Móvil, de cualquier material publicado por usted o por 
                    cualquier incumplimiento a éstos términos. GBonita le informará de la existencia de tales reclamaciones, demandas o procedimientos judiciales y le asistirá, a su costo, en la defensa de tales reclamaciones, demandas o 
                    procedimientos judiciales.</p>

                <h3 className="privacyTitle"><strong>25. Terminación:</strong></h3>

                    <p className="pt-2 privacyDescr"> GBonita y sus agentes asociados se reservan el derecho, a su entera discreción, a perseguir todos los remedios legales, incluyendo, sin limitación, la eliminación de sus publicaciones en este Sitio Web, la 
                    terminación inmediata de claves de acceso a este Sitio Web y/o cualesquier otros servicios que le brinde la empresa y sus agentes asociados, debido a cualquier incumplimiento suyo a estos términos y condiciones de uso o si la 
                    empresa es incapaz de verificar o autentificar cualquier información que usted presente al sitio de Internet u otros registros; En caso de cancelación del servicio por estas causas GBonita ni sus agentes asociados harán ningún 
                    reembolso de cantidades pagadas por la terminación del servicio.</p>

                <h3 className="privacyTitle"><strong>26. Información del usuario:</strong></h3>

                    <p className="pt-2 privacyDescr">El Aviso de Privacidad de GBonita incorpora este acuerdo.</p>

                <h3 className="privacyTitle"><strong>27. Contacto y preguntas:</strong></h3>

                    <p className="pt-2 privacyDescr">Preguntas acerca del uso de este Sitio Web y Aplicación Móvil deberán ser dirigidas al correo electrónico gestion@gbonita.com</p>

                <br />
                <br />
                <br />


            </div>
        </div>
    )
    
}

export default Terms;
