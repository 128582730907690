import {types} from '../types/types';

const initialState = {
    searchOnBoarding:{}
};

export const searchOnBoardingReducer = (state = initialState, action) =>{

    switch(action.type){

        case types.setSearchOnBoarding:
            return {
                ...state,
                searchOnBoarding: action.payload.searchOnBoarding
            }

        case types.logout:
            return initialState;

        default:
            return state;
    }
}