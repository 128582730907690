import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

//Actions
import { showSideBar } from '../redux/actions/uiAction';

const Privacy = () => {
    
    const dispatch = useDispatch();

    useEffect(() => {

        dispatch(showSideBar(false));

    }, [dispatch]);

    return (

        <div className="wrapper">

            <div className="container">
                <br/>
                <br/>
                <h1>Aviso de privacidad de Gbonita Business Consulting, S.A. de C.V.</h1>
                <br/>

                <p className="pt-2 privacyDescr">Gbonita Business Consulting, S.A de C.V. (conocido como "Gbonita") reconoce que nos estás proporcionando tus datos de contacto, así como tus datos laborales y académicos que aparecen en tu CV. 
                Nuestro compromiso es tratar estos datos para los fines de promoción laboral frente a terceros propios de este sitio, así como para ofrecerte promociones comerciales de bienes o servicios propios o de terceros que consideramos 
                que pueden ser de tu interés. Antes de que nos proporciones cualquier información, deberás de leer y comprender los términos de este Aviso de Privacidad, manifestando expresamente su consentimiento al Aceptar los mismos. 
                Salvo para cumplir con las finalidades anteriores, no transferiremos sus datos a ningún tercero.</p>

                <h3 className="privacyTitle"><strong>I. DE LA IDENTIDAD Y DOMICILIO DEL RESPONSABLE.</strong></h3>

                    <p className="pt-2 privacyDescr">Este documento es el Aviso de Privacidad de Gbonita Business Consulting S.A. de C.V. (el "Responsable"), quien será el encargado de tratar los datos personales que usted (el "Titular") nos proporcione. 
                    Para los efectos señalados en el presente Aviso de Privacidad, el Responsable señala como su domicilio el ubicado en Felix Parra 201, Colonia San José Insurgentes,Ciudad de México.</p>

                <h3 className="privacyTitle"><strong>II. DE LOS DATOS PERSONALES QUE SERÁN SOMETIDOS A TRATAMIENTO.</strong></h3>

                    <p className="pt-2 privacyDescr">Los datos personales que el Titular proporcione a través del llenado y remisión electrónica de formularios contenidos en el sitio web y que serán sometidos a tratamiento son: (i) nombre(s), apellido 
                    paterno y apellido materno; (ii) domicilio; (iii) correo electrónico; (iv) número de teléfono, celular y/o fax; (v) fotografía; (vi) información sobre grados académicos (vii) lo relativo a historial académico y laboral (empleos anteriores); 
                    (viii) y datos relativos a interés en puestos de trabajo; (ix) aptitudes y capacidades; y (x) pretensiones laborales (incluyendo sueldo) (los "Datos").</p>

                    <p className="pt-2 privacyDescr">Los datos personales del Titular serán almacenados por el Responsable para la prestación de los servicios durante el tiempo que el Titular esté registrado en el Sitio, a menos que el Titular ejerza el 
                    derecho de Cancelación de estos mediante el procedimiento establecido en el número VII de este Aviso.</p>

                <h3 className="privacyTitle"><strong>III. DE LOS DATOS PERSONALES SENSIBLES.</strong></h3>

                    <p className="pt-2 privacyDescr">El Responsable no solicitará en forma alguna, y el Titular no proporcionará en ningún momento al Responsable, "datos personales sensibles", es decir, aquellos datos personales íntimos o cuya utilización 
                    indebida pueda dar origen a discriminación o conlleve un riesgo grave para el Titular. El Titular se obliga a no proporcionar al Responsable ninguna información relativa a su origen racial o étnico, estado de salud presente y/o futuro, 
                    información genética, creencias religiosas, filosóficas y/o morales, afiliación sindical, opiniones políticas y/o preferencia sexual. En caso de que el Titular proporcione datos sensibles a través de documentos subidos al sitio web, o 
                    por medio de los formularios, acepta y consiente que los mismos sean tratados conforme a los términos y condiciones del sitio web y a su Aviso de Privacidad, siendo obligación del Titular no proporcionar este tipo de datos en la información 
                    que sea subida al sitio web.</p>

                <h3 className="privacyTitle"><strong>IV. DE LOS DATOS FINANCIEROS.</strong></h3>

                    <p className="pt-2 privacyDescr">El Responsable recaba datos financieros que resultan necesarios para efectos de pago de nuestros servicios con cargo a tarjeta de crédito. Entre estos datos se encuentran: (i) Tipo de tarjeta; (ii) Número de tarjeta; 
                    (iii) Código de verificación de tarjeta; (iv) Información de contacto; y (v) Datos de facturación.</p>

                <h3 className="privacyTitle"><strong>V. DEL USO A SUS DATOS.</strong></h3>

                    <p className="pt-2 privacyDescr">Sus Datos serán tratados para las siguientes finalidades:</p>

                    <p className="pt-2 privacyDescr">a. Finalidad Primaria: Prestación de servicios a través de su sitio de internet para proporcionar una plataforma para la publicación de ofertas de trabajo y búsqueda de empleo por terceros, incluyendo: 
                    (i) Promoción de oferta laboral frente a terceros; (ii) Procesos de contratación laboral o de prestación de servicios independientes frente a terceros; (iii) Entrega de referencias en caso de que otra persona o empresa solicite 
                    informes sobre posibles candidatos a un puesto, trabajo, cargo o comisión; (iv) Para publicarlos para posibles reclutadores.</p>

                    <p className="pt-2 privacyDescr">b. Finalidad Secundaria: Mejora del proceso comercial y de mercadotecnia así como participación en asistencia social, utilizando la información para: (i) La compra de bienes o servicios; (ii) 
                    Procurar un servicio eficiente y proveer una mejor atención al Titular, así como para mejorar su experiencia en el uso de ciertos productos y/o servicios; (iii) Enviarle y presentarle bienes y/o servicios que puedan resultar 
                    relevantes o atractivos, incluyendo su participación en promociones, encuestas, ofertas y campañas de publicidad, propias o de terceros; (iv) Informar sobre cambios o nuevos productos o servicios que consideramos puedan ser 
                    de su interés; (v) Dar cumplimiento a obligaciones legales; (vi) Evaluar la calidad del servicio o realizar estudios internos sobre hábitos de consumo; (vii) Compartir sus datos con instituciones públicas o privadas, en las 
                    cuales sea del interés del Titular ayudar como voluntario en casos de desastre o emergencia a nivel local o nacional.</p>

                <h3 className="privacyTitle"><strong>VI. DEL TRATAMIENTO DE LOS DATOS Y SU NEGATIVA.</strong></h3>

                    <p className="pt-2 privacyDescr">Para poder cumplir con las finalidades de este Aviso de Privacidad, así como para poder tratar los Datos, es posible que el Responsable entregue todo o parte de los Datos a terceras personas, 
                    las que podrán ser físicas o morales, nacionales o extranjeras, empresas filiales o subsidiarias, incluyendo proveedores de bienes o servicios, que requieran conocer esta información, incluyendo servidores de almacenamiento de 
                    información, quienes quedarán obligados, por contrato, a mantener la confidencialidad de los Datos y conforme a este Aviso de Privacidad. Los terceros receptores, podrán ser, de forma enunciativa, más no limitativa, empresas 
                    industriales, comerciales y/o de servicios.</p>

                    <p className="pt-2 privacyDescr">Durante la prestación de los servicios, GBONITA puede hacer uso de una serie de procesos automatizados de tratamiento de sus datos a fin de brindarle servicios más personalizados, 
                    como realizar recomendaciones de ofertas laborales o educativas de acuerdo con su perfil</p>

                    <p className="pt-2 privacyDescr">El Responsable se compromete a contar con las medidas físicas, administrativas y técnicas (incluyendo técnicas como data masking) de seguridad suficientes y necesarias para 
                    garantizar que sus Datos permanezcan seguros.</p>

                    <p className="pt-2 privacyDescr">El Responsable estará facultado para dar el tratamiento que crea adecuado a los Datos, siempre y cuando este tratamiento se haga con relación a las finalidades señaladas en el inciso (a) 
                    de la Sección V del presente Aviso de Privacidad; para el tratamiento de las finalidades señaladas en el inciso (b) de la citada Sección, el Responsable podrá realizar dicho tratamiento hasta que no reciba la negativa 
                    por parte del Titular.</p>

                    <p className="pt-2 privacyDescr">El Titular no deberá añadir datos personales no requeridos o diferentes a aquellos solicitados por el Responsable en los campos designados para ello, ya que cualquier conducta contraria 
                    no garantiza la confidencialidad de dicha información.</p>
        
                    <p className="pt-2 privacyDescr">A efecto de que el Titular pueda manifestar su negativa o revocar el consentimiento para el tratamiento de sus datos personales, deberá dirigirse al Responsable por medio del procedimiento 
                    establecido para el ejercicio de los Derechos ARCO (cuyo término se define más adelante), señalado en la Sección VII del presente Aviso de Privacidad.</p>


                <h3 className="privacyTitle"><strong>VII. DEL EJERCICIO DE LOS DERECHOS ARCO.</strong></h3>

                    <p className="pt-2 privacyDescr">El Titular tendrá derecho para solicitar al Responsable en cualquier momento el acceso, rectificación, cancelación u oposición respecto de sus Datos (los "Derechos ARCO"), para lo cual 
                    deberá enviar una solicitud al Responsable, a la atención del área de Operaciones y Seguridad Informática, a la siguiente dirección: Felix Parrra 201, Colonia San José Insurgentes, C.P. 54020, Benito Juárez, Ciudad de México, 
                    o al correo electrónico: gestion@gbonita.com</p>

                    <p className="pt-2 privacyDescr">La solicitud para ejercer los Derechos ARCO, o para revocar el consentimiento al tratamiento de los datos personales, deberá contener y estar acompañada de lo siguiente: (i) el nombre, apellido 
                    paterno y materno del Titular; (ii) su domicilio y/o correo electrónico y/o cualquier otro medio para comunicarle la respuesta a su solicitud; (iii) los documentos oficiales que acrediten la identidad o, en su caso, 
                    la representación legal del Titular; (iv) la descripción clara y precisa de los Datos respecto de los que se busca ejercer alguno de los derechos antes mencionados; y (v) cualquier otro elemento o documento que facilite 
                    la localización de los Datos del Titular.</p>

                    <p className="pt-2 privacyDescr">Una vez que el Responsable reciba el escrito del Titular referente al ejercicio de sus Derechos ARCO, el Responsable dentro de los 15 días hábiles tendrá que analizar, atender y enviar 
                    al Titular la respuesta correspondiente. El medio del envío de la respuesta podrá ser por la forma que indique el Titular o utilizando el mismo medio por el cual el Titular le hizo llegar el correspondiente escrito al Responsable.</p>



                <h3 className="privacyTitle"><strong>VIII. DEL MAILING.</strong></h3>

                    <p className="pt-2 privacyDescr">Para suprimir la recepción de mailing publicitario, el Titular podrá realizarlo seleccionando la opción desuscribir o unsubscribe que se encuentra al final de la comunicación recibida, o bien, 
                    mediante el correo electrónico gestion@gbonita.com con el asunto “desuscribir publicidad”.</p>


                <h3 className="privacyTitle"><strong>IX. DE OTROS MEDIOS PARA LIMITAR EL USO O DIVULGACIÓN DE LOS DATOS.</strong></h3>

                    <p className="pt-2 privacyDescr">Para limitar el tratamiento de los Datos, el Titular únicamente tendrá los medios señalados en el presente Aviso de Privacidad. Quedará a elección del Titular, acudir o solicitar vía telefónica 
                    su inscripción al Registro Público de Consumidores previsto por la Ley Federal de Protección al Consumidor, a efecto de limitar el uso de los datos personales en posesión de los particulares.</p>


                <h3 className="privacyTitle"><strong>X. DE LOS MECANISMOS DE COMUNICACIÓN.</strong></h3>

                    <p className="pt-2 privacyDescr">Cualquier comunicación que el Titular dirija al Responsable relacionada al presente Aviso de Privacidad, deberá realizarse a las direcciones proporcionadas para el ejercicio de los Derechos ARCO, 
                    mencionando de manera clara y precisa: (i) el nombre, apellido paterno y materno del Titular; (ii) su domicilio y/o correo electrónico y/o cualquier otro medio para comunicarle la respuesta a su solicitud; (iii) los documentos oficiales 
                    que acrediten la identidad o, en su caso, la representación legal del Titular; y (iv) la descripción clara y precisa del objeto de su comunicación.</p>

                    <p className="pt-2 privacyDescr">De la misma manera, el Responsable atenderá y responderá cualquier comunicación que le sea dirigida dentro de los 7 días hábiles al haber recibido dicho escrito, ya sea en la en la misma forma en el que le 
                    hicieron llegar la comunicación o en la forma en que hubiesen indicado.</p>




                <h3 className="privacyTitle"><strong>XI. DE LOS MEDIOS REMOTOS.</strong></h3>

                    <p className="pt-2 privacyDescr">En caso de que el Titular proporcione sus Datos a través de medios electrónicos, incluyendo el sitio web (Internet) del Responsable, entonces el Titular entiende, acepta y reconoce que:</p>

                    <p className="pt-2 privacyDescr">(a) El sitio web del Responsable puede incluir enlaces a sitios web de terceros, que de accederse, ocasionará que se abandone el sitio web del Responsable, por lo cual el Responsable no asume ninguna 
                    responsabilidad en relación con esos sitios web de terceros. (b) El sitio web del Responsable puede incluir enlaces a sitios que administran redes sociales, en cuyo caso el Titular acepta que al proporcionar cualquier tipo de información 
                    o Datos en dichos sitios, ocasionará que la misma pueda ser leída, vista, accedida, retransmitida y tratada por cualquier persona, y por lo tanto libera de cualquier responsabilidad al Responsable. (c) Es posible que los sistemas del 
                    Responsable recopilen Datos del Titular tales como tipo de navegador, sistema operativo, páginas de Internet visitadas, dirección IP, etc., a través "cookies" o "web beacons", entre otros.</p>

                    <p className="pt-2 privacyDescr">Para que el Titular pueda deshabilitar estos programas, deberá de acceder a las "opciones de internet" ubicadas en la sección de las "herramientas", o funciones similares, del navegador que utilice.</p>



                <h3 className="privacyTitle"><strong>XII. DE LAS MODIFICACIONES AL AVISO DE PRIVACIDAD.</strong></h3>

                    <p className="pt-2 privacyDescr">El Responsable podrá modificar el presente Aviso de Privacidad tantas veces como considere necesario, tanto para guardar congruencia con los ordenamientos legales aplicables, a petición de las autoridades 
                    competentes y/o a nuestra propia conveniencia. En tanto el Responsable no modifique su identidad, se requieran del Titular más datos de los aquí mencionados, se modifique el fin del presente Aviso de Privacidad y/o cambien las condiciones 
                    de transferencia, el Titular está de acuerdo y conforme en que la forma de notificar cualquier cambio a este Aviso de Privacidad, será a través de la publicación en la página de Internet https://www.gbonita.com/privacy. Es obligación del 
                    Titular visitar periódicamente dicho sitio a fin de verificar la versión más actual del Aviso de Privacidad. Para los casos en que el consentimiento del Titular sea necesario por los cambios realizados al presente Aviso de Privacidad, 
                    el Responsable le hará llegar el aviso correspondiente a cualquiera de los medios de contacto que el Titular nos haya brindado; se entenderá que el Titular acepta dicho Aviso hasta que no recibamos cualquier comunicación en sentido contrario.</p>

                <h3 className="privacyTitle"><strong>XIII. DE LA ACEPTACIÓN DE LOS TÉRMINOS.</strong></h3>

                    <p className="pt-2 privacyDescr">Esta declaración de Privacidad está sujeta a los términos y condiciones de todos los sitios web de GBONITA antes descritos, lo cual constituye un acuerdo legal entre el usuario y GBONITA.</p>

                    <p className="pt-2 privacyDescr">Si el usuario utiliza los servicios en cualquiera de los sitios de GBONITA, significa que ha leído, entendido y aceptado los términos antes expuestos. Al momento de registrarse y previo al 
                    tratamiento de los datos, se pone a su disposición este Aviso de Privacidad integral, mismo que usted acepta al dar click en el botón “Registrarse” o a través del mecanismo que se ponga a su disposición. La oposición a la 
                    aceptación del presente Aviso de Privacidad traerá como consecuencia la imposibilidad para prestar el servicio dentro de la plataforma para oferentes y demandantes de empleo. En caso de no aceptar el presente Aviso de Privacidad,
                    no debe proporcionarnos datos personales, ni suscribirse a los sitios de GBONITA.</p>

                <h3 className="privacyTitle"><strong>XIV. DE LA PROTECCIÓN.</strong></h3>

                    <p className="pt-2 privacyDescr">La seguridad y la confidencialidad de los datos que los usuarios proporcionen al contratar un servicio o comprar un producto en línea estarán protegidos por un servidor seguro bajo el protocolo 
                    Secure Socket Layer (SSL), de tal forma que los datos enviados se transmitirán encriptados para asegurar su resguardo.</p>

                    <p className="pt-2 privacyDescr">Para verificar que se encuentra en un entorno protegido asegúrese de que aparezca una S en la barra de navegación. Ejemplo: httpS://. Sin embargo, y a pesar de contar cada día con herramientas más seguras, 
                    la protección de los datos enviados a través de Internet no se puede garantizar al 100%; por lo que una vez recibidos, se hará todo lo posible por salvaguardar la información.</p>

                <h3 className="privacyTitle"><strong>XV. AUTORIDAD.</strong></h3>

                    <p className="pt-2 privacyDescr">Si considera que han sido vulnerados sus derechos respecto de la protección de datos personales, tiene el derecho de acudir ante el Instituto Nacional de Transparencia, Acceso a la Información y Protección de 
                    Datos Personales (INAI) en www.ifai.mx</p>

                <h3 className="privacyTitle"><strong>XVI. DE LOS CIUDADANOS DE LA UNIÓN EUROPEA.</strong></h3>

                    <p className="pt-2 privacyDescr">Si el Titular de los datos es residente de la Unión Europea además de las consideraciones anteriores, aplicará lo referente al Reglamento General de Protección de Datos (GDPR por sus siglas en inglés).</p>
                    <p className="pt-2 privacyDescr">Al utilizar esta plataforma, usted está otorgando su consentimiento a GBONITA para procesar sus datos personales bajo los términos de este Aviso de Privacidad.</p>
                    <p className="pt-2 privacyDescr">Si considera que han sido vulnerados los derechos respecto a la protección de sus datos personales, en todo momento tiene derecho a acudir ante las Autoridades de la Unión Europea en Materia de Protección 
                    de Datos Personales.</p>

                <br />
                <br />

                <p className="pt-2 privacyDescr">La presente versión de este Aviso de Privacidad fue actualizada por última vez el 7 de julio de 2020</p>

                <br />
                <br />
                <br />

            </div>

        </div>


         
    )
    
}

export default Privacy;
