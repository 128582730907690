import React, { useEffect } from 'react';

//Redux
import { useDispatch } from  'react-redux';

//Components
import QuienesSomosImg1 from '../components/QuienesSomos/QuienesSomosImg1';
import QuienesSomosImg2 from '../components/QuienesSomos/QuienesSomosImg2';
import QuienesSomosImg3 from '../components/QuienesSomos/QuienesSomosImg3';
import QuienesSomosImg4 from '../components/QuienesSomos/QuienesSomosImg4';
import QuienesSomosImg5 from '../components/QuienesSomos/QuienesSomosImg5';
import Footer from '../components/Footer';

//Actions
import { showSideBar } from '../redux/actions/uiAction';

const QuienesSomos = () => {

    const dispatch = useDispatch();

    useEffect(() => {

        dispatch(showSideBar(false));

    }, [dispatch]);

    return (

        <div className="wrapper">
            <div className="mainContainerQuienesSomos">              
            
                <QuienesSomosImg1 />

                <QuienesSomosImg2 />

                <QuienesSomosImg3 />

                <QuienesSomosImg4 />

                <QuienesSomosImg5 />

                <Footer />
               
            </div>
        </div>
       
    )
    
}

export default QuienesSomos;
