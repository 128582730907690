import { validFormatPassword } from './PasswordModel';
import { FieldError } from './FieldError';

export class RegisterModel {

    constructor(stringsAuth) {
        this.errorStatus = false;
        this.nameError = new FieldError();
        this.lastnameError = new FieldError();
        this.companyError = new FieldError();
        this.emailError = new FieldError();
        this.jobError  = new FieldError();
        this.passwordError = new FieldError();
        this.confirmPasswordError = new FieldError();
        this.countryError = new FieldError();
        this.stringsAuth = stringsAuth;
    }

    validate(stateForm){
        this.errorStatus = false;

        if(stateForm.name.length === 0){
            this.nameError.setValues(true, this.stringsAuth.FieldNameError);
            this.errorStatus = true;
        }

        if(stateForm.lastname.length === 0){
            this.lastnameError.setValues(true, this.stringsAuth.FieldLastNameError);
            this.errorStatus = true;
        }

        if(stateForm.company.length === 0){
            this.companyError.setValues(true, this.stringsAuth.FieldCompanyError);
            this.errorStatus = true;
        }

        if(stateForm.email.length === 0){
            this.emailError.setValues(true, this.stringsAuth.FieldEmailError);
            this.errorStatus = true;
        }

        if(stateForm.job.length === 0){
            this.jobError.setValues(true, this.stringsAuth.FieldJobError);
            this.errorStatus = true;
        }

        let validatePassword = validFormatPassword(stateForm.password);
        if(!validatePassword.status) {
            this.passwordError.setValues(true, validatePassword.error);
            this.errorStatus = true;
        }

        validatePassword = validFormatPassword(stateForm.confirmPassword);
        if(!validatePassword.status) {
            this.confirmPasswordError.setValues(true, validatePassword.error);
            this.errorStatus = true;
        }
                
        if(stateForm.password !== stateForm.confirmPassword){
            this.confirmPasswordError.setValues(true, this.stringsAuth.FieldPasswordNotMatchError);
            this.errorStatus = true;
        }

        if(stateForm.country === stateForm.defaultPais || stateForm.country.length === 0){
            this.countryError.setValues(true, this.stringsAuth.FieldCountryError);
            this.errorStatus = true;
        }

    }

    toObject(){
        return  {
            name: this.nameError.toObject(),
            lastname: this.lastnameError.toObject(),
            company: this.companyError.toObject(),
            email: this.emailError.toObject(),
            job: this.jobError.toObject(),
            password: this.passwordError.toObject(),
            confirmPassword: this.confirmPasswordError.toObject(),
            country: this.countryError.toObject(),
            serv:{
                status: false,
                msg: ''
            }
        }
    }
}
