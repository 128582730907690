import { types } from '../types/types';


export const setUp = (stateLogIn, errorState) => {
    
    return ( dispatch ) => {

        console.log("errorState", errorState);

        dispatch(setStateLogIn(stateLogIn));

        dispatch(setErrorAuth(errorState));

    }
}

export const setStateLogIn = ( stateLogIn ) => ({
    type: types.setStateLogIn,
    payload:{
        stateLogIn
    }     
});


export const setErrorAuth = ( errorState) => ({
    
    type: types.errorAuthLogIn,
    payload:{
        ...errorState
    }     
 
});


export const resetStateLogIn = () => ({
    
    type: types.resetStateLogIn,
    payload:{
        
    }     
 
});
 


export const CerrarStateLogInAction = () => 
{
    return (dispatch) => {

        dispatch({
            type: types.logout,
            payload: {
                
            }
        });
           
    }
}

/*
export const setStateLogIn = ( stateLogIn ) => ({
    type: types.setStateLogIn,
    payload:{
        stateLogIn
    }     
});*/


//resetStateLogIn