import React, { Fragment, useState } from 'react';

//Redux
import { useSelector } from 'react-redux';

//Data
import { searchFilterData } from '../../../data/info';
import Select from 'react-select';


 const SearchBar = (props) => {

    const [stateSearch, setStateSearch] = useState({
        searchFilter: "Job",
        searchText: ""
    });
    
    const { searchBar } = useSelector(state => state.uiReducer);
    

    const handleInputChangeSelect = (optionSelected) => {
        
        //console.log("handleInputChange", target);
        console.log("handleInputChange", optionSelected.value);

        setStateSearch({
            ...stateSearch,
            searchFilter: optionSelected.value
        });
    }

    const handleInputChange = ({target}) => {
        
        //console.log("handleInputChange", target);
        
        setStateSearch({
            ...stateSearch,
            searchText: target.value
        });
    }

    const handleKeyDown = (e) => {

        if (e.key === 'Enter') {
            e.preventDefault();

            props.handleGetBusquedaNormal(stateSearch);
        }

    }

    return(
        <Fragment>
            {
                searchBar &&
                <div className="containerSearch">
                    <form className="form-inline customSelect">

                        <Select 
                        options={searchFilterData['MX'].data} 
                        defaultValue={searchFilterData['MX'].data[0]} 
                        onChange={ handleInputChangeSelect }
                        className="search"/>
                        

                        <input 
                        className="form-control inputFieldSearchFilter" 
                        type="search" 
                        placeholder="Search" 
                        aria-label="Search"
                        onChange={handleInputChange}
                        onKeyDown={handleKeyDown}/>

                        
                        <button className="btn btn-primary my-2 my-sm-0 btnSearch" type="button" onClick={() => props.handleGetBusquedaNormal(stateSearch)}>Buscar</button> 

                    </form> 
                </div>
            }
        </Fragment>
      
    )   
}

export default SearchBar;
