import React from 'react';

//Images
import ic_profile from '../../assets/img/ic_profile.png';

 const CandidatoRecienteInfoSkeleton = () => {
    
    return (

        <div className="mainContainerCandidatoRecieteE text-center d-flex align-self-center">

            <div className="CandidatoRecienteInfo">

                <div className="p-0 align-self-center pl-2 pr-2 pb-3">
                    <img className="imgCandidatoRecienteInfoE" src={ ic_profile } alt="Foto Candidato"></img>
                </div>

                <div className="p-0 align-self-center">

                    <div className="contentCandidatoRecienteInfoNameE d-flex justify-content-center">
                        <p className="p-0 my-auto"></p>
                    </div>
                    
                    <div className="contentCandidatoRecienteInfoJobE">{/*d-flex justify-content-center */}
                        <p className="m-0 mb-2"></p>
                        <p className="p-0 my-auto"></p>
                    </div>

                
                </div> 

            </div>
        
        </div>

    )
    
}

export default CandidatoRecienteInfoSkeleton;