import { types } from '../types/types';


export const setUp = (stateRegister, errorState) => {
    
    return ( dispatch ) => {
        
        dispatch(setStateRegister(stateRegister));

        dispatch(setErrorAuthRegister(errorState));
    }
}

export const setStateRegister = ( stateRegister ) => ({
    type: types.setStateRegister,
    payload:{
        stateRegister
    }     
});


export const setErrorAuthRegister = (errorState) => ({
    type: types.errorAuthRegister,
    payload:{
        ...errorState
    }     
});


export const resetStateRegister = () => ({
    
    type: types.resetStateRegister,
    payload:{
        
    }     
 
});
 