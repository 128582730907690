import React from 'react';

//Route
import { Route, Redirect } from 'react-router-dom';

const PublicRoute = ({
    isAuthenticated,
    component: Component,
    ...rest
}) => {

    return (
        <Route { ...rest }
            component={ (props) => (
                ( isAuthenticated )
                    ? ( <Redirect to="/menu" /> )
                    : ( <Component { ...props } /> )
            )}
        
        />
    )
}

export default PublicRoute;