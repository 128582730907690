//export const services = [{id: 1, name:'Comida'}, {id: 2, name:'Productos'}, {id: 3, name:'Servicios'}];

export const noticiaData = [

    { image: 'Not1.jpg' },
    { image: 'Not2.jpg' },
    { image: 'Not3.jpg' },
    { image: 'Not4.jpg' },
    { image: 'Not5.jpg' }
];


export const exampleUsersRecentsData = [

    { user: {name: '', lastname: ''}, userFiles:{}, userPrepare:{}, file: false },
    { user: {name: '', lastname: ''}, userFiles:{}, userPrepare:{}, file: true },
    { user: {name: '', lastname: ''}, userFiles:{}, userPrepare:{}, file: false },
    { user: {name: '', lastname: ''}, userFiles:{}, userPrepare:{} },
    { user: {name: '', lastname: ''}, userFiles:{}, userPrepare:{} },
    { user: {name: '', lastname: ''}, userFiles:{}, userPrepare:{} },
    { user: {name: '', lastname: ''}, userFiles:{}, userPrepare:{} },
    { user: {name: '', lastname: ''}, userFiles:{}, userPrepare:{} },
    { user: {name: '', lastname: ''}, userFiles:{}, userPrepare:{} },
    { user: {name: '', lastname: ''}, userFiles:{}, userPrepare:{} },
    { user: {name: '', lastname: ''}, userFiles:{}, userPrepare:{} },
    { user: {name: '', lastname: ''}, userFiles:{}, userPrepare:{} },
    { user: {name: '', lastname: ''}, userFiles:{}, userPrepare:{} },
    { user: {name: '', lastname: ''}, userFiles:{}, userPrepare:{} },
    { user: {name: '', lastname: ''}, userFiles:{}, userPrepare:{} },
    { user: {name: '', lastname: ''}, userFiles:{}, userPrepare:{} },
    { user: {name: '', lastname: ''}, userFiles:{}, userPrepare:{} },
    { user: {name: '', lastname: ''}, userFiles:{}, userPrepare:{} }
];

export const usersSearchSkeletonData = [

    { user: {name: '', lastname: ''}, userFiles:{}, userPrepare:{}, file: false },
    { user: {name: '', lastname: ''}, userFiles:{}, userPrepare:{}, file: true },
    { user: {name: '', lastname: ''}, userFiles:{}, userPrepare:{}, file: false },
    { user: {name: '', lastname: ''}, userFiles:{}, userPrepare:{} },
    { user: {name: '', lastname: ''}, userFiles:{}, userPrepare:{} },
    { user: {name: '', lastname: ''}, userFiles:{}, userPrepare:{} },
    { user: {name: '', lastname: ''}, userFiles:{}, userPrepare:{} },
    { user: {name: '', lastname: ''}, userFiles:{}, userPrepare:{} },
    { user: {name: '', lastname: ''}, userFiles:{}, userPrepare:{} }
];


export const jobData = {

    EU: { data: [

        { id:1,  descrID: 'Archivist Assistant' },
        { id:2,  descrID: 'Maintenance assistant' },
        { id:3,  descrID: 'Educational Technician' },
        { id:4,  descrID: 'Cultural Promoter' },
        { id:5,  descrID: 'Gardener' },
        { id:6,  descrID: 'Administrative' },
        { id:7,  descrID: 'Master of Arts' },
        { id:8,  descrID: 'Medical Administrator Assistant' },
        { id:9,  descrID: 'Laboratory assistant' },
        { id:10,  descrID: 'Administrative Assistant' },
        { id:11,  descrID: 'Accounting clerk' },
        { id:12,  descrID: 'Medical Administrator Assistant' },
        { id:13,  descrID: 'Maintenance assistant' },
        { id:14,  descrID: 'Nursing Assistant' },
        { id:15,  descrID: 'Nurse' },
        { id:16,  descrID: 'Psychologist' },
        { id:17,  descrID: 'Economic analyst' },
        { id:18,  descrID: 'Financial Analyst' },
        { id:19,  descrID: 'Engineer' }
    
        ]
    },
        
        
    MX: { data: [
        { id:1,  descrID: 'Auxiliar de Archivista' },
        { id:2,  descrID: 'Auxiliar de Mantenimiento' },
        { id:3,  descrID: 'Técnico Educativo' },
        { id:4,  descrID: 'Promotor Cultural' },
        { id:5,  descrID: 'Jardinero' },
        { id:6,  descrID: 'Administrativo' },
        { id:7,  descrID: 'Maestro en Artes' },
        { id:8,  descrID: 'Auxiliar de Médico Administrador' },
        { id:9,  descrID: 'Auxiliar de Laboratorio' },
        { id:10,  descrID: 'Auxiliar Administrativo' },
        { id:11,  descrID: 'Auxiliar de Contabilidad' },
        { id:12,  descrID: 'Auxiliar de Médico Administrador' },
        { id:13,  descrID: 'Auxiliar de Mantenimiento' },
        { id:14,  descrID: 'Auxiliar de Enfermería' },
        { id:15,  descrID: 'Enfermera' },
        { id:16,  descrID: 'Psicólogo' },
        { id:17,  descrID: 'Analista Económico' },
        { id:18,  descrID: 'Analista Financiero' },
        { id:19,  descrID: 'Ingeniero' }
    ]
    }
    
};

export const countryData = [

    { country: 'Colombia', countryCode:'CO' },
    { country: 'México', countryCode:'MX' }
   
];

export const getCountry = (countryCode) => {

    var index = countryData.findIndex(country => country.countryCode === countryCode);

    if(index !== -1 ){
        return countryData[index].country;
    }

    return '';
}

export const cityData = {
    MX: { data: [
        { id:1, descrString: 'Aguascalientes' },
        { id:2, descrString: 'Baja California' },
        { id:3, descrString: 'Baja California Sur' },
        { id:4, descrString: 'Campeche' },
        { id:5, descrString: 'Chiapas' },
        { id:6, descrString: 'Chihuahua' },
        { id:7, descrString: 'Ciudad de México' },
        { id:8, descrString: 'Coahuila' },
        { id:9, descrString: 'Colima' },
        { id:10, descrString: 'Durango' },
        { id:11, descrString: 'Estado de México' },
        { id:12, descrString: 'Guanajuato' },
        { id:13, descrString: 'Guerrero' },
        { id:14, descrString: 'Hidalgo' },
        { id:15, descrString: 'Jalisco' },
        { id:16, descrString: 'Michoacán' },
        { id:17, descrString: 'Morelos' },
        { id:18, descrString: 'Nayarit' },
        { id:19, descrString: 'Nuevo León' },
        { id:20, descrString: 'Oaxaca' },
        { id:21, descrString: 'Puebla' },
        { id:22, descrString: 'Querétaro' },
        { id:23, descrString: 'Quintana Roo' },
        { id:24, descrString: 'San Luis Potosí' },
        { id:25, descrString: 'Sinaloa' },
        { id:26, descrString: 'Sonora' },
        { id:27, descrString: 'Tabasco' },
        { id:28, descrString: 'Tamaulipas' },
        { id:29, descrString: 'Tlaxcala' },
        { id:30, descrString: 'Veracruz' },
        { id:31, descrString: 'Yucatán' },
        { id:32, descrString: 'Zacatecas' },
        ]
    }
    ,

    CO: { data: [
        { id:1, descrString: 'Amazonas' },
        { id:2, descrString: 'Antioquia' },
        { id:3, descrString: 'Arauca' },
        { id:4, descrString: 'Atlántico' },
        { id:5, descrString: 'Bogotá, Distrito Capital' },
        { id:6, descrString: 'Bolívar' },
        { id:7, descrString: 'Boyacá' },
        { id:8, descrString: 'Caldas' },
        { id:9, descrString: 'Caquetá' },
        { id:10, descrString: 'Casanare' },
        { id:11, descrString: 'Cauca' },
        { id:12, descrString: 'Cesar' },
        { id:13, descrString: 'Chocó' },
        { id:14, descrString: 'Córdoba' },
        { id:15, descrString: 'Cundinamarca' },
        { id:16, descrString: 'Guainía' },
        { id:17, descrString: 'Guaviare' },
        { id:18, descrString: 'Huila' },
        { id:19, descrString: 'La Guajira' },
        { id:20, descrString: 'Magdalena' },
        { id:21, descrString: 'Meta' },
        { id:22, descrString: 'Nariño' },
        { id:23, descrString: 'Norte de Santander' },
        { id:24, descrString: 'Putumayo' },
        { id:25, descrString: 'Quindío' },
        { id:26, descrString: 'Risaralda' },
        { id:27, descrString: 'San Andrés y Providencia' },
        { id:28, descrString: 'Santander' },
        { id:29, descrString: 'Sucre' },
        { id:30, descrString: 'Tolima' },
        { id:31, descrString: 'Valle del Cauca' },
        { id:32, descrString: 'Vaupés' },
        { id:33, descrString: 'Vichada' },
        ]
    },
        

    default: { data:
        [
            
        ]
        
    }
        
}

export const getCity = (searchOnBoarding) => {

    var cityByCountryCode = cityData[searchOnBoarding.countryCode];

    var index = cityByCountryCode.data.findIndex(city => city.id === searchOnBoarding.cityID);

    if(index !== -1 ){
        return cityByCountryCode.data[index].descrString;
    }

    return '';
}


export const searchFilterData = {

    MX: { data: [
            { value: 'Job', label: 'Puesto' },
            { value: 'Email', label: 'Correo' },
            { value: 'Name', label: 'Nombre' }
        ]
    },
    EU: { data: [
            { value: 'Job', label: 'Job' },
            { value: 'Email', label: 'Email' },
            { value: 'Name', label: 'Name' }
        ]
    }
};

