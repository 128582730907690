import React,  { useEffect, useState } from 'react';

//Redux
import { useSelector, useDispatch } from 'react-redux';

//Actions
import { setSearchOnBoarding } from '../redux/actions/searchOnBoardingAction';
import { showSideBar } from '../redux/actions/uiAction';

//Data
import { cityData, countryData, jobData, getCountry, getCity } from '../data/info';

//Strings
import { RegisterOnBoardingStrings } from '../data/strings';

//Img
import ICO_onboarding from '../assets/img/ICO_onboarding.png';

const RegisterOnBoarding = (props) => {

    //Language
    const strings = RegisterOnBoardingStrings['MX'];
    const jobs = jobData['MX'].data;

    const dispatch = useDispatch();

    const { searchOnBoarding } = useSelector(state => state.searchOnBoardingReducer);


    const initialInfoSelect = {
        job: '',//Text
        jobID: -1,
        country: '',
        countryCode: 'default',//CountryCode
        city:'',//Text
        cityID: 0

    };

    const [infoSelect, setSelect] = useState(initialInfoSelect);

  
    useEffect(() => {

        dispatch(showSideBar(false));

    }, [dispatch]);

    useEffect(() =>{

        if(typeof(searchOnBoarding) !== 'undefined' && Object.keys(searchOnBoarding).length !== 0){//|| pageSelected==null || 
            
            console.log("Entrooooooo", searchOnBoarding);

            const setUp = () => {

                setSelect({
                
                    job: (searchOnBoarding.job !== -1) ? jobs[jobs.findIndex(job => job.id === searchOnBoarding.job)].descrID : '',//jobData[jobData.findIndex(job => job.id === searchOnBoarding.job)].descrID : '',
                    jobID: searchOnBoarding.job,
                    country:  getCountry(searchOnBoarding.countryCode),
                    countryCode: searchOnBoarding.countryCode,
                    cityID:  searchOnBoarding.cityID,
                    city: getCity(searchOnBoarding) 
                });
               
            }
    
            setUp();
        }
       
        
    }, [searchOnBoarding]);//infoSelect

    const handleInputChangeJob = ({target}) => {

        let job = target.value;
        let indexJob = jobs.findIndex(x=> x.descrID === job);

        setSelect({ 
            ...infoSelect,
            job,
            jobID: (indexJob === -1) ? -1 : jobs[indexJob].id
        });

    };

    const handleInputChangeCountry = ({target}) => {

        let country = target.value;

        const index = countryData.findIndex(x=> x.country === country);

        setSelect({ 
            ...infoSelect,
            country,
            countryCode: (index === -1) ? 'default' : countryData[index].countryCode,
            city: '',
            cityID: 0
        });
        
    };

    const handleInputChangeCity = ({target}) => {
   
        //const index = cityData[countryCode].data.findIndex(city => city.id === city);
        const index = target.selectedIndex;
        const optionElement = target.childNodes[index];
        const cityID = parseInt(optionElement.getAttribute('id'), 10);
        //console.log("cityID", cityID);

        setSelect({ 
            ...infoSelect,
            city: target.value,
            cityID: cityID
        });
    };

    const handleSave = () =>{

        dispatch(setSearchOnBoarding({job: infoSelect.jobID, countryCode: infoSelect.countryCode, cityID: infoSelect.cityID}, props.history));
    }

    const page = (

        <div className="row m-0 justify-content-center">

            <div className="mainContainerOnBoardingColumns text-center">

                <img src={ICO_onboarding} className="imgOnBoarding" alt="Register On Boarding"></img>


                <div className="containerOnBoardingTitle m-auto">
                    <h1>{strings.labelTitle}</h1>
                    <h1>{strings.labelSubTitle}</h1>
                </div>
                


                <form className="containerOnBoardingFilter m-auto">

                    <div className="form-group">
                        <label htmlFor="job" className="col-form-label">{strings.labelJob}</label>

                       <select name="job" id="job" className="form-control inputFieldOnBoardingRegister" onChange={ handleInputChangeJob } value={infoSelect.job}>

                            <option>{strings.labelSelectJob}</option>    

                            {jobs.map(job => <option key={job.id}>{job.descrID}</option>) }                                      

                        </select>

                    </div>



                    <div className="form-group">

                        <div className="row m-0">


                                <div className="col-2 p-0 text-left">
                                    <label className="col-form-label">{strings.labelCountry}</label>
                                </div>
                                
                                
                                <div className="col-10 p-0">

                                    <select 
                                    name="country" 
                                    id="country" 
                                    className="form-control inputFieldOnBoardingRegister" 
                                    onChange={handleInputChangeCountry} 
                                    value={infoSelect.country}>

                                    <option>{strings.labelSelectCountry}</option>    

                                    {countryData.map(country => <option key={country.countryCode}>{country.country}</option>) }                                  

                                    </select>
                                </div>
                            

                        </div>
                        

                    </div>


                    <div className="form-group">

                        <div className="row m-0">

                            <div className="col-2 p-0 text-left">
                                <label className="col-form-label">{strings.labelCity}</label>
                            </div>

                            <div className="col-10 p-0">
                                <select 
                                name="city" 
                                id="city" 
                                className="form-control inputFieldOnBoardingRegister" 
                                onChange={  handleInputChangeCity }  
                                value={infoSelect.city}>

                                <option>{strings.labelSelectCity}</option>    

                                {cityData[infoSelect.countryCode].data.map(city => <option key={city.id} id={city.id}>{city.descrString}</option>) }                                  

                                </select>
                            </div>

                        </div>

                    </div>

                    <button type="button" className="btn btn-primary btn-block btnRegisterOnBoarding mt-5" onClick={handleSave}>{strings.btnSave}</button>

                </form>

            </div>
           
        </div>
        
        
    )

    return (

        <div className="wrapper">
        
            { page }

        </div>
    )
    
}

export default RegisterOnBoarding;