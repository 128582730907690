import React from 'react';

import { Route, Redirect } from 'react-router-dom';

const PrivateRoute = ({
    isAuthenticated,
    component: Component,
    ...rest
}) => {

    return (
        <Route { ...rest }
            component={ (props) => (

                ( !isAuthenticated )
                    ? ( <Redirect to="/login" /> )
                    
                    : ( <Component { ...props } /> )
            )}
        
        />
    )
}

export default PrivateRoute;