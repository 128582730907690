import { types } from '../types/types';

const initialState = 
{
    stateSettings: {
        name:'',
        lastname:'',
        company:'',
        email:'',
        job:'',
        password:'',
        country:'',
        city:'',
        phone:'',
        postCode:'',
        role:'',
        defaultPais: 'Selecciona una opc'
    },
    errorState:{

        name:{
            status: false,
            msg: ''
        },
        lastname:{
            status: false,
            msg: ''
        },
        company:{
            status: false,
            msg: ''
        },
        job:{
            status: false,
            msg: ''
        },
        country:{
            status: false,
            msg: ''
        },
        serv:{
            status: false,
            msg: ''
        }
    }
}

export const stateSettingsReducer = ( state = initialState, action ) => {

    switch ( action.type ) {
               
        case types.setStateSettings:
            return{
                ...state,
                stateSettings:{
                    ...action.payload.stateSettings
                }
            }

        case types.errorAuthSettings:
            return{
                ...state,
                errorState:{
                    ...state.errorState,
                    ...action.payload
                }
            }

        case types.resetStateSettings:
            return initialState;
    
        default:
            return state;
    }

}