import React, { useEffect, useState, Fragment, useRef } from 'react';

//Redux
import { useSelector, useDispatch } from 'react-redux';

//Components
import HeaderSettings from '../components/Settings/HeaderSettings';

//ROUTES
import * as ROUTES from '../data/routes';

//Model
import { SettingsModel } from '../models/SettingsModel';

//Actions
import { setUp, resetStateSettings } from '../redux/actions/stateSettingsAction';
import { showSideBar } from '../redux/actions/uiAction';
import { updateSettings, updateProfilePhotoSettings, CerrarSesion } from '../redux/actions/auth';

//Data
import { countryData } from '../data/info';
import {socialData} from '../data/constant';
import { getPlan } from '../helpers/helpers';

//Strings
import { SettingsStrings } from '../data/strings';

//Images
import ic_facebook from '../assets/img/SocialMedia/ic_facebook.png';
import ic_instagram from '../assets/img/SocialMedia/ic_instagram.png';
import ic_twitter from '../assets/img/SocialMedia/ic_twitter.png';

const Settings = (props) => {

    //Language
    const strings = SettingsStrings['MX'];

    //Model
    let settingsModel = new SettingsModel(strings); 

    const dispatch = useDispatch();

    const { showSideBarSts } = useSelector(state => state.uiReducer);
    const { userInfo } = useSelector(state => state.auth);
    let activeUser = useRef( userInfo );

    const [data, setData] = useState(
        {
            user:{},
            userFiles:{},
            userPrepare:{}
        }
    );

    const { stateSettings, errorState } = useSelector(state => state.stateSettingsReducer);

    const [stateForm, setState] = useState(stateSettings);

    const { name, lastname, company, email, job, password, country, city, phone, postCode, role, defaultPais } = stateForm;

    const [stateFile, setStateFile] = useState({
 
        fileGroup:{
            file:null,
            fileSource:''//This is the the fileURL
        }
    });

    useEffect(() => {
        dispatch(showSideBar(true));
    }, [dispatch]);


    useEffect(() => {

        console.log("Settings useEffect");

        if (typeof(userInfo.user) !== 'undefined' && userInfo.user.name !== ""){

            //console.log("Initial userInfo name", userInfo.user.name);
            //console.log("Initial userInfo lastname", userInfo.user.pais);

            setData({...userInfo});        
            
            setState(
                {
                    ...stateForm,
                    name: userInfo.user.name,
                    lastname: userInfo.user.lastname,
                    company: userInfo.user.company,
                    email: userInfo.user.email,
                    job: userInfo.user.job,
                    password:"00000000",
                    country: userInfo.user.country,
                    city: userInfo.user.city,
                    phone: userInfo.user.phone,
                    postCode: userInfo.user.postCode,
                    role: userInfo.user.role
                }
                );
            
            dispatch(resetStateSettings());
            
        }

        if (typeof(userInfo.user) === 'undefined' || userInfo.user === null || Object.keys(userInfo.user).length === 0){
            console.log("Returnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnn Settings", userInfo);
            return;
        }

        if (activeUser.current.user !== userInfo.user ||
            activeUser.current.userFiles.profilePhoto !== userInfo.userFiles.profilePhoto) {

           
            console.log("Cambio Userrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrr Settings", userInfo);

            activeUser.current = userInfo;

            //SetUp();
            setData({...userInfo});         
            //setState({...userInfo.user, password:"00000000"});
            setState(
                {
                    ...stateForm,
                    name: userInfo.user.name,
                    lastname: userInfo.user.lastname,
                    company: userInfo.user.company,
                    email: userInfo.user.email,
                    job: userInfo.user.job,
                    password:"00000000",
                    country: userInfo.user.country,
                    city: userInfo.user.city,
                    phone: userInfo.user.phone,
                    postCode: userInfo.user.postCode,
                    role: userInfo.user.role
                }
                );

            dispatch(resetStateSettings());
        }
     

    }, [dispatch, userInfo]);

    const handleInputChange = ({target}) => {

        setState({
            ...stateForm,
            [target.name]: target.value
        })
    };

    const showPrivacyPolicy = () =>{
        //props.history.push('/privacy');
        var win = window.open(ROUTES.TERMS, '_blank');
        win.focus();
    }

    const fileSelectedHandler = (e) => {

        //setLoading(true);
        console.log("Empieza");

        console.log("file", e.target.files[0]);

        
        // Assuming only image
        var file = e.target.files[0];
        var reader = new FileReader();
        reader.readAsDataURL(file);//var url = reader.readAsDataURL(file);
        

        reader.onloadend = function (event) {

            //console.log("Entro", event);

            
            setStateFile({
                ...stateFile,
                fileGroup: {
                    file,
                    fileSource : reader.result//[reader.result]
                }
            });


        }//.bind(this);

    }

    const handleGuardar = (e) => {

        e.preventDefault();

        settingsModel.validate(stateForm);

        //Local
        if(settingsModel.errorStatus){
            dispatch(setUp(stateForm, settingsModel.toObject()));
            return;
        }

        //Server
        dispatch(updateSettings({
            name: stateForm.name,
            lastname: stateForm.lastname,
            company: stateForm.company,
            job: stateForm.job,            
            country: stateForm.country,
            city: stateForm.city,
            phone: stateForm.phone,
            postCode: stateForm.postCode
        }));

        if(stateFile.fileGroup.file !== null){

            //console.log("Update File", stateFile.fileGroup.file);
            setStateFile({
                ...stateFile,
                fileGroup: {
                    file:null,
                    fileSource:''//This is the the fileURL
                }
            });

            dispatch(updateProfilePhotoSettings(stateFile.fileGroup.file));   
        }
    }


    const logout = (e) =>{
       
        e.preventDefault();
        dispatch(CerrarSesion());
    }

    const page = (

        <Fragment>

            <div className="mainContainerSettingsColumns">
                
                <HeaderSettings data={data} fileGroup={stateFile.fileGroup} fileSelectedHandler={fileSelectedHandler} />

                <div className="containerSettingsColumns">

                    <div className="row m-0 justify-content-center">

                        <div className="col-12 col-lg-6 col-xl-4">

                            <form className="">

                                <div className="form-group">
                                    
                                    <label htmlFor="name" className="col-form-label label-input">{strings.labelName}</label>
                                    
                                    <input 
                                        type="text"
                                        placeholder={strings.labelName}
                                        name="name"
                                        id="name"
                                        autoComplete="off"
                                        value={ name }
                                        onChange={ handleInputChange }
                                        className="form-control inputFieldSettingsBorderBottom"/>

                                    {errorState.name.status && <div className="errorSettings text-left"><span>{errorState.name.msg}</span></div>}

                                </div>

                                <div className="form-group">

                                    <label htmlFor="lastname" className="col-form-label label-input">{strings.labelLastName}</label>

                                    <input 
                                        type="text"
                                        placeholder={strings.labelLastName}
                                        name="lastname"
                                        id="lastname"
                                        autoComplete="off"
                                        value={ lastname }
                                        onChange={ handleInputChange }
                                        className="form-control inputFieldSettingsBorderBottom"/>

                                    {errorState.lastname.status && <div className="errorSettings text-left"><span>{errorState.lastname.msg}</span></div>}

                                </div>

                                <div className="form-group">

                                    <label htmlFor="company" className="col-form-label label-input">{strings.labelCompany}</label>

                                    <input 
                                        type="text"
                                        placeholder={strings.placeHolderCompany}
                                        name="company"
                                        id="company"
                                        autoComplete="off"
                                        value={ company }
                                        onChange={ handleInputChange }
                                        className="form-control inputFieldSettingsBorderBottom"/>

                                    {errorState.company.status && <div className="errorSettings text-left"><span>{errorState.company.msg}</span></div>}

                                </div>

                                <div className="form-group">

                                    <label className="col-form-label label-input">{strings.labelEmail}</label>

                                    <input 
                                        type="text"
                                        placeholder={strings.placeHolderEmail}
                                        name="email"
                                        id="email"
                                        autoComplete="off"
                                        value={ email }
                                        onChange={ handleInputChange }
                                        className="form-control inputFieldSettingsBorderBottom"
                                        disabled="disabled"/>

                                </div>


                                <div className="form-group">

                                    <label htmlFor="job" className="col-form-label label-input">{strings.labelJob}</label>

                                    <input 
                                        type="text"
                                        placeholder={strings.labelJob}
                                        name="job"
                                        id="job"
                                        autoComplete="off"
                                        value={ job }
                                        onChange={ handleInputChange }
                                        className="form-control inputFieldSettingsBorderBottom"/>

                                    {errorState.job.status && <div className="errorSettings text-left"><span>{errorState.job.msg}</span></div>}

                                </div>


                                <div className="form-group">
                                    <label className="col-form-label pt-0 label-input-p">{strings.labelPassword}</label>

                                    <div className="containerPassword">
                                        <input 
                                            type="password"
                                            placeholder={strings.labelPassword}
                                            name="password"
                                            id="password"
                                            autoComplete="off"
                                            value={ password }
                                            onChange={ handleInputChange }
                                            className="form-control inputFieldRegister"
                                            disabled="disabled"/>

                                        {/* <span toggle="#input-pwd" id="toggle-password"  className="fa fa-fw fa-eye field-icon toggle-password" onClick={() => handleShowHide("password", "toggle-password")}></span> */}
                                    </div>

                                </div>
                                
                            </form>

                        </div>


                        <div className="col-12 col-lg-6 col-xl-4">

                            <form className="">

                        
                                <div className="form-group">
                                    <label htmlFor="phone" className="col-form-label label-input">{strings.labelPhone}</label>
                                    <input 
                                        type="text"
                                        placeholder={strings.labelPhone}
                                        name="phone"
                                        id="phone"
                                        autoComplete="off"
                                        value={ phone }
                                        onChange={ handleInputChange }
                                        className="form-control inputFieldSettingsBorderBottom"/>
                                </div>



                                <div className="form-group">

                                    <label className="col-form-label pt-0 label-input-p">{strings.labelCountry}</label>

                                    <select 
                                        name="country" 
                                        id="country" 
                                        value={country} 
                                        className="form-control inputFieldRegister" 
                                        onChange={ handleInputChange }>

                                        <option>{defaultPais}</option>    

                                        {countryData.map(country => <option key={country.countryCode}>{country.country}</option>) }                                  

                                    </select>

                                    {errorState.country.status && <div className="errorSettings text-left"><span>{errorState.country.msg}</span></div>}

                                </div>

                                <div className="form-group">
                                    <label htmlFor="city" className="col-form-label label-input">{strings.labelCity}</label>
                                    <input 
                                        type="text"
                                        placeholder={strings.labelCity}
                                        name="city"
                                        id="city"
                                        autoComplete="off"
                                        value={ city }
                                        onChange={ handleInputChange }
                                        className="form-control inputFieldSettingsBorderBottom"/>
                                </div>


                                <div className="form-group">
                                    <label htmlFor="postCode" className="col-form-label label-input">{strings.labelPostCode}</label>
                                    <input 
                                        type="text"
                                        placeholder={strings.labelPostCode}
                                        name="postCode"
                                        id="postCode"
                                        autoComplete="off"
                                        value={ postCode }
                                        onChange={ handleInputChange }
                                        className="form-control inputFieldSettingsBorderBottom"/>
                                </div>


                                <div className="form-group">
                                    <label htmlFor="plan" className="col-form-label label-input">{strings.labelPlan}</label>
                                    <input 
                                        type="text"
                                        placeholder={strings.labelPlan}
                                        name="plan"
                                        id="plan"
                                        autoComplete="off"
                                        value={ getPlan(role) }
                                        onChange={ handleInputChange }
                                        className="form-control inputFieldSettingsBorderBottom"
                                        disabled="disabled"/>
                                </div>


                                <div className="form-group">
                                    <button type="submit" className="btn btn-primary btn-block btnSettings" onClick={handleGuardar}>{strings.btnSave}</button>
                                </div>


                              
                            
                            </form>
                        </div>

                        <div className="col-12 col-lg-6 col-xl-4">

                            <form className="">


                                <div className="row m-0 text-center containerPrivacyPolicy">
                         
                                    <div className="col-12 p-0 text-left align-self-center text-center" onClick={showPrivacyPolicy}>
                                        <p className="mb-0">{strings.labelPrivacyPolicy1} <span>{strings.labelPrivacyPolicy2}</span></p>
                                    </div>
                                    
                                </div>


                                <div className="text-center pb-4 settingsSocialMedia">

                                    <h1>{strings.socialMedia}</h1>

                                    <a href={socialData.facebook} target="_blank" rel="noopener noreferrer">
                                        <img src={ic_facebook} className="imgSocialMediaSettings" alt="Facebook"></img>
                                    </a>

                                    <a href={socialData.instagram} target="_blank" rel="noopener noreferrer">
                                        <img src={ic_instagram} className="imgSocialMediaSettings" alt="Instagram"></img>
                                    </a>

                                    <a href={socialData.twitter} target="_blank" rel="noopener noreferrer">
                                        <img src={ic_twitter} className="imgSocialMediaSettings" alt="LinkedIn"></img>
                                    </a>

                                </div>


                                <div className="form-group">
                                    <button type="submit" className="btn btn-primary btn-block btnSettings" onClick={logout}>{strings.btnLogOut}</button>
                                </div>
                            </form>
                        </div>

                    </div>


                </div>
                
            
            </div>


        </Fragment>

    )

    return (
  
        <div className={`${showSideBarSts ? 'menuDisplayed': ''} wrapper`}>
            <div className="page-content-wrapper">
                <div className="container-fluid p-0">

                    { page }

                </div>
            </div>
        </div>
    )
    
}

export default Settings;