import React from 'react';

//Img
import ICO_educacion from '../../assets/img/CandidatoProfile/ICO_educacion.png';

const PrepareEducation = (props) => {
    
    const { group_NivelFormacion, formacionCat } = props;
    const { strings } = props;

    return (
        <div className="col-12 p-0 mainContainerPrepareItemProfileCandidato">
                            
                            
            <div className="containerImgPrepareProfileCandidato">
                <img src= {ICO_educacion} className="imgPrepareCandidatoInfo" alt="Educacion"></img>
            </div>


            <div className="row m-0 containerInfoPrepareProfileCandidato">
                <div className="subcontainerInfoPrepareProfileCandidato">
                    
                    <h1>{strings.titleEducation}</h1>                
                    {group_NivelFormacion && group_NivelFormacion.map(formacion => <p key={formacion}>{formacionCat[formacion].descrID}</p>)}
                    
                </div>
            </div>

        </div>
    )
    
}

export default PrepareEducation;
