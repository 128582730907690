import { types } from '../types/types';


const initialState = 
{
    stateLogIn:{
        email:'',
        password:''
    },
    errorState:{

        email:{
            status: false,
            msg: ''
        },
        password:{
            status: false,
            msg: ''
        },
        serv:{
            status: false,
            msg: ''
        }
    }
}

export const stateLogInReducer = ( state = initialState, action ) => {

    switch ( action.type ) {
        
        case types.setStateLogIn:
            return{
                ...state,
                stateLogIn:{
                    ...action.payload.stateLogIn
                }
            }

        case types.errorAuthLogIn:
            return{
                ...state,
                errorState:{
                    ...state.errorState,
                    ...action.payload
                }
            }
            
        case types.resetStateLogIn:
            return initialState;

        case types.logout:
            return initialState;
            
        default:
            return state;
    }

}

/*
  case types.errorAuth:
            return{
                ...state,
                errorState:{
                    ...state.errorState,
                    serv:{
                        status: action.payload.status,
                        msg: action.payload.msg
                    }
                }
            }
*/
