import React from 'react';

 const ContainerPage = ({children, showSideBarSts}) => {
    
    return (
        <div className={`${showSideBarSts ? 'menuDisplayed': ''} wrapper`}>
            <div className="page-content-wrapper">
                <div className="container-fluid">

                     { children }

                </div>
            </div>
        </div>
    )
    
}

export default ContainerPage;