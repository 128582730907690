import { validFormatPassword } from './PasswordModel';
import { FieldError } from './FieldError';

export class LogInModel {

    constructor(stringsAuth) {
        this.errorStatus = false;
        this.emailError = new FieldError();
        this.passwordError = new FieldError();
        this.stringsAuth = stringsAuth;
    }

    validate(stateForm){

        this.errorStatus = false;

        if(stateForm.email.length === 0){
            this.emailError.setValues(true, this.stringsAuth.FieldEmailError);
            this.errorStatus = true;
        }

        let validatePassword = validFormatPassword(stateForm.password);
        if(!validatePassword.status) {
            this.passwordError.setValues(true, validatePassword.error);
            this.errorStatus = true;
        }
    }

    
    toObject(){
        return  {
            email: this.emailError.toObject(),
            password: this.passwordError.toObject(),
            serv:{
                status: false,
                msg: ''
            }
        }
    }
}
