import React, { useRef } from 'react';

//Helpers
//import { getJob } from '../../helpers/helpers';

//Images
import ic_profile from '../../assets/img/ic_profile.png';
import BUT_edit_up from '../../assets/img/BUT_edit_up.png';

 const HeaderSettings= (props) => {
 
    const { data, fileGroup } = props;

    let fileInput = useRef();

    const handleOnError = ({target}) => {
        
        //userFiles.profilePhoto = null;
        console.log("handleError", target);
        target.src = ic_profile;//'some default image url';
    }


    return (

        <div className="containerHeaderSettings col-12 p-0">
                        
            <div className="mainContainerPictureProfileCandidato">

                    <div className="containerImgProfileCandidato">

                        
                        <input 
                            style={{display: 'none'}} 
                            type="file" 
                            onChange={props.fileSelectedHandler}
                            ref={inputRef => fileInput = inputRef}
                        />

                        <div className="subcontainerImgProfileCandidato">
                            
                            <img 
                                src={ fileGroup.fileSource || data.userFiles.profilePhoto || ic_profile } 
                                className="imgProfileCandidato" 
                                alt="Imagen Perfil" 
                                onClick={()=>{fileInput.click()}} 
                                onError={(e) => handleOnError(e)}></img>
                            

                            <img 
                                src={ BUT_edit_up } 
                                className="editImgProfileCandidato" 
                                alt="Imagen Edit" 
                                onClick={()=>{fileInput.click()}} 
                                ></img>

                        </div>
                      
                    </div>


                    <div className="row m-0 containerInfoSettings">

                        <div className="subcontainerInfoProfileCandidato">

                            <h4 className="mb-0">{`${data.user.name ? data.user.name : "" }  ${data.user.lastname ? data.user.lastname : ""}`}</h4>
                            <p className="mb-0">{data.user.job}</p>
                            
                        </div>
                        
                    </div>

            </div>

        </div>
    )
    
}

export default HeaderSettings;