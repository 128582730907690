import React, { Fragment } from 'react';

//Components
import PlanLite from './Plan/PlanLite';
import PlanPlatinum from './Plan/PlanPlatinum';
import PlanExtended from './Plan/PlanExtended';

const ServicesMobile = (props) => {

    const { handleLogIn, imgHeader } = props;

    return (

        <Fragment>

            <div className="containerHeaderServicios">
                
                <img className="imgQuienesSomos" src={imgHeader} alt="Services"></img>

                <div className="container p-0 row m-0 subContainerHeaderServiceColumns">
    
                    <div className="col-12 p-0 text-center">
                        <h1>Tenemos un plan para tus necesidades</h1>    
                    </div>
    
                </div>
    
            </div>


            <div className="mainContainerServicios">

                <div className="subContainerServicios row">
  
                    <PlanLite handleLogIn={handleLogIn}/>

                    <PlanPlatinum handleLogIn={handleLogIn}/>

                    <PlanExtended handleLogIn={handleLogIn}/>

                </div>

            </div>

        </Fragment>

    )
    
}

export default ServicesMobile;
