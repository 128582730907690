

export const accessModuleProfileUser = (roleID) => {

    if(roleID === 0)
    {
        return false;   
    }
    else if(roleID === 1)
    {
        return true;   
    }
    else if(roleID === 10)
    {
        return true;   
    }
   
    return false;
}

export const accessModulePrivateNavigation = (roleID) => {    

    if(roleID === 0)
    {
        return false;   
    }
    else if(roleID === 1)
    {
        return true;   
    }
    else if(roleID === 10)
    {
        return true;   
    }

    return true;
}

export const accessModuleSearch = (roleID) => {    

    if(roleID === 0)
    {
        return false;   
    }
    else if(roleID === 1)
    {
        return true;   
    }
    else if(roleID === 10)
    {
        return true;   
    }

    return true;
}