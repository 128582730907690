import React, { Fragment, useEffect } from 'react';

//Redux
import { useDispatch } from 'react-redux';

//Router
import { useHistory } from 'react-router-dom';
import * as ROUTES from '../data/routes';

//Firebase
import { firebase } from '../firebase/firebase-config';

//Action
import { login, setChecking, pruebaLogIn } from '../redux/actions/auth';


 const CheckAuth = () => {

    const dispatch = useDispatch();
    const history = useHistory();


    useEffect(() => {
        
        firebase.auth().onAuthStateChanged( async(user) => {

            if ( user?.uid ) {

                const pathname = history.location.pathname;
                const splitPath = pathname.split('/');
                const url = '/' + splitPath[splitPath.length -1];
        
                if(url === ROUTES.SIGN_UP){
        
                    history.push(`${ROUTES.SIGN_IN}`);
        
                    return;
                }
                
                /*else{
                    console.log("Not in Register");
                }*/
        

                dispatch(pruebaLogIn(user, history));


                //dispatch(setChecking(false));

            } else {
                //setIsLoggedIn( false );
                console.log("No logged");

                dispatch(setChecking(false));
            }

            //dispatch(setChecking(false));

        });
        //dispatch(setChecking(false));
        
    }, [ dispatch, history ])
    
    return (
        <Fragment>
            
        </Fragment>
    )
    
}

export default CheckAuth;
