import { types } from '../types/types';

const initialState = 
{
    stateRegister: {
        name:'',
        lastname:'',
        company:'',
        email:'',
        job:'',
        password:'',
        confirmPassword:'',
        country:'',
        defaultPais:'Selecciona una opc',
        privacyPolicy: false
    },
    errorState:{

        name:{
            status: false,
            msg: ''
        },
        lastname:{
            status: false,
            msg: ''
        },
        company:{
            status: false,
            msg: ''
        },
        email:{
            status: false,
            msg: ''
        },
        job:{
            status: false,
            msg: ''
        },
        password:{
            status: false,
            msg: ''
        },
        confirmPassword:{
            status: false,
            msg: ''
        },
        country:{
            status: false,
            msg: ''
        },
        serv:{
            status: false,
            msg: ''
        }
    }
}

export const stateRegisterReducer = ( state = initialState, action ) => {

    switch ( action.type ) {
        
       
        case types.setStateRegister:
            return{
                ...state,
                stateRegister:{
                    ...action.payload.stateRegister
                }
            }

      
        case types.errorAuthRegister:
            return{
                ...state,
                errorState:{
                    ...state.errorState,
                    ...action.payload
                }
            }

        case types.resetStateRegister:
            return initialState;
    
        default:
            return state;
    }

}