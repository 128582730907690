import React from 'react';

//Img
import ICO_hobbies from '../../assets/img/CandidatoProfile/ICO_hobbies.png';

const PrepareHobbies = (props) => {
    
    const { group_Hobbies, hobbiesCat } = props;
    const { strings } = props;

    return (
        <div className="col-12 p-0 mainContainerPrepareItemProfileCandidato">
                            
                            
            <div className="containerImgPrepareProfileCandidato">
                <img src= {ICO_hobbies} className="imgPrepareCandidatoInfo" alt="Hobbies"></img>
            </div>


            <div className="row m-0 containerInfoPrepareProfileCandidato">
                <div className="subcontainerInfoPrepareProfileCandidato">
                    <h1>{strings.titleHobbies}</h1>   
                    {
                        group_Hobbies && 
                        group_Hobbies.map(hobbie => <p key={hobbie}>{hobbiesCat[hobbie].descrID}</p>)
                    }
                </div>
            </div>

        </div>
    )
    
}

export default PrepareHobbies;
