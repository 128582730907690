import React, { Fragment } from "react";

//Redux
import { useSelector } from "react-redux";

//Router
import { useHistory } from "react-router-dom";
import * as ROUTES from '../../data/routes';

//Hooks
import { useAccessModule } from '../../hooks/useAccessModule';

//Helpers
import { accessModulePrivateNavigation } from  '../../helpers/accessModule';

//Icons
import MENU_busqueda_up from '../../assets/img/SideBar/MENU_busqueda_up.png';
import MENU_filtro_up from '../../assets/img/SideBar/MENU_filtro_up.png';
import MENU_carpeta_up from '../../assets/img/SideBar/MENU_carpeta_up.png';
import MENU_chat_up from '../../assets/img/SideBar/MENU_chat_up.png';

const FooterBar = () => {
    
    const [ validateAccess ] = useAccessModule(accessModulePrivateNavigation);
    const history = useHistory();

    const { sidebar, showSideBarSts } = useSelector((state) => state.uiReducer);
    const { role } = useSelector(state => state.auth.userInfo.user);

    const handleClick = (e, route) =>{
      e.preventDefault();

      if(validateAccess() || route === ROUTES.REGISTER_ON_BOARDING){
          history.push(route);
      }
  }


    let container;
    if (sidebar) {

        container = (

          <div className={`${showSideBarSts ? "menuDisplayed" : ""} fixed-bottom footer-wrapper`}>
            <div className="">
              <nav className="navbar navbar-expand navbar-dark bg-dark container-footer-navbar">
                <div className="container">
                  <div className="m-auto">

                    <ul className="navbar-nav ">

                      <li className="nav-item" onClick={(e) => handleClick(e, ROUTES.REGISTER_ON_BOARDING)}>
                        <div className="navbar-brand op-sidebar-busqueda">
                          <img
                          src={MENU_busqueda_up}
                          className="imageOptionFooter"
                          alt="Busqueda"
                          loading="lazy"
                          />
                          </div>
                      </li>


                      {
                        role === 10 ? 
                        
                          <Fragment>
                            <li className="nav-item" onClick={(e) => handleClick(e, ROUTES.SEARCH_FILTER)}>
                              <div className="navbar-brand op-sidebar-filtro">
                                <img
                                src={MENU_filtro_up}
                                className="imageOptionFooter"
                                alt="Filtro"
                                loading="lazy"
                                />
                              </div>
                            </li>

                            <li className="nav-item" onClick={(e) => handleClick(e, ROUTES.PERFILES_GUARDADOS)}>
                              <div className="navbar-brand op-sidebar-carpetas">
                                <img
                                src={MENU_carpeta_up}
                                className="imageOptionFooter"
                                alt="Carpeta"
                                loading="lazy"
                                />
                              </div>
                            </li>

                            <li className="nav-item" onClick={(e) => handleClick(e, ROUTES.CHAT)}>
                              <div className="navbar-brand op-sidebar-chat">
                                <img
                                src={MENU_chat_up}
                                className="imageOptionFooter"
                                alt="Chat"
                                loading="lazy"
                                />
                              </div>
                            </li>
                          </Fragment>
                          :
                          <Fragment></Fragment>
                      }

                      

                    </ul>

                  </div>
                </div>
              </nav>
            </div>
            
          </div>
        )
      
    }

    else{
        container = <Fragment></Fragment>
    }

    return (

      container

    );
    
}

export default FooterBar;