import React, { Fragment } from 'react';

//Redux
import { useDispatch, useSelector } from 'react-redux';

//Actions
import { getUsersNextFilter } from '../../redux/actions/searchAction';

 const LoadMore = (props) => {

    const { strings } = props;

    const dispatch = useDispatch();
    
    const { searchUsers } = useSelector(state => state.searchReducer);

    const handleSearchNext = () =>{
        
        dispatch(getUsersNextFilter());
    }

    return (

        <Fragment>
            {
                (searchUsers && searchUsers.users.length > 0 &&   Object.keys(searchUsers.endUser).length !== 0) 
                &&
                <div className="text-center containerSearchNext">
                    <button className="btn menu-btnLoadMore" onClick={handleSearchNext}>{strings.btnCargarMas}</button>
                </div>    
            
            }
        </Fragment>
        
    )
    
}

export default LoadMore;
