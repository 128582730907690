import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';

//Reducers
import { authReducer } from '../reducers/authReducer';
import { stateLogInReducer } from '../reducers/stateLogInReducer';
import { stateRegisterReducer } from '../reducers/stateRegisterReducer';
import { stateChangePasswordReducer } from '../reducers/stateChangePasswordReducer';
import { stateSettingsReducer } from '../reducers/stateSettingsReducer';
import { uiReducer } from '../reducers/uiReducer';
import { searchReducer } from '../reducers/searchReducer';
import { searchOnBoardingReducer } from '../reducers/searchOnBoardingReducer';

const initialState = {};
const middleware = [thunk];

const composeEnhancers = (typeof window !== 'undefined' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;

const reducers = combineReducers({
    auth: authReducer,
    stateLogInReducer,
    stateRegisterReducer,
    stateChangePasswordReducer,
    stateSettingsReducer,
    uiReducer,
    searchReducer,
    searchOnBoardingReducer
});

const enhancer = composeEnhancers(applyMiddleware(...middleware));

export const store = createStore(
    reducers,
    initialState,
    enhancer
);