import React, { useEffect, useState } from 'react';

//Redux
import { useDispatch } from  'react-redux';

//Components
import ServicesDesktop from '../components/Services/ServicesDesktop';
import ServicesTablet from '../components/Services/ServicesTablet';
import ServicesMobile from '../components/Services/ServicesMobile';

//Actions
import { showSideBar } from '../redux/actions/uiAction';

//Img
import GB_servicios from '../assets/img/GB_servicios.png';

const Servicios = (props) => {

    const dispatch = useDispatch();

    const [stateWindow, setWindow] = useState('Desktop');

    useEffect(() => {

        dispatch(showSideBar(false));

        handleResize();

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);

    }, [dispatch]);

    const handleResize = () =>{

        const { innerWidth } = window;// console.log("window", window);

        if(innerWidth >= 1600){
            setWindow('Desktop');
        }
        else if(innerWidth >= 1150 && innerWidth <= 1599){
            setWindow('Tablet');
        }
        else if(innerWidth <= 1149){
            setWindow('Mobile');
        }

    }

    const handleLogIn = () =>{
        props.history.push('/login');
    }

    return (

        <div className="wrapper">

            {
                stateWindow === 'Desktop' ? 

                    <ServicesDesktop 
                        handleLogIn={handleLogIn} 
                        imgHeader={GB_servicios}
                    />

                    : stateWindow === 'Tablet' ? 
                    <ServicesTablet 
                        handleLogIn={handleLogIn} 
                        imgHeader={GB_servicios}
                    />
                    :
                    <ServicesMobile 
                        handleLogIn={handleLogIn} 
                        imgHeader={GB_servicios}
                    />
                    
            }
        </div>

    )
    
}

export default Servicios;