import React from 'react';

//Img
import GB_foto_05 from '../../assets/img/QuienesSomos/GB_foto_05.png';

const QuienesSomosImg5 = () => {
    
    return (
          
        <div className="containerImageQuienesSomos2">
                
            <img className="imgQuienesSomos" src={GB_foto_05} alt="Img5"></img>

            <div className="container p-0 row m-0 containerTxtQuienesSomos">

                <div className="col-5 p-0 ml-auto">

                    <p className="titleTxtQuienesSomosImg5 text-left">Confenciona tus propios filtros para encontrar a tu candidato ideal.</p>
                    <p className="descrTxtQuienesSomosImg5 mb-0 text-left">Selecciona tus criterios de busqueda de nuestra extensa lista para que se acerque a tu candidato ideal, el perfil que busques puede ser tan variado como tu necesites, desde un recepcionista hasta una posicion gerencial.</p>
                    
                </div>

            </div>

        </div>

    )
    
}

export default QuienesSomosImg5;