import React from 'react';
import { Provider } from 'react-redux';

import './App.css';

//Store
import { store } from './redux/store/store';
import AppRouter from './routers/AppRouter';


function App() {

  //const isActive = true;


  return (

  
    <Provider store={store}>
        <AppRouter />
    </Provider>
  

  );
}

export default App;

