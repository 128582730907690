import { jobData }  from '../data/info';
import { habilidadesFortalezasCat } from '../data/strings';

export const getPlan = (roleID) => {

    let plan = '';

    console.log("roleID", roleID);

    if(roleID === 0){
        plan = 'Lite';
    }
    else if(roleID === 1){
        plan = 'Premium';
    }
    else if(roleID === 10){
        plan = 'Administrador';
    }
    else {
        plan = '-';
    }
   
    return plan;
}

export const getJob = (userPrepare) => {

    let job = {};

    //const { user } = props;

    //console.log("user", user);

    if(typeof(userPrepare) === 'undefined' || userPrepare === null){
        //console.log("Entro 1");
        job = 'No hay ninguno puesto seleccionado';
    }
    else if(typeof(userPrepare.puesto) === 'undefined'){
        //console.log("Entro 2");
        job = 'No hay ninguno puesto seleccionado';
    }
    else if (typeof(userPrepare.puestoString) !== 'undefined' && userPrepare.puestoString.length > 0){
        //console.log("Entro 3");
        job = userPrepare.puestoString;
    }
    else if(userPrepare.puesto !== -1){

        const catalogue = jobData['MX'];

        const index = catalogue[userPrepare.puesto];

        if(index !== -1){
            job = catalogue[userPrepare.puesto].descrID;
        }
        else{
            job = 'No hay ninguno puesto seleccionado'; 
        }

    }
   

    return job;
}


export const getAboutMe = (habilidadesSelected) => {

    if(typeof(habilidadesSelected) === 'undefined'){
        return ""
    }

    const habilidadesCat =  habilidadesFortalezasCat['MX'];
    let aboutMe = '';
    
    habilidadesSelected.map(habilidadSelected => 
        {
            //console.log("opSelected -> " + habilidadesCat[habilidadSelected].descrLarga);
            aboutMe += habilidadesCat[habilidadSelected].descrLarga + " ";

            return '';
        }
    );

    return aboutMe;
}

export const getAboutMeResume = (habilidadesSelected) => {

    if(typeof(habilidadesSelected) === 'undefined'){
        return ""
    }

    const habilidadesCat =  habilidadesFortalezasCat['MX'];
    let aboutMe = '';
    
    habilidadesSelected.map(habilidadSelected => 
        {
            //console.log("opSelected -> " + habilidadesCat[habilidadSelected].descrLarga);
            aboutMe += habilidadesCat[habilidadSelected].descrLarga + " ";

            return '';
        }
    );

    aboutMe = aboutMe.substr(0, 100) + "...";

    return aboutMe;
}