import React, { Fragment } from "react";

//Redux
import { useSelector } from 'react-redux';

//Router
import { useHistory } from 'react-router-dom';
import * as ROUTES from '../../data/routes';

//Hooks
import { useAccessModule } from '../../hooks/useAccessModule';

//Helpers
import { accessModulePrivateNavigation } from  '../../helpers/accessModule';

//Strings
import { SideBarStrings } from '../../data/strings';

//Images
import MENU_busqueda_up from '../../assets/img/SideBar/MENU_busqueda_up.png';
import MENU_filtro_up from '../../assets/img/SideBar/MENU_filtro_up.png';
import MENU_carpeta_up from '../../assets/img/SideBar/MENU_carpeta_up.png';
import MENU_chat_up from '../../assets/img/SideBar/MENU_chat_up.png';


const SideBar = () => {

    const strings = SideBarStrings['MX'];

    const [ validateAccess ] = useAccessModule(accessModulePrivateNavigation);
    const history = useHistory();

    const { sidebar, showSideBarSts } = useSelector( state => state.uiReducer );
    const { role } = useSelector(state => state.auth.userInfo.user);
    

    const handleClick = (e, route) =>{
        e.preventDefault();

        if(validateAccess() || route === ROUTES.REGISTER_ON_BOARDING){
            history.push(route);
        }
    }

    let container;
    if (sidebar) {
        container = (


        <div className={`${showSideBarSts ? 'menuDisplayed': ''}`}>        {/*wrapper */}
            
            <div className="sidebar-wrapper">
                              
                <div className="containerSideBar">

                    <ul className="sidebar-nav text-center">

                        <li className="nav-item" onClick={(e) => handleClick(e, ROUTES.REGISTER_ON_BOARDING)}>
                            <div 
                                className="nav-link sidebar-nav-link op-sidebar-busqueda" 
                                id="menu">

                                    <img className="imgSideBar" src={MENU_busqueda_up} alt="menu"></img>
                                    <p className="descrImgSideBar">{strings.btnBusqueda}</p>

                            </div>
                        </li>


                        {
                            role === 10 ? 
                            <Fragment>
                                <li className="nav-item" onClick={(e) => handleClick(e, ROUTES.SEARCH_FILTER)}>
                                    <div 
                                        className="nav-link sidebar-nav-link op-sidebar-filtro" 
                                        id="buscar">

                                            <img className="imgSideBar" src={MENU_filtro_up} alt="buscar"></img>
                                            <p className="descrImgSideBar">{strings.btnFiltro}</p>
                                    </div>
                                </li>

                                <li className="nav-item" onClick={(e) => handleClick(e, ROUTES.PERFILES_GUARDADOS)}>
                                    <div 
                                        className="nav-link sidebar-nav-link op-sidebar-carpetas" 
                                        id="contactar">

                                            <img className="imgSideBar" src={MENU_carpeta_up} alt="contactar"></img>
                                            <p className="descrImgSideBar">{strings.btnCarpetas}</p>
                                    </div>
                                </li>

                                <li className="nav-item" onClick={(e) => handleClick(e, ROUTES.CHAT)}>
                                    <div 
                                        className="nav-link sidebar-nav-link op-sidebar-chat"  
                                        id="perfilesguardados">
                                        <img className="imgSideBar" src={MENU_chat_up} alt="perfilesguardados"></img>
                                        <p className="descrImgSideBar">{strings.btnChat}</p>
                                    </div>
                                </li>
                            </Fragment>
                            :
                            <Fragment></Fragment>
                        }
                       
                    </ul>
                    
                </div>                        
                       
            </div>    
        </div>
        )
    }

    else{
        container = <Fragment></Fragment>
    }


    return (
        container
    );

}
 

export default SideBar;