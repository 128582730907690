import React from 'react';

//Images
import Star_Blue from '../../assets/img/Star_Blue.png';

const CalificaPerfil = (props) => {
    
    const { strings } = props;

    return (
        <div className="containerCalificarProfileCandidato">

            <div className="containerCalificarDescrProfileCandidato">
                <h4 className="m-0">{strings.title}</h4>
            </div>


            <div className="row m-0 containerInfoPrepareProfileCandidato">

                <div className="subcontainerInfoPrepareProfileCandidato">

                    <img className="cardStar" src={ Star_Blue } alt="Star"/>
                    <img className="cardStar" src={ Star_Blue } alt="Star"/>
                    <img className="cardStar" src={ Star_Blue } alt="Star"/>
                    <img className="cardStar" src={ Star_Blue } alt="Star"/>
                    <img className="cardStar" src={ Star_Blue } alt="Star"/>

                </div>

            </div>

        </div>
    )
    
}

export default CalificaPerfil;
