import React, { useState, useRef, memo } from 'react';

//Components
import ContainerScrollViewCandidatosRecientes from './ContainerScrollViewCandidatosRecientes';

//Images
import ArrowLeft from '../../assets/img/ArrowLeft.png';
import ArrowRight from '../../assets/img/ArrowRight.png';

//Info Example
//import { exampleUsersRecentsData } from '../../data/info';

const ContainerCandidatosRecientes = memo((props) => {

    //const { loadingCandidatosRecientes } = useSelector(state => state.uiReducer);
    //const { recentUsers } = useSelector(state => state.searchReducer);
    const { loadingCandidatosRecientes, recentUsers } = props;

    const ref = useRef(null);

    const [stateControlsRecientes, setStateControlsRecientes] = useState({
        left:false,
        right:true
    });

    const scroll = (scrollOffset) => {

       
        //Move Left
        if(scrollOffset < 0){
            if((ref.current.scrollLeft + scrollOffset) <= 0){
                setStateControlsRecientes({...stateControlsRecientes, left: false, right: true});
                //console.log("Scroll 1");
            }
            else{
                setStateControlsRecientes({...stateControlsRecientes, right: true});
                //console.log("Scroll 2");
            }
        }
      

        //Move Rigth

        if(scrollOffset > 0){

            if((ref.current.scrollLeft + scrollOffset) >= (ref.current.scrollWidth - ref.current.clientWidth)){//Check Max Scroll
                setStateControlsRecientes({...stateControlsRecientes, left: true, right: false});
                //console.log("Scroll 3");
            }
            else {//if((ref.current.scrollLeft + scrollOffset) >= 0){//
                setStateControlsRecientes({...stateControlsRecientes, left: true});
                //console.log("Scroll 4");
            }
        }

       

        /*if(ref.current.scrollLeft === 0 &&  scrollOffset > 0){ //Initial Scroll 
            setStateControlsRecientes({...stateControlsRecientes, left: true});
        }
        else if((ref.current.scrollLeft + scrollOffset) <= 0 && scrollOffset <= 0){ //Move Rigth
            setStateControlsRecientes({...stateControlsRecientes, left: false});
        }
        else if((ref.current.scrollLeft + scrollOffset) >= (ref.current.scrollWidth - ref.current.clientWidth) && scrollOffset > 0){//Check Max Scroll
            setStateControlsRecientes({...stateControlsRecientes, right: false});
        }
        else if((ref.current.scrollLeft + scrollOffset) >= 0 && scrollOffset < 0){//Move Left
            setStateControlsRecientes({...stateControlsRecientes, right: true});
        }*/
        
       
        
      

        ref.current.scrollTo({ behavior: 'smooth', left: ref.current.scrollLeft + scrollOffset });

        //console.log(" ref.current.scrollLeft",  (ref.current.scrollLeft));
        //console.log(" ref.current.scrollLeft",  (ref.current.scrollWidth - ref.current.clientWidth));//var maxScrollLeft = element.scrollWidth - element.clientWidth;
    };
    

    return (
        
        <div className="containerScroll">

            <div className="scrolling-wrapper inner-container" ref={ref}>

                <ContainerScrollViewCandidatosRecientes 
                    recentUsers={recentUsers} 
                    viewProfile={props.viewProfile}
                />

            </div>

            <div className="inner-controls">
             
                <img src={ArrowLeft} className={`carousel-control left ${stateControlsRecientes.left  ? 'active' : ''} `} onClick={() => scroll(-600)} alt="ArrowLeft"></img>
                <img src={ArrowRight} className={`carousel-control right ${stateControlsRecientes.right  ? 'active' : ''} `} onClick={() => scroll(600)} alt="ArrowRight"></img>
            </div>

            {
                loadingCandidatosRecientes && <div className="containerItemsCandidatoInfoLoading">

                                        </div>
            }

        </div>
        
    )

}, (prevProps, nextProps) => {

    if(prevProps.loadingCandidatosRecientes !== nextProps.loadingCandidatosRecientes ||
        prevProps.recentUsers !== nextProps.recentUsers){

        //console.log("Memo true");
        return false;
    }

    //console.log("Memo false");
    return true;
});

export default ContainerCandidatosRecientes;


//viewProfile={props.viewProfile}


/*

    {
        
        recentUsers && recentUsers.length > 0 ?
            recentUsers.map(user=> <CandidatoRecienteInfo key={uuid_v4()} user={user}/> )
        :
        exampleUsersRecentsData && 
        exampleUsersRecentsData.map(() => <CandidatoRecienteInfoSkeleton key={uuid_v4()} /> )
    }    





    { 
        <button className={`carousel-control left ${stateControlsRecientes.left  ? 'active' : ''} `} onClick={() => scroll(-600)}>Left</button> 
        <button className={`carousel-control right ${stateControlsRecientes.right  ? 'active' : ''} `} onClick={() => scroll(600)} >Right</button>
    }
*/