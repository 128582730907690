export const LANDING = '/';
export const SERVICES = '/servicios';
export const SIGN_IN = '/login';
export const RECOVER_PASSWORD = '/recoverPassword';
export const SIGN_UP = '/register';
export const PRIVACY = '/privacy';
export const TERMS = '/terms';


export const MENU = '/menu';

export const REGISTER_ON_BOARDING = '/registerOnBoarding';
export const SEARCH_FILTER = '/buscar';
export const PERFILES_GUARDADOS = '/perfilesguardados';
export const CHAT = '/chat';

export const UPGRADE_PLAN = '/upgradePlan';
export const PERFIL = '/perfil/:id';
export const SETTINGS = '/settings';
export const CHANGE_PASSWORD = '/changePassword';


export const CONTACT = '/contactar';