import React, { useEffect } from 'react';

//Redux
import { useDispatch, useSelector } from 'react-redux';

//Router
import * as ROUTES from '../data/routes';

//Components
import LoadMore from '../components/Menu/LoadMore';
import SearchBar from '../components/Menu/SearchBar/SearchBar';
import ContainerCandidatosRecientes from '../components/CandidatosRecientes/ContainerCandidatosRecientes';
import ContainerCandidatoInfo from '../components/CandidatoInfo/ContainerCandidatoInfo';
//import CustomCarousel from '../components/CustomCarousel';

//Actions
import { showSideBar } from '../redux/actions/uiAction';
import { setUserSelected, getUsersFilter } from '../redux/actions/searchAction';

//Hooks
import { useAccessModule } from '../hooks/useAccessModule';

//Helpers
import { accessModuleProfileUser } from '../helpers/accessModule';

//Strings
import { MenuStrings } from '../data/strings';

//import VisibilitySensor from 'react-visibility-sensor';

//import Carousel from 'react-multi-carousel';
//import 'react-multi-carousel/lib/styles.css';

const Menu = (props) => {
    
     //Language
     const strings = MenuStrings['MX'];

    const [ validateAccess ] = useAccessModule(accessModuleProfileUser);

    const dispatch = useDispatch();
    
    const { showSideBarSts, loadingCandidatosInfo, loadingCandidatosRecientes } = useSelector(state => state.uiReducer);

    const { recentUsers, searchUsers } = useSelector(state => state.searchReducer);
    

    useEffect(() => {

        dispatch(showSideBar(true));

    }, [dispatch]);
 

    const handleGetBusquedaNormal = (stateSearch) =>{

        //console.log("handleGetBusquedaNormal", stateSearch);

        dispatch(getUsersFilter(stateSearch));
    }

    const viewProfile = (user) => {

        
        if(validateAccess()){

            dispatch(setUserSelected(user));
            props.history.push(`/perfil/${user.user.uid}`);
        }
        else{
            props.history.push(ROUTES.UPGRADE_PLAN);
        }

     

        /*props.history.push(
        {
            pathname: `/perfil/${user.user.uid}`, 
            data:user
        });*/
    }

    const page = (

        <div className="mainContainerMenu">

            <SearchBar handleGetBusquedaNormal={handleGetBusquedaNormal}/>
    
            {
                //Carousel
                //<CustomCarousel />
            }
            
            <div className="subContainerMainMenu">

                <div className="col-12 containerRecientes">
                    <h1>{strings.titleRecent}</h1>
                </div>

                <ContainerCandidatosRecientes 
                    loadingCandidatosRecientes={loadingCandidatosRecientes} 
                    recentUsers={recentUsers}
                    viewProfile={viewProfile}
                />


                <div className="row m-0">
                    
                    <div className="col-12">
                        <h1>{strings.titleSearch}</h1>
                    </div>

                    <ContainerCandidatoInfo 
                        loadingCandidatosInfo={loadingCandidatosInfo} 
                        searchUsers={searchUsers} 
                        viewProfile={viewProfile}
                    />
                    
                </div>


                <LoadMore 
                    strings={strings}
                />
                                  

            </div>
           
        </div>   
    )

    return (

        <div className={`${showSideBarSts ? 'menuDisplayed': ''} wrapper`}>
            <div className="page-content-wrapper">
                <div className="container-fluid p-0">

                    { page }

                </div>
            </div>
        </div>
    )
    
}

export default Menu;