import React from 'react';

//Route
import { useHistory } from "react-router-dom";
import * as ROUTES from '../data/routes';

//Strings
import { Footer_PrivacyTerms_Strings } from '../data/strings';

//Data
import { socialData, storeData } from '../data/constant';

//Images
import ICO_FB from '../assets/img/SocialMedia/ICO_FB.png';
import ICO_IN from '../assets/img/SocialMedia/ICO_IN.png';
import ICO_TW from '../assets/img/SocialMedia/ICO_TW.png';
import appstore from '../assets/img/Store/appstore.png';
import playstore from '../assets/img/Store/playstore.png';

const Footer = () => {

     //Language
    const strings = Footer_PrivacyTerms_Strings['MX'];
    
    const history = useHistory();

    const handleClick = (e, route) =>{
        e.preventDefault();
        //console.log("handle Click");
        history.push(route);
    }

    return (
    
        <div className="containerFooter">

            <div className="container subContainerFooter">

                <div className="row">

                    <div className="col-12 pt-4 pb-2">
                        <div className="row">       
                            
                            <div className="containerSocialMedia col-6 p-0">
                                <a href={socialData.facebook} target="_blank" rel="noopener noreferrer">
                                            <img src={ICO_FB} className="imgSocialMediaFooter" alt="Facebook"></img>
                                        </a>
                                
                                <a href={socialData.instagram} target="_blank" rel="noopener noreferrer">
                                    <img src={ICO_IN} className="imgSocialMediaFooter" alt="Facebook"></img>
                                </a>

                                <a href={socialData.twitter} target="_blank" rel="noopener noreferrer">
                                    <img src={ICO_TW} className="imgSocialMediaFooter" alt="Facebook"></img>
                                </a>
                            </div>


                            <div className="col-6 p-0 container-stores">
                                <a href={storeData.appstore} target="_blank" rel="noopener noreferrer">
                                            <img src={appstore} className="imgAppStoreFooter mr-4" alt="App Store"></img>
                                        </a>
                                
                                <a href={storeData.playstore} target="_blank" rel="noopener noreferrer">
                                    <img src={playstore} className="imgPlayStoreFooter" alt="Play Store"></img>
                                </a>
                            </div>

                        </div>
                    </div>


                    <div className="col-12 pt-2 pb-4">
                        <div className="row m-0">     

                            <div className="mainContainerFooterPrivacyPolicyRight">
                            </div>

                            <div className="mainContainerFooterPrivacyPolicyLeft">
                                <div className="row container-footerPrivacyPolicy">     
                                        <p 
                                        className="footerPrivacyPolicy pr-4" 
                                        onClick={(e) => handleClick(e, ROUTES.PRIVACY)}>
                                            {strings.privacyPolicyLabel}
                                        </p>
                                        <p className="footerPrivacyPolicy"
                                        onClick={(e) => handleClick(e, ROUTES.TERMS)}>
                                            {strings.termsLabel}
                                        </p>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

        </div>
            
    )
}

export default Footer;