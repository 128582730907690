import React,  { useEffect } from 'react';

//Redux
import { useDispatch } from 'react-redux';

//Actions
import { showSideBar } from '../redux/actions/uiAction';


//Strings
import { RegisterOnBoardingStrings } from '../data/strings';

//Img
import ICO_onboarding from '../assets/img/ICO_onboarding.png';

const UpgradePlan = () => {

    //Language
    const strings = RegisterOnBoardingStrings['MX'];

    const dispatch = useDispatch();
  
    useEffect(() => {

        dispatch(showSideBar(false));

    }, [dispatch]);

    const page = (

        <div className="row m-0 justify-content-center">

            <div className="mainContainerOnBoardingColumns text-center">

                <img src={ICO_onboarding} className="imgOnBoarding" alt="Register On Boarding"></img>


                <div className="containerOnBoardingTitle m-auto">
                    <h1>Plan</h1>
                    <h1>Comprar nuevo plan</h1>
                </div>
                
            </div>
           
        </div>

    )

    return (

        <div className="wrapper">
        
            { page }

        </div>
    )
    
}

export default UpgradePlan;