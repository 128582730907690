import React from 'react';

//Img
import GB_foto_03 from '../../assets/img/QuienesSomos/GB_foto_03.png';

const QuienesSomosImg3 = () => {
    
    return (
          
        <div className="containerImageQuienesSomos2">
                
        <img className="imgQuienesSomos" src={GB_foto_03} alt="Img3"></img>


        <div className="container p-0 row m-0 containerTxtQuienesSomos">

            <div className="col-5 p-0 ml-auto">

                <p className="titleTxtQuienesSomosImg3 text-left">Observar como se desenvuelve en camara y escoge a tus favoritos.</p>
                <p className="descrTxtQuienesSomosImg3 mb-0 text-left">Si buscas en tus candidatos una buena conexion con clientes, buena comunicacion y presencia empatica, nuestro formato te permitira evaluar esos aspectos desde los primeros segundos de video.</p>
                
            </div>

        </div>

    </div>
    )
    
}

export default QuienesSomosImg3;