import { types } from '../types/types';

const initialState = 
{
    userInfo:{
        user:{
            name:'',
            lastname:'',
            company:'',
            email:'',
            job:'',
            country:'',
            city:'',
            phone:'',
            postCode:'',
            role: -1
        },
        userFiles:{},
        userPrepare:{}
    },

    isLoggedIn: false,
    checkingAuth: true
}

export const authReducer = ( state = initialState, action ) => {

    switch ( action.type ) {
        
        case types.checkingAuth:
            return{
                ...state,
                checkingAuth: false
            }

        case types.updateUserSettings:

            state.userInfo.user = { ...state.userInfo.user, ...action.payload }

            return{
                ...state  
            }

        case types.updateUserSettingsProfilePhoto:

            state.userInfo.userFiles.profilePhoto = action.payload.profilePhoto

            return{
                ...state  
            }

        case types.setIsLoggedIn:
            return{
                ...state,
                isLoggedIn:  action.payload.isLoggedIn
            }
     
        case types.authUser:
            return{
                ...state,
                userInfo:{
                    user: {...state.userInfo.user, ...action.payload.data.user},
                    userFiles: action.payload.data.userFiles,
                    userPrepare: action.payload.data.userPrepare
                },
                isLoggedIn: true
            }
 
        case types.logout:
            console.log("logout");
            return initialState;
    
        default:
            return state;
    }

}
