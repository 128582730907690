import React from 'react';

const ComingSoon = (props) => {

    const { img } = props;

    return (
        <div className="row m-0 justify-content-center">
            <div className="mainContainerComingSoon text-center">

                <img src={img} className="imgComingSoon" alt="Coming Soon"></img>

                <div className="containerComingSoonTitle m-auto">
                    <h1>Estamos construyendo cosas nuevas para ti.</h1>
                </div>
                
            
            </div>
        </div>
    )

}


export default ComingSoon;