import { types } from '../types/types';

export const setUp = (stateChangePassword, errorState) => {
    
    return ( dispatch ) => {

        console.log("errorState", errorState);

        dispatch(setStateChangePassword(stateChangePassword));

        dispatch(setErrorAuthChangePassword(errorState));

    }
}

export const setStateChangePassword = ( stateChangePassword ) => ({
    type: types.setStateChangePassword,
    payload:{
        stateChangePassword
    }     
});

export const setErrorAuthChangePassword = ( errorState) => ({
    
    type: types.errorAuthChangePassword,
    payload:{
        ...errorState
    }     
 
});

export const resetStateChangePassword = () => ({
    
    type: types.resetStateChangePassword,
    payload:{
        
    }     
 
});

export const CerrarStateChangePasswordAction = () => 
{
    return (dispatch) => {

        dispatch({
            type: types.logout,
            payload: {
                
            }
        });
           
    }
}