//Redux
import { useSelector } from 'react-redux';

//Router
import { useHistory } from 'react-router-dom';
import * as ROUTES from '../data/routes';

export const useAccessModule = (module) => {
  
    const history = useHistory();
    const { role } = useSelector(state => state.auth.userInfo.user);

    const validateAccess = () =>{

        if(!module(role)){

            history.push(ROUTES.UPGRADE_PLAN);

            return false;
        }

        return true;
    }

    return [ validateAccess ];
}