import React, { Fragment } from 'react';

//Redux
import { useSelector } from 'react-redux';

//Router
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import * as ROUTES from '../data/routes';

//NavBars
import Navbar from '../components/layout/Navbar';
import SideBar from '../components/layout/SideBar';
import FooterBar from '../components/layout/FooterBar';

//Components
import CheckAuth from '../components/CheckAuth';

//Pages

//Public
import LogIn from '../pages/LogIn';
import RecoverPassword from '../pages/RecoverPassword';
import Register from '../pages/Register';
import QuienesSomos from '../pages/QuienesSomos';
import Servicios from '../pages/Servicios';
import Privacy from '../pages/Privacy';
import Terms from '../pages/Terms';

//Private
import Menu from '../pages/Menu';
import PerfilesGuardados from '../pages/PerfilesGuardados';
import Buscar from '../pages/Buscar';
import Chat from '../pages/Chat';
import CandidatoProfile from '../pages/CandidatoProfile';
import RegisterOnBoarding from '../pages/RegisterOnBoarding';
import Settings from '../pages/Settings';
import ChangePassword from '../pages/ChangePassword';
import UpgradePlan from '../pages/UpgradePlan';

//Prueba
import PruebaLoading from '../pages/PruebaLoading';

//Route
import PublicRoute from '../routers/PublicRoute';
import PrivateRoute from '../routers/PrivateRoute';


const AppRouter = () => {

    const { isLoggedIn, checkingAuth } = useSelector((state) => state.auth);

    return (
        <Router>

            <CheckAuth />

            {checkingAuth ? 
            <div>
                <h1>Loading...</h1> 
            </div>
             :
                <Fragment>
                    <Navbar />
                    <SideBar /> 
                    <FooterBar />
                
                    <Switch>
                    
                        
                        {/*Public*/}
                        <PublicRoute exact path={ROUTES.LANDING} component={QuienesSomos} isAuthenticated={ isLoggedIn }/>
                        <PublicRoute path={ROUTES.SERVICES} component={Servicios} isAuthenticated={ isLoggedIn }/>
                        
                        <PublicRoute path={ROUTES.SIGN_IN} component={LogIn} isAuthenticated={ isLoggedIn }/>
                        <PublicRoute path={ROUTES.RECOVER_PASSWORD } component={RecoverPassword} isAuthenticated={ isLoggedIn }/>
                        <PublicRoute exact path={ROUTES.SIGN_UP} component={Register} isAuthenticated={ isLoggedIn }/>
        
        
                        <Route path={ROUTES.PRIVACY} component={Privacy} />
                        <Route path={ROUTES.TERMS} component={Terms} />
        
                        {/*Private*/}
                        <PrivateRoute path={ROUTES.REGISTER_ON_BOARDING} component={RegisterOnBoarding} isAuthenticated={ isLoggedIn } />
                        <PrivateRoute path={ROUTES.MENU} component={Menu} isAuthenticated={ isLoggedIn }/>
                        <PrivateRoute path={ROUTES.SEARCH_FILTER} component={Buscar} isAuthenticated={ isLoggedIn }/>
                        <PrivateRoute path={ROUTES.PERFILES_GUARDADOS} component={PerfilesGuardados} isAuthenticated={ isLoggedIn }/>
                        <PrivateRoute path={ROUTES.CHAT} component={Chat} isAuthenticated={ isLoggedIn }/>
                        
                        <Route path={ROUTES.PERFIL} component={CandidatoProfile} />
                        <PrivateRoute path={ROUTES.SETTINGS} component={Settings} isAuthenticated={ isLoggedIn }/>
                        <PrivateRoute path={ROUTES.CHANGE_PASSWORD} component={ChangePassword} isAuthenticated={ isLoggedIn }/>
                        <PrivateRoute path={ROUTES.UPGRADE_PLAN} component={UpgradePlan} isAuthenticated= { isLoggedIn} />
        

                        <Route path="/pruebaLoading" component={PruebaLoading} />

                        {/* 
                        <Route path="/pruebaVideo" component={PruebaVideoImagen} />
                        <Route path="/pruebas" component={Pruebas} />
                        <Route path="/pruebaTresColumnas" component={PruebaTresColumnas} />
                        */}
                
                        
                        {isLoggedIn ? <Redirect to="/" /> : <Redirect to="/menu" />}
                        
                    </Switch>
                </Fragment>
         
            }

        </Router>
        
    )

}

export default AppRouter;

