

export class FieldError {
    constructor() {
        this.status = false;
        this.msg = '';
    }
 
    setValues(status, msg){
        this.status = status;
        this.msg = msg;
    }

    toObject () {
        return { status: this.status, msg: this.msg }; 
    }
}

