import React from "react";
import { useSelector } from "react-redux";

//Components
import PublicNavbar from "./PublicNavbar";
import PrivateNavbar from "./PrivateNavbar";

const Navbar = () => {

  const { isLoggedIn } = useSelector((state) => state.auth);

  return (

      !isLoggedIn 

      ?
        <PublicNavbar />
      :
        <PrivateNavbar />

  );
};

export default Navbar;

