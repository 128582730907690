import React from 'react';

const RegisterDesktop = (props) => {
    
    const { stateForm, errorState } = props;
    const { handleInputChange, handleShowHide, handleCheckPrivacyPolicy, handleShowPrivacy, handleRegistrarse } = props;
    const { BTN_check_up, BTN_check_press } = props;
    const { countryData } = props;
    const { strings } = props;

    const { name, lastname, company, email, job, password, confirmPassword, country, privacyPolicy } = stateForm;


    return (

        <div className="containerRegisterColumns desktop">

            <h1 className="mb-5 titleRegister">{strings.titleRegister}</h1>

            <div className="row m-0">

                <div className="col-xl-4 p-xl-3">

                    <form className="">

                        <div className="form-group">
                            
                            <label htmlFor="name" className="col-form-label label-input">{strings.labelName}</label>
                            
                            <input 
                                type="text"
                                placeholder={strings.labelName}
                                name="name"
                                id="name"
                                autoComplete="off"
                                value={ name }
                                onChange={ handleInputChange }
                                className="form-control inputFieldRegisterBorderBottom"/>

                            {errorState.name.status && <div className="errorRegister text-left"><span>{errorState.name.msg}</span></div>}

                        </div>

                        <div className="form-group">

                            <label htmlFor="lastname" className="col-form-label label-input">{strings.labelLastName}</label>

                            <input 
                                type="text"
                                placeholder={strings.labelLastName}
                                name="lastname"
                                id="lastname"
                                autoComplete="off"
                                value={ lastname }
                                onChange={ handleInputChange }
                                className="form-control inputFieldRegisterBorderBottom"/>

                            {errorState.lastname.status && <div className="errorRegister text-left"><span>{errorState.lastname.msg}</span></div>}

                        </div>

                        <div className="form-group">

                            <label htmlFor="company" className="col-form-label label-input">{strings.labelCompany}</label>

                            <input 
                                type="text"
                                placeholder={strings.placeHolderCompany}
                                name="company"
                                id="company"
                                autoComplete="off"
                                value={ company }
                                onChange={ handleInputChange }
                                className="form-control inputFieldRegisterBorderBottom"/>

                            {errorState.company.status && <div className="errorRegister text-left"><span>{errorState.company.msg}</span></div>}

                        </div>

                        <div className="form-group">

                            <label htmlFor="email" className="col-form-label label-input">{strings.labelEmail}</label>

                            <input 
                                type="text"
                                placeholder={strings.placeHolderEmail}
                                name="email"
                                id="email"
                                autoComplete="off"
                                value={ email }
                                onChange={ handleInputChange }
                                className="form-control inputFieldRegisterBorderBottom"/>

                            {errorState.email.status && <div className="errorRegister text-left"><span>{errorState.email.msg}</span></div>}

                        </div>


                        <div className="form-group">

                            <label htmlFor="job" className="col-form-label label-input">{strings.labelJob}</label>

                            <input 
                                type="text"
                                placeholder={strings.labelJob}
                                name="job"
                                id="job"
                                autoComplete="off"
                                value={ job }
                                onChange={ handleInputChange }
                                className="form-control inputFieldRegisterBorderBottom"/>

                            {errorState.job.status && <div className="errorRegister text-left"><span>{errorState.job.msg}</span></div>}

                        </div>
                        
                    </form>

                </div>


                <div className="col-xl-4 p-xl-3">

                    <form className="">

        
                        <div className="form-group">

                            <label className="col-form-label pt-0 label-input-p">{strings.labelCountry}</label>

                            <select 
                                name="country" 
                                id="country" 
                                className="form-control inputFieldRegisterCity" 
                                value={ country }
                                onChange={ handleInputChange }>
                                
                                <option>{stateForm.defaultPais}</option>    

                                {countryData.map(country => <option key={country.countryCode}>{country.country}</option>) }                                  

                            </select>

                            {errorState.country.status && <div className="errorRegister text-left"><span>{errorState.country.msg}</span></div>}

                        </div>



                        <div className="form-group">
                            <label htmlFor="password" className="col-form-label pt-0 label-input-p">{strings.labelPassword}</label>

                            <div className="containerPassword">

                                <input 
                                    type="password"
                                    placeholder={strings.labelPassword}
                                    name="password"
                                    id="password"
                                    autoComplete="off"
                                    value={ password }
                                    onChange={ handleInputChange }
                                    className="form-control inputFieldRegister"/>

                                <span toggle="#input-pwd" id="toggle-password"  className="fa fa-fw fa-eye field-icon toggle-password" onClick={() => handleShowHide("password", "toggle-password")}></span>

                            </div>

                            {errorState.password.status && <div className="errorRegister text-left"><span>{errorState.password.msg}</span></div>}

                        </div>

                        <div className="form-group">
                            <label htmlFor="confirmPassword" className="col-form-label pt-0 label-input-p">{strings.labelConfirmPassword}</label>

                            <div className="containerPassword">

                                <input 
                                    type="password"
                                    placeholder={strings.labelPassword}
                                    name="confirmPassword"
                                    id="confirmPassword"
                                    autoComplete="off"
                                    value={ confirmPassword }
                                    onChange={ handleInputChange }
                                    className="form-control inputFieldRegister"/>

                                <span toggle="#input-pwd" id="toggle-confirm-password" className="fa fa-fw fa-eye field-icon toggle-password" onClick={() => handleShowHide("confirmPassword", "toggle-confirm-password")}></span>

                            </div>

                            {errorState.confirmPassword.status && <div className="errorRegister text-left"><span>{errorState.confirmPassword.msg}</span></div>}
                            
                        </div>

                        <p>{strings.labelDescrPassword}</p>

                    
                    </form>
                </div>


            

                <div className="col-xl-4 p-xl-3">

                    <form className="">

                        <div className="col-12 p-0 containerPrivacyPolicy">
                        
                            <div className="containerImgPrivacyPolicy" onClick={handleCheckPrivacyPolicy}>
                                <img src= {privacyPolicy ? BTN_check_press : BTN_check_up} className="imgCheckPrivacyPolicy" alt="Fortalezas"></img>
                            </div>
                
                
                            <div className="row m-0 containerTextPrivacyPolicy" onClick={handleShowPrivacy}>
                                <div className="subContainerTextPrivacyPolicy">
                                    <p className="mb-0">{strings.privacyPolicy} <span>{strings.privacyPolicy2}</span></p>
                                </div>
                            </div>
            
                        </div>
                        

                        <div className="form-group">
                            <button type="submit" className={`${!privacyPolicy ? 'disabled': ''} btn btn-primary btn-block btnRegister`} onClick={handleRegistrarse}>{strings.btnRegister}</button>
                        </div>

                        {errorState.serv.status && <div className="errorRegister text-left"><span>{errorState.serv.msg}</span></div>}
                        
                    </form>
                </div>


            
            </div>


        </div>
    )
    
}

export default RegisterDesktop;

/*


<div className="row m-0">
    <div className="subContainerTextPrivacyPolicy">
        
        <p className="text-center">{strings.terms}</p>
        
    </div>
</div>

*/