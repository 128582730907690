import React, { useEffect } from 'react';

//Redux
import { useDispatch, useSelector } from 'react-redux';

//Components
import ContainerPage from '../components/ContainerPage';
import ComingSoon from '../components/ComingSoon';

//Actions
import { showSideBar } from '../redux/actions/uiAction';

//Access Module
import { useAccessModule } from '../hooks/useAccessModule';

//Helpers
import { accessModulePrivateNavigation } from  '../helpers/accessModule';

//Img
import ICO_carpeta_construction from '../assets/img/ComingSoon/ICO_carpeta_construction.png';

const PerfilesGuardados = () => {
    
    const [ validateAccess ] = useAccessModule(accessModulePrivateNavigation);
    const dispatch = useDispatch();
    const { showSideBarSts } = useSelector(state => state.uiReducer);

    
    useEffect(() => {

        dispatch(showSideBar(true));

    }, [dispatch]);


    useEffect(() => {

        validateAccess();
         
    }, [validateAccess]);


    const page = (
        <ComingSoon img={ICO_carpeta_construction}/>
    )

    return (

        <ContainerPage showSideBarSts={showSideBarSts} children={page}>

        </ContainerPage>
    )
    
}

export default PerfilesGuardados;
