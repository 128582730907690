import React, {useState} from "react";

//Redux
import { useDispatch } from  'react-redux';

//Router
import { Link, NavLink } from "react-router-dom";
import * as ROUTES from '../../data/routes';

//Bootstrap
import { Navbar } from 'react-bootstrap';

//Actions
import { resetStateLogIn } from '../../redux/actions/stateLogInAction';
import { resetStateRegister } from '../../redux/actions/stateRegisterAction';

//Strings
import { PublicNavbarStrings } from '../../data/strings';

//Image
import icon from "../../assets/img/icon_.png";

const PublicNavbar = () => {
  
   //Language
    const strings = PublicNavbarStrings['MX'];

    const dispatch = useDispatch();

    const [navExpanded, setNavBarExpanded] = useState(false);
    

    const handleLogIn = (e) =>{

      e.preventDefault();

      //history.push(ROUTES.SIGN_IN);

      //console.log("handleLogIn");
      //document.getElementById("navbarNav").style.display = "none";   
      
      setNavBarExpanded(false);

      dispatch(resetStateLogIn());

    }

    const handleRegister = (e) =>{
      e.preventDefault();

      setNavBarExpanded(false);
      
      dispatch(resetStateRegister());

    }

    const setNavExpanded = (expanded) =>{
      setNavBarExpanded(expanded);
    }

    
    const handleCloseNavBar = () =>{
      setNavBarExpanded(false);
    }

    return (

      <Navbar
          sticky="top"
          id="navbar"
          bg="light"
          expand="lg"
          className="navbar navbar-expand-lg navbar-light bg-light fixed-top containerNavBar"
          collapseOnSelect={true}
          onToggle={setNavExpanded} 
          expanded={navExpanded}
      >
        <div className="container">

          <Link to={ROUTES.LANDING} className="navbar-brand mr-auto" onClick={handleCloseNavBar}>
              <img
                  src={icon}
                  width="140"
                  height="50"
                  className="d-inline-block align-top"
                  alt="logo"
                  loading="lazy"
                />
            </Link>



          <Navbar.Toggle aria-controls="basic-navbar-nav"/>
            
            <Navbar.Collapse id="basic-navbar-nav">

                <ul className="navbar-nav ml-auto">

                  <li className="nav-item" onClick={handleCloseNavBar}>
                    <NavLink exact to={ROUTES.SERVICES} className="nav-link">
                      {strings.btnServicios}
                    </NavLink>
                  </li>

                  <li className="nav-item" id="logIn" onClick={handleLogIn}>
                    <NavLink exact to={ROUTES.SIGN_IN} className="nav-link">
                    {strings.btnLogIn}
                    </NavLink>
                  </li>

                  <li className="nav-item" onClick={handleRegister}>
                    <NavLink exact to={ROUTES.SIGN_UP} className="nav-link nav-link-register" >
                      {strings.btnRegister}
                    </NavLink>
                  </li>

                </ul>

            </Navbar.Collapse>

        </div>

      </Navbar>
      
    )
    
}

export default PublicNavbar;


/*
<nav className="navbar navbar-expand-lg navbar-light bg-light fixed-top">

        <div className="container">
    
            <Link to={ROUTES.LANDING} className="navbar-brand mr-auto">
              <img
                  src={icon}
                  width="140"
                  height="50"
                  className="d-inline-block align-top"
                  alt="logo"
                  loading="lazy"
                />
            </Link>


            <button
                className="navbar-toggler"
                type="button"
                data-toggle="collapse"
                data-target="#navbarNav"
                aria-controls="navbarNav"
                aria-expanded={navExpanded}
                aria-label="Toggle navigation"
              >
                <span className="navbar-toggler-icon"></span>

            </button>
            
            <div className="collapse navbar-collapse" id="navbarNav">

              <ul className="navbar-nav ml-auto">

                <li className="nav-item">
                  <NavLink exact to={ROUTES.LANDING} className="nav-link">
                    Quienes Somos
                  </NavLink>
                </li>

                <li className="nav-item">
                  <NavLink exact to={ROUTES.SERVICES} className="nav-link">
                    Servicios
                  </NavLink>
                </li>

                <li className="nav-item" id="logIn" onClick={handleLogIn}>
                  <NavLink exact to={ROUTES.SIGN_IN} className="nav-link">
                    Log In
                  </NavLink>
                </li>

                <li className="nav-item" onClick={handleRegister}>
                  <NavLink exact to={ROUTES.SIGN_UP} className="nav-link nav-link-register" >
                    Registrate
                  </NavLink>
                </li>

              </ul>
              
            </div>
        </div>
    </nav>
*/