import { FieldError } from './FieldError';

export class SettingsModel {

    constructor(stringsAuth) {
        this.errorStatus = false;
        this.nameError = new FieldError ();
        this.lastnameError = new FieldError ();
        this.companyError = new FieldError ();
        this.jobError = new FieldError ();
        this.countryError = new FieldError ();
        this.stringsAuth = stringsAuth;
    }

    validate(stateForm){
        this.errorStatus = false;

        if(stateForm.name.length === 0){
            this.nameError.setValues(true, 'El campo es obligatorio'); 
            this.errorStatus = true;
        }

        if(stateForm.lastname.length === 0){
            this.lastnameError.setValues(true, 'El campo es obligatorio'); 
            this.errorStatus = true;
        }

        if(stateForm.company.length === 0){
            this.companyError.setValues(true, 'El campo es obligatorio'); 
            this.errorStatus = true;
        }

        if(stateForm.job.length === 0){
            this.jobError.setValues(true, 'El campo es obligatorio'); 
            this.errorStatus = true;
        }

        if(stateForm.country === stateForm.defaultPais || stateForm.country.length === 0){
            this.countryError.setValues(true, 'Debes seleccionar una opc');
            this.errorStatus = true;
        }
    }

    toObject(){
        return  {
            name: this.nameError.toObject(),
            lastname: this.lastnameError.toObject(),
            company: this.companyError.toObject(),
            job: this.jobError.toObject(),
            country: this.countryError.toObject(),
            serv:{
                status: false,
                msg: ''
            }
        }
    }
}
