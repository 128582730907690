import { types } from '../types/types';

const initialState = {
    
    userSelected: {
        user:{},
        userFiles:{},
        userPrepare:{}
    },
    recentUsers: [],
    searchUsers:{
        initialUser: {},
        endUser: {},
        users: []
    },
    searchFilter: "",
    searchText: ""
}

export const searchReducer = (state = initialState, action) => {

    switch(action.type){


        case types.userSelected:
            return{
                ...state,
                userSelected: action.payload.userSelected
            }

        case types.recentUsers:

            //state.recentUsers = action.payload.recentUsers;

            return{
                ...state,
                recentUsers: action.payload.recentUsers
            }

        case types.searchUsersFilter:
        
            return{
                ...state,
                searchUsers: {
                    initialUser: action.payload.data.initialUser,
                    endUser: action.payload.data.endUser,
                    users: action.payload.data.users
                }
            }

        case types.searchUsersNextFilter:
            return{
                ...state,
                searchUsers: {
                    initialUser: action.payload.data.initialUser,
                    endUser: action.payload.data.endUser,
                    users: action.payload.data.users
                }
            }

        case types.setFiltersSearch:
            return{
                ...state,
                searchText: action.payload.stateSearch.searchText,
                searchFilter: action.payload.stateSearch.searchFilter
            }

        case types.logout:
            return initialState;

        default:
            return state;

    }
}

//users: [...state.users, ...action.payload.data.users]