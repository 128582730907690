import React from 'react';

//Helpers
import { getJob } from '../../helpers/helpers';

//Images
import Star_Teal from '../../assets/img/Star_Teal.png';
import ic_profile from '../../assets/img/ic_profile.png';

 const HeaderCandidatoProfile = (props) => {
 
    const { data } = props;

    const handleOnError = ({target}) => {
        
        //userFiles.profilePhoto = null;
        target.src = ic_profile;//'some default image url';
    }

    return (

        <div className="containerHeaderProfileCandidato col-12 p-0">
                        
            <div className="mainContainerPictureProfileCandidato">

                    <div className="containerImgProfileCandidato">

                        <img 
                            src= { data.userFiles.profilePhoto || ic_profile } 
                            className="imgProfileCandidato" 
                            alt="Imagen Perfil"
                            onError={(e) => handleOnError(e)}></img>                        
                        
                    </div>


                    <div className="row m-0 containerInfoProfileCandidato">

                        <div className="subcontainerInfoProfileCandidato">

                            <h4 className="mb-0">{`${data.user.name} ${data.user.lastname}`}</h4>
                            <p className="mb-0">{getJob(data.userPrepare)}</p>
                            
                            <div className="text-left">

                                <img className="cardStar" alt="star" src={ Star_Teal }/>
                                <img className="cardStar" alt="star" src={ Star_Teal }/>
                                <img className="cardStar" alt="star" src={ Star_Teal }/>
                                <img className="cardStar" alt="star" src={ Star_Teal }/>
                                <img className="cardStar" alt="star" src={ Star_Teal }/>

                            </div>

                        </div>

                    </div>

            </div>

        </div>
    )
    
}

export default HeaderCandidatoProfile;