import React from 'react';

//Img
import GB_desk_home from '../../assets/img/QuienesSomos/GB_desk_home.png';

const QuienesSomosImg1 = () => {
    
    return (
        <div className="containerImageQuienesSomos">

            <img className="imgQuienesSomos" src={GB_desk_home} alt="Img2"></img>

            <div className="container p-0 row m-0 containerTxtQuienesSomos justify-content-center">

                <div className="col-10 p-0 text-center">

                    <p className="txtQuienesSomos">Somos una herramienta de busqueda de perfiles en formato de video.</p>
                    <p className="descrTxtQuienesSomos">En 1 minuto podras conocer las habilidades y fortalezas de cada candidato y observar su buen desempeño en camara.</p>
                    <button className="btn btnRegisterQuienesSomos">Registrate</button>

                </div>

            </div>

        </div>
    )
    
}

export default QuienesSomosImg1;