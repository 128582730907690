import React from 'react';

//Img
import ICO_experiencia from '../../assets/img/CandidatoProfile/ICO_experiencia.png';

const PrepareExp = (props) => {
    
    const { expAge } = props;
    const { strings } = props;

    return (
        <div className="col-12 p-0 mainContainerPrepareItemProfileCandidato">
                        
                        
        <div className="containerImgPrepareProfileCandidato">
            <img src= {ICO_experiencia} className="imgPrepareCandidatoInfo" alt="Experiencia"></img>
        </div>


        <div className="row m-0 containerInfoPrepareProfileCandidato">
            <div className="subcontainerInfoPrepareProfileCandidato">
                
                <h1>{strings.titleExp}</h1>   
                <p className="m-0">{expAge}</p>  
                
            </div>
        </div>

    </div>
    )
    
}

export default PrepareExp;
