import React from 'react';

//helpers
import { getAboutMe } from '../../helpers/helpers'

const AboutMe = (props) => {
    
    const { group_HabilidadesFortalezas } = props;
    const { strings } = props;

    //console.log("group_HabilidadesFortalezas",  group_HabilidadesFortalezas);

    const aboutMe = getAboutMe(group_HabilidadesFortalezas);

    return (

        <div className="containerSobreMiProfileCandidato">
            <h1>{strings.title}</h1>
            <p>{aboutMe}</p>
        </div>

    )
    
}

export default AboutMe;