import { types } from '../types/types';

export const uiSideBar  = (sidebar, displayed) =>{
    return (dispatch) => {
        dispatch(
            {
                type: types.sidebar,
                payload: {
                    sidebar,
                    displayed
                }
            }
        );
    }
};

export const showSideBar = (sidebar) => (dispatch) => {

    //console.log("showSideBar");

    dispatch(
        {
            type: types.showSideBar,
            payload: {
                sidebar
            }
        }
    );
    
};

export const ClickShowHideSideBar = (displayed) =>{

    //console.log("ClickShowHideSideBar");
    return (dispatch) => {

        dispatch(
            {
                type: types.clickShowSidebar,
                payload: {
                    displayed
                }
            }
        );
    }
};

export const showSearchBar = (searchBar) => {

    return (dispatch) => {
        dispatch({
            type: types.showSearchBar,
            payload:{
                searchBar
            }
        });
    }

}



export const setLoadingLogIn = (value) =>{
    
    return (dispatch) => {

        dispatch({
            type: types.setLoadingLogIn,
            payload:{
                loadingLogIn: value
            }
        });

    }
}


export const setLoadingRegister = (value) =>{
    
    return (dispatch) => {

        dispatch({
            type: types.setLoadingRegister,
            payload:{
                loadingRegister: value
            }
        });

    }
}

export const setLoadingChangePassword = (value) =>{
    return (dispatch) => {
        dispatch({
            type: types.setLoadingChangePassword,
            payload:{
                loadingChangePassword: value
            }
        });
    }
}

export const setLoadingCandidatosRecientes = (value) =>{
    
    return (dispatch) => {

        dispatch({
            type: types.setLoadingCandidatosRecientes,
            payload:{
                loadingCandidatosRecientes: value
            }
        });

    }
}

export const setLoadingCandidatosInfo = (value) =>{
    
    return (dispatch) => {

        dispatch({
            type: types.setLoadingCandidatosInfo,
            payload:{
                loadingCandidatosInfo: value
            }
        });
        
    }
}

export const setLoadingVideoOnCadidatoProfile = (value) =>{
    
    return (dispatch) => {

        dispatch({
            type: types.setLoadingVideoOnCadidatoProfile,
            payload:{
                loadingVideoOnCadidatoProfile: value
            }
        });
        
    }
}

export const CerrarSesionUI = () => 
{
    return (dispatch) => {

        dispatch({
            type: types.logout,
            payload: {
                
            }
        });
           
    }
}