//Redux
import { AuthStrings } from '../data/strings';

export const validFormatPassword = (pass) => {

    const stringsAuth = AuthStrings.MX;

    if(pass.length < 8){
        return { status: false, error: stringsAuth.FieldPasswordLenghtError };  
    }

    if (!pass.match(".*[A-Z].*")){
        //console.log("No Tiene Mayuscula");
        return { status: false, error: stringsAuth.FieldPasswordBadFormatError };  
    } 

    if (!pass.match(".*[a-z].*")) {
        //console.log("No Tiene Minuscula");
        return { status: false, error: stringsAuth.FieldPasswordBadFormatError };  
    }

    if (!pass.match(".*\\d.*")){
        //console.log("No Tiene numero");
        return { status: false, error: stringsAuth.FieldPasswordBadFormatError };  
    } 

    if (!pass.match(".*[-+_!@#$%^/&*., ?].*")){
        //console.log("No Tiene caracteres especiales");
        return { status: false, error: stringsAuth.FieldPasswordBadFormatError };  
    } 



    return { status: true, error: '' };
}