import React, { Fragment, useEffect, useRef, useState } from 'react';

//Redux
import { useDispatch, useSelector } from 'react-redux';

//Actions
import { showSideBar } from '../../redux/actions/uiAction';
import { setLoadingVideoOnCadidatoProfile } from '../../redux/actions/uiAction';

//Images
import ICO_sinvideo from '../../assets/img/ICO_sinvideo.png';
import ic_play from '../../assets/img/ic_play.png';
import ic_pause from '../../assets/img/ic_pause.png';

const VideoCandidatoProfile = (props) => {

    const { dataVideo } = props;

    const dispatch = useDispatch();

    let activeUserFiles = useRef( dataVideo );

    let playerVideo = useRef();

    const { loadingVideoOnCadidatoProfile } = useSelector(state => state.uiReducer);
    //const [stateLoading, setStateLoading] = useState({loading: true});

    const initialStateProgress = {progress:0};
    const [stateProgress, setStateProgress] = useState(initialStateProgress);

    const initialStateControls = {
        paused: true,
        muted: false,
        volume: 0.5};

    const [stateControls, setStateControls] = useState(initialStateControls);
        //timeElapsedValue: '00:00', 
        //duratioValue:'00:00',

    const initialStateTime = {
        step: 0.1,
        min: 0,
        currentTime: 0,
        formattedTime: '00:00'
    }

    const [stateTime, setStateTime] = useState(initialStateTime);


    const initialStateControlsFormattedLength = {
        length: null,
        formattedLength: '00:00'
    }

    const [stateControlsFormattedLength, setStateControlsFormattedLength] = useState(initialStateControlsFormattedLength);

    const initialIntervalID = {intervalID: 0};
    const [stateIntervalID, setIntervalID] = useState(initialIntervalID);


    useEffect(() => {

        dispatch(showSideBar(true));

        window.scrollTo(0, 0);

        playerVideo.load();
    
    }, [dispatch]);



     useEffect(() => {

        //console.log("----------------->userFiles", userFiles);

        if (activeUserFiles.current.userFiles.videopersonal !== dataVideo.userFiles.videopersonal || 
            activeUserFiles.current.userFiles.videoformal !== dataVideo.userFiles.videoformal || 
            activeUserFiles.current.videoFormal !== dataVideo.videoFormal) {

            console.log("Updaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaate Video", dataVideo);

            const setUp = () => {

                console.log("----------SetUp------------");
                clearInterval(stateIntervalID.intervalID);
        
                setStateProgress(initialStateProgress);
                setStateControls(initialStateControls);
                setStateTime(initialStateTime);
                setStateControlsFormattedLength(initialStateControlsFormattedLength);
                setIntervalID(initialIntervalID);
        
        
                activeUserFiles.current = dataVideo;
        
                playerVideo.pause();
                playerVideo.load();
               
            }
    
            setUp();
            
        }
    
    }, [dataVideo, stateIntervalID, initialStateProgress, 
        initialStateControls, initialStateTime, 
        initialStateControlsFormattedLength, initialIntervalID]);

    const play = () => {

        if(loadingVideoOnCadidatoProfile){//if(stateLoading.loading){
            return;
        }

        //const play_pause = document.querySelector(".play_pause");

        /*
        setStateControls({    
            ...stateControls,
            paused: !stateControls.paused
        });*/

        //console.log("ClickPlay");

        const paused = !stateControls.paused
    
        if (paused === false) {

            console.log("play", stateControls.paused);

            /*setIntervalID({
                intervalID: setInterval(() => currentTime(), 1000)
            });  //10
            */
          
            playerVideo.play();

            /*setStateControls({
                ...stateControls,
                paused: false
            });*/

        } else {

            /*console.log("pause", stateControls.paused);
            //console.log("Borrar", intervalID);

            clearInterval(stateIntervalID.intervalID);*/

            playerVideo.pause();

            /*setStateControls({
                ...stateControls,
                paused: true
            });*/
            
        }
       
    }

    const duration = () => {

        let dur = document.getElementById("v").duration;//playerVideo.duration;//
        
        console.log("duration func", dur);

        const time = formatTime(Math.round(dur));//let formattedLength = formatTime(dur);//.toHHMMSS();

        dur = dur.toFixed();
        
        console.log("duration func", time);

        setStateControlsFormattedLength(
            {
                ...stateControlsFormattedLength,
                length: dur,
                formattedLength: `${time.minutes}:${time.seconds}`
            }
        )
      

        return dur;
    }


    const currentTime = () => {

        let cur = document.getElementById("v").currentTime;

        const time = formatTime(Math.round(cur));//let cur = document.getElementById("v").currentTime;//document.getElementById("v").currentTime;//
        
        cur = cur.toFixed();

        let progress = (cur / stateControlsFormattedLength.length) * 100;

        //console.log("cur", cur);
        //console.log("progress", progress);

        setStateProgress({progress});

    
        setStateTime({
            ...stateTime,
            currentTime: cur,
            formattedTime: `${time.minutes}:${time.seconds}`
        });


        if (parseInt(cur) === parseInt(stateTime.length)) {

          setStateControls(
              { 
                ...stateControls, 
                paused: true 
            }); 
        }

        return cur;
    }

    const customTime = () => {

        const time_range = document.querySelector(".slider");//time_range
        document.getElementById("v").currentTime = time_range.value;
        //const fill = document.getElementsByClassName("fill")//document.querySelector(".bar .fill");//time_range

        //width: stateProgress.progress + '%'

        //console.log("customTime", fill.width);
        let progress = (time_range.value / stateControlsFormattedLength.length) * 100;
        setStateProgress({progress });
  
    
        setStateTime({
            ...stateTime,
          currentTime: time_range.value
        });
    }

    // formatTime takes a time length in seconds and returns the time in
    // minutes and seconds
    function formatTime(timeInSeconds) {
        const result = new Date(timeInSeconds * 1000).toISOString().substr(11, 8);
    
        return {
            minutes: result.substr(3, 2),
            seconds: result.substr(6, 2)
        };
    };

    const handleVideoLoaded = () => {
        
        duration();
        dispatch(setLoadingVideoOnCadidatoProfile(false));
    }
   
    //var formal = true;
    const handleOnEded = () =>{

        console.log("handleOnEded");

        setStateControls({
            ...stateControls,
            paused: true
        });

    }


    const handleOnPlay = () =>{

        //console.log("OnPlay");

        //console.log("play", stateControls.paused);

        setIntervalID({
            intervalID: setInterval(() => currentTime(), 1000)
        });  //10
        
        
        //playerVideo.play();

        setStateControls({
            ...stateControls,
            paused: false
        });
   
    }

    const handleOnPause = () =>{

        //console.log("handleOnPause");

        //console.log("pause", stateControls.paused);
        //console.log("Borrar", intervalID);

        clearInterval(stateIntervalID.intervalID);

        //playerVideo.pause();

        setStateControls({
            ...stateControls,
            paused: true
        });
    
    }


    const validarVideo = () => {
        if(dataVideo.videoFormal){
            if(typeof(dataVideo.userFiles.videoformal) === 'undefined' || dataVideo.userFiles.videoformal.length === 0){
                return true
            }
        }
        else{
            if(typeof(dataVideo.userFiles.videopersonal) === 'undefined' || dataVideo.userFiles.videopersonal.length === 0){
                return true
            }
        }

        return false
         
    };

    return (    

        <Fragment>

            {
                /* Video */
                 /*{loadingVideoOnCadidatoProfile && <img src={ICO_sinvideo} className="previewVideoProfile" alt="Img Loading"></img>} */
            }

            <div className="col-12 p-0 text-center">

                <div className="wrapperVideoProfile m-auto">
                   
                    { validarVideo()  && <img src={ICO_sinvideo} className="previewVideoProfile" alt="Img Loading"></img>} 


                    <video className={`${!loadingVideoOnCadidatoProfile ? 'enable' : ''} `} 
                            id="v" 
                            //controls 
                            disablePictureInPicture 
                            controlsList="nodownload" 
                            ref={videoRef => playerVideo = videoRef} 
                            preload="metadata" 
                            onLoadedData={handleVideoLoaded} 
                            onPlay={() => handleOnPlay()}
                            onPause={()=> handleOnPause()}
                            onEnded={()=> handleOnEded()}
                            >
                                

                        <source src={ dataVideo.videoFormal ? dataVideo.userFiles.videoformal : dataVideo.userFiles.videopersonal } type="video/MP4"/>

                    </video>
                </div>

            </div>



            {
                /* Controls */
            }

            <div className="col-12 p-0">

                <div className="containerControlsVideoCandidatoProfile mx-auto">


                    <div className="row m-0">

                        <div className="leftControlsVideoCandidatoProfile text-center">
                            <img src={stateControls.paused ? ic_play: ic_pause} className="btnPlaySlider" onClick={play} alt="Play Button"></img>
                        </div>
                        
                        
                        <div className="containerSliderVideoCandidatoProfile my-auto">

                            
                            <span className="bar"><span className="fill" style={{width: stateProgress.progress + '%'}}></span></span>

                            <input 
                            type="range" 
                            className="slider" 
                            onChange={customTime}
                            value={stateTime.currentTime}
                            step={stateTime.step}
                            min={stateTime.min}
                            max={stateControlsFormattedLength.length}
                            />
                            
                        </div>

                    </div>
                    
                    
                </div>

            </div>


        </Fragment>
    
    )
    
}


export default VideoCandidatoProfile;