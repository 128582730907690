import React, { useState } from 'react';

//Redux
import { useDispatch, useSelector } from  'react-redux';

//Model
import { ChangePasswordModel } from '../models/ChangePasswordModel';

//Actions
import { setUp } from '../redux/actions/stateChangePassword';
import { changePassword } from '../redux/actions/auth';

//Strings
import { ChangePasswordStrings, AuthStrings } from '../data/strings';

const ChangePassword = (props) => {

    const dispatch = useDispatch();
    const { stateChangePassword, errorState } = useSelector(state => state.stateChangePasswordReducer);
    const { loadingChangePassword } = useSelector(state => state.uiReducer);

    const [stateForm, setState] = useState(stateChangePassword);

    const { currentPassword, newPassword, confirmNewPassword } = stateForm;

    //Language
    const strings = ChangePasswordStrings['MX'];
   
    //Models
    let changePasswordModel = new ChangePasswordModel(AuthStrings['MX']); //const stringsAuth = AuthStrings['MX'];

    const handleInputChange = ({target}) => {

        setState({
            ...stateForm,
            [target.name]: target.value
        });
    };

    const handleChangePassword = (e) => {

        e.preventDefault();

        console.log("handleChangePassword");

        changePasswordModel.validate(stateForm);

        //Local
        if(changePasswordModel.errorStatus){
            dispatch(setUp(stateForm, changePasswordModel.toObject()));
            return;
        }

        //Server
        dispatch(changePassword(currentPassword, newPassword, props.history));

    }

    const handleShowHide = (id, toggleID) =>{
          
        var x = document.getElementById(id);
        var element = null;

        if (x.type === "password") {

          x.type = "text";

          element = document.getElementById(toggleID);
          element.className = "fa fa-fw fa-eye field-icon toggle-password fa-eye-slash";

        } else {

          x.type = "password";

          element = document.getElementById(toggleID);
          element.className = "fa fa-fw fa-eye field-icon toggle-password";
        }
    } 

    const handleKeyDown = (e) => {

        if (e.key === 'Enter') {
            handleChangePassword(e);
        }
    }
    
    return (
    
        <div className="mainContainerLogIn">

            <div className="container">
                
                <div className="row m-0 justify-content-center">

                    <div className="mainContainerLogInColumns">

                        <div className="text-center">

                            <form className="">

                                <div className="form-group">
                                    
                                    <div className="containerPassword">
                                        
                                        <input 
                                        type="password"
                                        placeholder={strings.labelCurrentPassword}
                                        name="currentPassword"
                                        id="currentPassword"
                                        autoComplete="off"
                                        value={currentPassword}
                                        onChange={handleInputChange}
                                        onKeyDown={handleKeyDown}
                                        className="form-control inputFieldLogIn"/>

                                        <span toggle="#input-pwd" id="toggle-currentPassword" className="fa fa-fw fa-eye field-icon toggle-password" onClick={() => handleShowHide("currentPassword", "toggle-currentPassword")}></span>
                                    </div>

                                    {errorState.currentPassword.status && <div className="errorSignUp text-left"><span>{errorState.currentPassword.msg}</span></div>}
    
                                </div>

                                <div className="form-group">
                                        
                                    <div className="containerPassword">
                                        <input 
                                        type="password"
                                        placeholder={strings.labelNewPassword}
                                        name="newPassword"
                                        id="newPassword"
                                        autoComplete="off"
                                        value={newPassword}
                                        onKeyDown={handleKeyDown}
                                        onChange={handleInputChange}
                                        className="form-control inputFieldLogIn"/>

                                        <span toggle="#input-pwd" id="toggle-newPassword" className="fa fa-fw fa-eye field-icon toggle-password" onClick={() => handleShowHide("newPassword", "toggle-newPassword")}></span>
                                    </div>

                                    {errorState.newPassword.status && <div className="errorSignUp text-left"><span>{errorState.newPassword.msg}</span></div>}

                                </div>

                                <div className="form-group">
                                    
                                    <div className="containerPassword">
                                        <input 
                                        type="password"
                                        placeholder={strings.labelConfirmNewPassword}
                                        name="confirmNewPassword"
                                        id="confirmNewPassword"
                                        autoComplete="off"
                                        value={confirmNewPassword}
                                        onKeyDown={handleKeyDown}
                                        onChange={handleInputChange}
                                        className="form-control inputFieldLogIn"/>

                                        <span toggle="#input-pwd" id="toggle-confirmNewPassword" className="fa fa-fw fa-eye field-icon toggle-password" onClick={() => handleShowHide("confirmNewPassword", "toggle-confirmNewPassword")}></span>
                                    </div>

                                    {errorState.confirmNewPassword.status && <div className="errorSignUp text-left"><span>{errorState.confirmNewPassword.msg}</span></div>}

                                </div>
                                

                                <div className="form-group">
                                    
                                    <button 
                                        type="button" 
                                        className="btn btn-primary btn-block btnLogIn" 
                                        onClick={handleChangePassword}
                                        onKeyDown={handleKeyDown}
                                    >
                                        {strings.btnChangePassword}
                                    </button>

                                    {errorState.serv.status && <div className="errorSignUp text-left"><span>{errorState.serv.msg}</span></div>}

                                </div>

                            </form>

                        
                        </div>

                    </div>

                </div>      

            </div>

            {
                loadingChangePassword && <div className="containerRegisterLoading">
                                    </div>
            }

        </div>
            
    )
}

export default ChangePassword;