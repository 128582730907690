//Firebase
import { firebase, db, storage } from '../../firebase/firebase-config';

//Route
import * as ROUTES from '../../data/routes'; 

//Axios
import axios from 'axios';

//Actions
import { setStateLogIn, setErrorAuth, resetStateLogIn, CerrarStateLogInAction } from '../actions/stateLogInAction';
import { setStateRegister, setErrorAuthRegister, resetStateRegister } from '../actions/stateRegisterAction';
import { setErrorAuthChangePassword, resetStateChangePassword } from '../actions/stateChangePassword';
import { getSearchOnBoarding, CerrarSesionOnBoarding } from '../actions/searchOnBoardingAction';
import { CerrarSesionSearch } from '../actions/searchAction';
import { setLoadingLogIn, setLoadingRegister, setLoadingChangePassword, CerrarSesionUI } from '../actions/uiAction';

//Strings
import { LogInAuthStrings, RegisterAuthStrings } from '../../data/strings';

import { FirebaseKey } from "../../data/constant";

import { types } from '../types/types';

export const startRegisterWithEmailPasswordName = ( email, password, stateForm) => {
    
    return ( dispatch ) => {

        dispatch(setStateRegister(stateForm));
        dispatch(resetStateRegister());
        dispatch(setLoadingRegister(true));

        let userInfo = {
            name: stateForm.name,
            lastname: stateForm.lastname,
            company: stateForm.company,
            email: stateForm.email,
            job: stateForm.job,
            country: stateForm.country,
            role: 0
        }

        firebase.auth().createUserWithEmailAndPassword( email, password )
        .then(({user}) => {

            sendEmailVerification(user);

            //console.log("Registro", user.uid);

            db.collection(FirebaseKey.users_recruiters).doc(`${user.uid}`).set(userInfo)
            .then(() => {
                
                dispatch(setLoadingRegister(false));

                console.log("User successfully written!", userInfo);
                //history.push(`${ROUTES.SIGN_IN}`);
                
            })
            .catch(function(error) {
                console.error("Error(Register) writing document: ", error);

                dispatch(setLoadingRegister(false));
            });

            //firebase.auth().signOut();
        
        })
        .catch( (e) => {

            dispatch(setLoadingRegister(false));
            console.log("Error Register ", e);
            let stringsAuth = LogInAuthStrings['MX'];

            if(e.code === "auth/email-already-in-use"){
                //console.log("Mostrar mensaje que ya esta en uso");
                dispatch(setErrorAuthRegister({serv:{status:true, msg: stringsAuth.EmailAlreadyInUse}}));
            }
            else if(e.code === "auth/invalid-email"){
                dispatch(setErrorAuthRegister({serv:{status:true, msg: stringsAuth.InvalidEmail}}));
            }
            
        });

    }
}

export const startLogInWithEmailPassword = (email, password ) => {

    return async( dispatch ) => {

        dispatch(setStateLogIn({email, password}));
        //dispatch(setErrorAuth({serv:{status:false, msg:""}, clickLogIn: true}));
        dispatch(resetStateLogIn());
        dispatch(setLoadingLogIn(true));

        //SigOut before try to logIn
        firebase.auth().signOut();
        

        firebase.auth().signInWithEmailAndPassword(email, password)
        .then( async({ user }) => {


            console.log("signInWithEmailAndPassword");

            /*
            dispatch(setLoadingLogIn(false));

            if(user.emailVerified){ //This will return true or false
               
              console.log("LogIn Completado", user.uid);
              
              dispatch({
                  type: types.setIsLoggedIn,
                  payload: {
                      isLoggedIn: true
                  }
              });


            }else{
               //console.log('email not verified')
               dispatch(setErrorAuth({serv:{status:true, msg:"Email no verificado"}}));

               firebase.auth().signOut();
            }*/
            
        })
        .catch( (e) => {
            console.log(e);

            dispatch(setLoadingLogIn(false));

            let stringsAuth = RegisterAuthStrings['MX'];

            if(e.code === 'auth/wrong-password' || e.code === 'auth/user-not-found'){
                //console.log("auth/wrong-password");
                dispatch(setErrorAuth({serv:{status:true, msg: stringsAuth.WrongPassword}}));
            }
            else if(e.code === 'auth/invalid-email'){
                //console.log("auth/invalid-email");
                dispatch(setErrorAuth({serv:{status:true, msg: stringsAuth.InvalidEmail}}));
            }
            else if(e.code === 'auth/too-many-requests'){
                //console.log("auth/too-many-requests");
                dispatch(setErrorAuth({serv:{status:true, msg: stringsAuth.TooManyRequests}}));
            }
        });

    }
}

export const isRecluter = (uid) => {

    return new Promise(function (resolve, reject) {

   
        axios({
            method: 'post',     //put
            url: `${process.env.REACT_APP_FIREBASE_FUNCTIONS_URL}/checkLogIn`, 
            data: {
                uid
            }
        })
        .then(res => {
    
    
            console.log("Resultado", res.data.status);
             
            resolve({
                res: res.data.status,
                error:''
            });
    
    
        }).catch(error =>{

            //console.log("err", error);

            resolve({
                res: '',
                error
            });
            
        });
    });

}

export const pruebaLogIn = (user, history) => {

    return async(dispatch) =>{

        console.log("pruebaLogIn");

        if(user.emailVerified){

            const resolve = await isRecluter(user.uid);
        
            //Check if its recluter
            if(resolve.res){

                console.log("LogIn Completado", user.uid);

                dispatch(setLoadingLogIn(false));

                dispatch({
                    type: types.setIsLoggedIn,
                    payload: {
                        isLoggedIn: true
                    }
                });


                dispatch(login(user.uid, history));

                //dispatch(setChecking(false));
            }
            else{

                if(resolve.error !== ''){
                    //console.log("No disponible para candidatos");
                    dispatch(setLoadingLogIn(false));

                    dispatch(setErrorAuth({serv:{status:true, msg:LogInAuthStrings['MX'].NotAvailableCandidate}}));

                    firebase.auth().signOut();
                }

                dispatch(setChecking(false));
      
            }
            
        }
        else{

            //Email not verified

            dispatch(setLoadingLogIn(false));

            dispatch(setErrorAuth({serv:{status:true, msg:LogInAuthStrings['MX'].EmailNotVerified}}));

            dispatch(setChecking(false));
        }  

    }
}

export const login = (uid, history) => {

    return (dispatch) => {

        console.log("login");

        axios({
            method: 'post',     //put
            url: `${process.env.REACT_APP_FIREBASE_FUNCTIONS_URL}/getLoginInfo`, 
            data: {
                uid
            }
        })
        .then(res => {

            dispatch(resultLogIn(res, history));
          
        }).catch(err =>{
            console.log("err", err);
            dispatch(setChecking(false));
        });

    }
}

export const resultLogIn = (res, history) => {
    
    return async(dispatch) => {

        console.log("login Finish");

        dispatch({
            type: types.authUser,
            payload: {
                data: res.data
            }
        });

        await dispatch(getSearchOnBoarding(history));
        dispatch(setChecking(false));

    }
}


export const setChecking = () => {
    
    return(dispatch) => {

        dispatch({
            type: types.checkingAuth,
            payload: {
                checkingAuth: false
            }
        });

    }
}


export const updateSettings = (userInfo) => {

    return (dispatch, getState) =>{

        const { uid } = getState().auth.userInfo.user;

       //Get user info
       db.collection(FirebaseKey.users_recruiters).doc(`${uid}`).update(userInfo)
       .then(() => {
           
           console.log("users_recruiters successfully!");
             
            dispatch(
            {
                type: types.updateUserSettings,
                payload: {
                    ...userInfo
                }
            });

       })
       .catch(function(error) {
           console.error("Error(users_recruiters) writing document: ", error);
       });
    }
    
}

export const updateProfilePhotoSettings = (file) => {

    return async(dispatch, getState) =>{

        const { uid } = getState().auth.userInfo.user;

        const nombreGenerico = 'profile.png';//item.previousState.imgs[0];

        const res = await dispatch(uploadFile(uid, file, nombreGenerico));
        const url = res.downloadURL
        console.log("resultado del archivo", res); 
        
        dispatch(
        {
            type: types.updateUserSettingsProfilePhoto,
            payload: {
                profilePhoto:url
            }
        });
    
        if(res.error.trim().length === 0){
    
            /*item.info = { 
                ...item.info, 
                urls:[url],
                files:[{
                        file:null,
                        fileSource:''//This is the the fileURL
                    }] 

            }*/

            //const respuesta = await dispatch(uploadInfo(item));       
            //console.log("respuesta", respuesta); 

             //Get user info
            db.collection("users_files").doc(`${uid}`).update({profilePhoto:url})
            .then(() => {
                
                console.log("users_files successfully!");
                    
                  /*
                dispatch(
                {
                    type: types.updateUserSettingsProfilePhoto,
                    payload: {
                        profilePhoto:url
                    }
                });*/
             

            })
            .catch((error) => {


                //console.error("Error(users_files) writing document: ", error);


                db.collection("users_files").doc(`${uid}`).set({profilePhoto:url})
                .then(() => {
                    
                    console.log("users_files successfully!");
                        
                    /*
                    dispatch(
                    {
                        type: types.updateUserSettingsProfilePhoto,
                        payload: {
                            profilePhoto:url
                        }
                    });*/
    
                })
                .catch((error) => {
                    console.error("Error(users_files) writing document: ", error);
                });


            });
        }
    }
    
}

export const uploadFile = (uid, file, nombreGenerico) =>{

    return (dispatch) =>{

        return new Promise(function (resolve, reject) {
           
            const fileName = nombreGenerico;//`${Date.now()}.png`;//'nombreGenerico.png';

            console.log("uploadFile Action", file);

            // Create a reference to 'mountains.jpg'
            var uploadTask = storage.ref(`/users_document/${uid}/${fileName}`).put(file);

            // Listen for state changes, errors, and completion of the upload.
            uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED, // or 'state_changed'
             (snapshot) => {
                    // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                    var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                    console.log('Upload is ' + progress + '% done');
                    switch (snapshot.state) {
                        case firebase.storage.TaskState.PAUSED: // or 'paused'
                            console.log('Upload is paused');
                            break;
                        case firebase.storage.TaskState.RUNNING: // or 'running'
                            console.log('Upload is running');
                            break;
                        default:
                            break;
                    }
            }, (error) => {
                /*
                // A full list of error codes is available at
                // https://firebase.google.com/docs/storage/web/handle-errors
                switch (error.code) {
                case 'storage/unauthorized':
                    // User doesn't have permission to access the object
                    break;

                case 'storage/canceled':
                    // User canceled the upload
                    break;

                ...

                case 'storage/unknown':
                    // Unknown error occurred, inspect error.serverResponse
                    break;
                }*/

                resolve({
                    error,
                    downloadURL:''
                });
            }, () => {
                // Upload completed successfully, now we can get the download URL
                uploadTask.snapshot.ref.getDownloadURL().then(function(downloadURL) {
                    //console.log('File available at', downloadURL);
                    resolve({
                        error: '',
                        downloadURL
                    });
                });
            });
        });

    }
}

export const sendEmailVerification = (user) => {

    
    user.sendEmailVerification().then(function() {

        console.log("Email Send");

    }).catch(function(error) {

        console.log("Error email send", error);
    });
}


export const recoverPassword = (email) => {

    return (dispatch) => {

        console.log("recoverPassword");

        firebase.auth().sendPasswordResetEmail(email).then(function() {
          // Email sent.
          console.log("Email sent");
        }).catch(function(error) {
          // An error happened.
          console.log("error", error);
        });

    }
}

/*
export const reauthenticate = () => {

    return(dispatch)  => {

        var credential = firebase.auth().AuthCredential;

        // Prompt the user to re-provide their sign-in credentials
        firebase.auth().currentUser.reauthenticateWithCredential(credential).then(function() {
            // User re-authenticated.
            console.log("User re-authenticated.");
        }).catch(function(error) {
            // An error happened.
            console.log("Error", error);
        });
    }
}*/

export const changePassword = (currentPassword, newPassword, history) => {
    
    return(dispatch) => {
    
        dispatch(resetStateChangePassword());
        dispatch(setLoadingChangePassword(true));

        const user = firebase.auth().currentUser;
        const credential = firebase.auth.EmailAuthProvider.credential(
            user.email, 
            currentPassword
        );
        
        user.reauthenticateWithCredential(credential).then(function() {
            // User re-authenticated.
            firebase.auth().currentUser.updatePassword(newPassword).then(function() {
                // Update successful.
                console.log("Update successful");

                dispatch(setLoadingChangePassword(false));

                history.push(ROUTES.SETTINGS);

            }).catch(function(error) {
                // An error happened.
                console.log("error", error);
                dispatch(setLoadingChangePassword(false));
            });
        }).catch(function(e) {

            dispatch(setLoadingChangePassword(false));
            // An error happened.
            console.log("ChangePassword Error");
            let stringsAuth = RegisterAuthStrings['MX'];

            if(e.code === 'auth/wrong-password' || e.code === 'auth/user-not-found'){
                //console.log("auth/wrong-password");
                dispatch(setErrorAuthChangePassword({serv:{status:true, msg: stringsAuth.WrongPassword}}));
            }
        });
    }
}

export const CerrarSesion = () => 
{
    return (dispatch) => {

        firebase.auth().signOut()
        .then(()=>{
            //console.log("User logout")
            dispatch({
                type: types.logout,
                payload: {
                    
                }
            });
    
            dispatch(CerrarSesionSearch);
            dispatch(CerrarSesionOnBoarding);
            dispatch(CerrarSesionUI);
            dispatch(CerrarStateLogInAction);

        })  
        .catch(function(error) {
            console.error("Error trying logout", error);
        });    
      
    }
}
