import {types} from '../types/types';
import { db } from '../../firebase/firebase-config'; 

//Actions
import { getRecentUsers, getUsersFilter } from '../actions/searchAction';

//Route
import * as ROUTES from '../../data/routes';

export const setSearchOnBoarding = (searchOnBoarding, history) =>{

    return  (dispatch, getState) => {

        const { uid } = getState().auth.userInfo.user;

        console.log("setSearch", searchOnBoarding);
        //console.log("uid", uid);

        db.collection("users_searchOnBoarding").doc(`${uid}`)
        .set(searchOnBoarding)
        .then(() => {
            
            console.log("UserDoc successfully!");
                
            dispatch(
            {
                type: types.setSearchOnBoarding,
                payload: {
                    searchOnBoarding
                }
            });


            dispatch(getUsersFilter({ searchFilter: "Job", searchText: ""}));

            history.push('/menu');
 
        })
        .catch(function(error) {
            console.error("Error(LogIn) writing document: ", error);
        });  
        
    }
}

export const getSearchOnBoarding = (history) =>{

    return  async(dispatch, getState) => {

        const { uid } = getState().auth.userInfo.user;

        await db.collection("users_searchOnBoarding").doc(`${uid}`)
        .get()
        .then((docData) => {
            
            if(!docData.exists){
                //Send to onboarding
                history.push(ROUTES.REGISTER_ON_BOARDING);
            }
    
            console.log("get document successfully get SearchOnBoarding!", docData.data());


            dispatch(
            {
                type: types.setSearchOnBoarding,
                payload: {
                    searchOnBoarding: {...docData.data()}
                }
            });

  

            dispatch(getRecentUsers());
            dispatch(getUsersFilter({ searchFilter: "Job", searchText: ""}));
 
        })
        .catch(function(error) {
            console.error("Error(LogIn) writing document: ", error);
        });  
        
    }
}

export const CerrarSesionOnBoarding = () => 
{
    return (dispatch) => {

        dispatch({
            type: types.logout,
            payload: {
                
            }
        });
           
    }
}