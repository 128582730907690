import React, { useState, useEffect } from 'react';

//Redux
import { useDispatch, useSelector } from  'react-redux';

//Route
import * as ROUTES from '../data/routes';

//Models
import { LogInModel } from '../models/LogInModel';

//Components
import Footer from '../components/Footer';

//Actions
import { setUp } from '../redux/actions/stateLogInAction';
import { startLogInWithEmailPassword } from '../redux/actions/auth';
import { showSideBar } from '../redux/actions/uiAction';

//Strings
import { LogInStrings, LogInAuthStrings } from '../data/strings';

const LogIn = (props) => {

    const dispatch = useDispatch();

    const { stateLogIn, errorState } = useSelector(state => state.stateLogInReducer);

    const [stateForm, setState] = useState(stateLogIn);

    const { email, password } = stateForm;

    const { loadingLogIn } = useSelector(state => state.uiReducer);

    //Language
    const strings = LogInStrings['MX'];
    const stringsAuth = LogInAuthStrings['MX'];

    //Model
    let logInModel = new LogInModel(stringsAuth); 

    useEffect(() => {

        dispatch(showSideBar(false));

    }, [dispatch]);

    const handleInputChange = ({target}) => {

        setState({
            ...stateForm,
            [target.name]: target.value
        })
    };

    const handleShowHide = () =>{
          var x = document.getElementById("password");
          var element = null;

          if (x.type === "password") {
            x.type = "text";
            element = document.getElementsByClassName("toggle-password");            
            element[0].className = "fa fa-fw fa-eye field-icon toggle-password fa-eye-slash";

          } else {
            x.type = "password";
            element = document.getElementsByClassName("toggle-password");
            element[0].className = "fa fa-fw fa-eye field-icon toggle-password";
          }
    }

    const handleLogIn = (e) =>{
        
        e.preventDefault();

        logInModel.validate(stateForm);
      
        //Local
        if(logInModel.errorStatus){
            dispatch(setUp(stateForm, logInModel.toObject()));
            return;
        }

        //Server
        dispatch(startLogInWithEmailPassword(email, password));
    }

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            handleLogIn(e);
        }
    }

    const handleRegister = () =>{
        props.history.push(ROUTES.SIGN_UP);
    }

    return (

        <div className="mainContainerLogIn">

            <div className="container">
                
                <div className="row m-0 justify-content-center">

                    <div className="mainContainerLogInColumns">

                        <div className="text-center">

                            <form className="">

                                <div className="form-group">
                                    
                                    <input 
                                    type="text"
                                    placeholder="Usuario/Email"
                                    name="email"
                                    id="email"
                                    autoComplete="off"
                                    value={email}
                                    onChange={handleInputChange}
                                    onKeyDown={handleKeyDown}
                                    className="form-control inputFieldLogIn"/>
   
                                    {errorState.email.status &&   <div className="errorSignUp text-left"><span>{errorState.email.msg}</span></div>}
                               
                                </div>

                               

                                <div className="form-group">
                                    
                                    <div className="containerPassword">
                                        <input 
                                        type="password"
                                        placeholder="Contraseña"
                                        name="password"
                                        id="password"
                                        autoComplete="off"
                                        value={password}
                                        onChange={handleInputChange}
                                        onKeyDown={handleKeyDown}
                                        className="form-control inputFieldLogIn"/>

                                        <span toggle="#input-pwd" className="fa fa-fw fa-eye field-icon toggle-password" onClick={handleShowHide}></span>{/*fa-eye-slash */}
                                    </div>

                                    {errorState.password.status && <div className="errorSignUp text-left"><span>{errorState.password.msg}</span></div>}

                                </div>
                                

                                <div className="form-group">
                                    
                                    <button 
                                        type="button" 
                                        className="btn btn-primary btn-block btnLogIn" 
                                        onClick={handleLogIn}
                                    >
                                        {strings.btnLogIn}
                                    </button>

                                    {errorState.serv.status && <div className="errorSignUp text-left"><span>{errorState.serv.msg}</span></div>}

                                </div>

                                <div className="form-group">
                                    
                                    <button type="button" className="btn btn-primary btn-block btnRegisterLogIn" onClick={handleRegister}>{strings.btnRegister}</button>

                                </div>
                            </form>

                        
                        </div>

                    </div>


                </div>      

            </div>

            

            {
                loadingLogIn && <div className="containerRegisterLoading">
                                    </div>
            }


            <div className="fixed-bottom">  
                <Footer />
            </div>

        </div>
    )
    
}

export default LogIn;


//<p>Olvidaste tu contraseña?</p>