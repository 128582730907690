
export const socialData = {
    facebook: 'https://www.facebook.com/Gbonita-Consulting-445018026070812/',
    instagram: 'https://www.instagram.com/gbonitaconsulting/?hl=es-la',
    twitter: 'https://twitter.com/GBonitaConsult1',
    linkedin: 'https://www.linkedin.com/company/gbonita-consulting/'
};

export const storeData = {
    playstore: 'https://play.google.com/store/apps/details?id=com.gbonita.gbonita',
    appstore: 'https://apps.apple.com/mx/app/gbonita/id1505382604'
};

export const FirebaseKey = {
    users_recruiters: 'users_recruiters'
};