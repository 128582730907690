import React from "react";

//Redux
import { useDispatch, useSelector } from 'react-redux';

//Route
import { Link, useHistory } from "react-router-dom";
import * as ROUTES from '../../data/routes';

//Bootstrap
import { Navbar } from 'react-bootstrap';

//Actions
import { showSearchBar } from '../../redux/actions/uiAction';

//Hooks
import { useAccessModule } from '../../hooks/useAccessModule';

//Helpers
import { accessModuleSearch } from  '../../helpers/accessModule';

//Images
import icon from "../../assets/img/icon_.png";
import MENU_busqueda_up from '../../assets/img/SideBar/MENU_busqueda_up.png';
import BUT_settings_up from '../../assets/img/NavBar/BUT_settings_up.png';

const PrivateNavbar = () => {
    
    const dispatch = useDispatch();
    const history = useHistory();
    const [ validateAccess ] = useAccessModule(accessModuleSearch);


    const { searchBar } = useSelector(state => state.uiReducer);
   
    const handleShowSearch = (e) =>{
     
      e.preventDefault();

      //Check access to search module
      if(validateAccess()){

        const pathname = history.location.pathname;
        const splitPath = pathname.split('/');
        const _page = splitPath[splitPath.length -1];
  
  
        const _searchBar = !searchBar;
    
       
  
        if(_page !== 'menu'){
          history.push(ROUTES.MENU);
  
          dispatch(showSearchBar(true));//setSearchFlag(aux);
        
        }
        else{
          dispatch(showSearchBar(_searchBar));//setSearchFlag(aux);
        }
  
        
        //dispatch(showSearchBar(aux));//setSearchFlag(aux);
        
    
        if(_searchBar){
            window.scrollTo(0, 0);
        }
      }
     
      
    }


    const handleShowSettings = () =>{

        const pathname = history.location.pathname;
        const splitPath = pathname.split('/');
        const _page = splitPath[splitPath.length -1];

        if(_page !== 'settings'){
          history.push(ROUTES.SETTINGS);
        }

    }
  
    return (

      <Navbar
        sticky="top"
        id="navbar"
        className="navbar navbar-expand-lg navbar-light bg-light fixed-top containerNavBar"
      >
        <div className="container">

          <div className="containerBrand">
            <Link to={ROUTES.MENU} className="navbar-brand mr-auto">
                <img
                  src={icon}
                  width="140"
                  height="50"
                  className="d-inline-block align-top"
                  alt="logo"
                  loading="lazy"
                />
                
              </Link>
          </div>
           

          {
            /* 
            
              <button
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbarNav"
              aria-controls="navbarNav"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            
            */

          }

        
          
          {/*<div className="collapse navbar-collapse" id="navbarNav"> */}

            <ul className="navbar-nav ml-auto">

              <li className="icon-menu op-sidebar-busqueda">
                <img
                    src={MENU_busqueda_up}
                    width="40"
                    height="40"
                    className="d-inline-block align-top"
                    alt="Busqueda"
                    loading="lazy"
                    onClick={handleShowSearch}
                  />
              </li>

              <li className="icon-menu op-navbar-settings">
                
                  <img
                    src={BUT_settings_up}
                    width="40"
                    height="40"
                    className="d-inline-block align-top"
                    alt="Settings"
                    loading="lazy"
                    onClick={handleShowSettings}
                  />
                
              </li>

            </ul>

        
          {/*  </div> */}

          </div>
      </Navbar>
    )
    
}

export default PrivateNavbar;