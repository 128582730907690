import React from 'react';

//Helpers
import { getJob } from '../../helpers/helpers';

//Image
import ic_profile from '../../assets/img/ic_profile.png';

/*
const user = { 
    countryCode:"FR",
email:"pibota@gmail.com",
phone:"0648472039",
postcode:"",
lastname:"Bc",
birthday:"",
twitter:"",
instagram:"",
name:"Maria",
facebook:""
}
const userPrepare = { }
const userFiles = { }*/

 const CandidatoRecienteInfo = (props) => {

    const { user, userPrepare, userFiles } = props.user;


    console.log("Hola desde CandidatoRecienteInfo");

    const handleOnError = ({target}) => {
        
        //userFiles.profilePhoto = null;
        target.src = ic_profile;//'some default image url';
    }
    
    //

    return (

        <div className="mainContainerCandidatoReciente text-center d-flex align-self-center" onClick={() => props.viewProfile(props.user)}>

            <div className="CandidatoRecienteInfo">

                <div className="p-0 align-self-center pl-2 pr-2 pb-3">
                    <img className="imgCandidatoRecienteInfo" src={ userFiles.profilePhoto || ic_profile } alt="Foto Candidato" onError={(e) => handleOnError(e)}></img>
                </div>

                <div className="p-0 align-self-center">

                    <div className="contentCandidatoRecienteInfoName d-flex justify-content-center">
                        <p className="p-0 my-auto">{`${user.name} ${user.lastname}`}</p>
                    </div>
                    
                    <div className="contentCandidatoRecienteInfoJob">{/*d-flex justify-content-center */}
                        <p className="p-0 my-auto">{getJob(userPrepare)}</p>
                    </div>

                </div> 

            </div>
        
        </div>

    )
    
}

export default CandidatoRecienteInfo;
