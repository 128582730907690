import React from 'react';

const PruebaLoading = () => {
    
    return (

        <div className={`menuDisplayed wrapper`}>
            <div className="page-content-wrapper">
                <div className="container-fluid p-0">

                    <div className="containerPruebaLoading">
                        <h1>Titutlo</h1>
                        <p>Descr ffdfd dfdfdfd dffdfdfd dffddffd ffdfd</p>
                    </div>
                    
                    <div className="subContainerPruebaLoading">

                    </div>

                </div>
            </div>
        </div>
       
    )
    
}


export default PruebaLoading;