import React, { useState, useRef } from 'react';

//Helpers
import { getJob, getAboutMeResume } from '../../helpers/helpers';

//Images
import ic_profile from '../../assets/img/ic_profile.png';
import ICO_sinvideo from '../../assets/img/ICO_sinvideo.png';
import Star_Blue from '../../assets/img/Star_Blue.png';


const CandidatoInfo = (props) =>{
    
    const { user, userFiles, userPrepare } = props.user;
    const { strings } = props;

    const [loadingVideo, setLoadingideo] = useState(true);
    //const [errorVideo, setOnErrorVideo] = useState(false);
    
    let playerVideo = useRef();

    const handleOnError = ({target}) => {
        
        //userFiles.profilePhoto = null;
        target.src = ic_profile;//'some default image url';
    }

    const handleOnErrorVideo = (error) => {

        //target.src = ic_profile;//'some default image url';
        //console.log("Error Video", error);
        //setOnErrorVideo(true);
    }
    
    
    console.log("Hola desde CandidatoInfo" );

    //onClick={() => this.props.viewProfile(this.props.user)}

    const handleVideoLoaded = () =>{
        //playerVideo.load();
        setLoadingideo(false);
    }


    const playMovie = () =>{
        playerVideo.play();
    }

    const stopMovie = () =>{
        playerVideo.pause();
    }


    return (

        <div className="mainContainerCandidatoInfo" onClick={() => props.viewProfile(props.user)}>
            <div className="row m-0">
                <div className="col-12 p-0">


                    <div className="leftMainContainerCandidatoInfo">

                        <div className="leftSubContainerCandidatoInfo">

                            <div className="containerVideoCandidatoInfo">

                                {
                                    (loadingVideo) && <img src= {ICO_sinvideo} className="imgVideoCandidatoInfo" alt="Video"></img>   
                                }

                                <video 
                                    className={`${(userFiles.videoformal || userFiles.videopersonal) ? 'enable' : ''} `} 
                                    id="v" 
                                    disablePictureInPicture 
                                    controlsList="nodownload" 
                                    ref={videoRef => playerVideo = videoRef} 
                                    preload="metadata" 
                                    onMouseOver={playMovie} 
                                    onMouseOut={stopMovie}
                                    onLoadedData={handleVideoLoaded} 
                                    onError={handleOnErrorVideo()}>

                                    <source src= {  userFiles.videoformal ?  userFiles.videoformal : userFiles.videopersonal ? userFiles.videopersonal : "" } type="video/MP4"/>

                                </video>
                            </div>

                        </div>

                    </div>
                        





                    <div className="row m-0 rightMainContainerCandidatoInfo">
                        <div className="rightSubContainerCandidatoInfo">
                            <div className="rightFillContainerCandidatoInfo">
                                <div className="row m-0">


                                        <div className="col-12 p-0">

                                            <div className="leftContainerImageProfileCandidatoInfo">

                                                    <div className="leftSubContainerImageProfileCandidatoInfo">
                                                        <img src= {userFiles.profilePhoto || ic_profile} className="imgProfileCandidatoInfo" onError={(e) => handleOnError(e) } alt="Profile Img"></img>
                                                    </div>
                                                
                                            </div>

                                            
                                            <div className="row m-0 rightContainerUserInfoCandidatoInfo">

                                                <div className="rightSubContainerUserInfoCandidatoInfo"> {/**/}

                                                        <div className="containerStarCandidatoInfo align-self-center">

                                                            <div className="text-right">

                                                                <img className="cardStar" alt="star" src={ Star_Blue }/>
                                                                <img className="cardStar" alt="star" src={ Star_Blue }/>
                                                                <img className="cardStar" alt="star" src={ Star_Blue }/>
                                                                <img className="cardStar" alt="star" src={ Star_Blue }/>
                                                                <img className="cardStar" alt="star" src={ Star_Blue }/>
                                                                
                                                            </div>

                                                        </div>

                                                        <div className="leftContainerUserDetailCandidatoInfo my-auto align-self-center">
                                                            <h1 className="mb-0">{`${user.name} ${user.lastname}`}</h1>
                                                            <p className="mb-0">{getJob(userPrepare)}</p>
                                                        </div>
                                                </div>

                                            </div>

                                        </div>


                                        <div className="containerAboutMeCandidatoInfo">

                                            <h1>{strings.labelAboutMe}</h1>
                                            <p className="m-0">{getAboutMeResume(userPrepare.group_HabilidadesFortalezas)}</p>
                                            
                                        </div>


                                </div>
                            </div>
                        </div>
                    </div>


                    
                </div>
            </div>
        </div>
    )
    
   
}

export default CandidatoInfo;